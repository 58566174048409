import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getEmbedThemeName, getEmbedThemeInfo, getEmbedExperienceText } from "../theme/Loader";

import featured from "../assets/featured-brs.png";
import highlight from "../assets/hightlight-brs-tweet.png";

import "./home.css";

const HOME='HOME';

const experience = {
  'HIGHLIGHTS_SCROLL': 'highlights-scroll',
  'GALLERY_SCROLL': 'gallery-scroll',
  'GALLERY': 'gallery',
  'HIGHLIGHTS': 'highlights',
  'LINK': 'link'
}


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {selected: HOME, gallery: '', highlights: ''};
  }

  componentDidMount() {
    const settings = getEmbedThemeInfo();
    let gallery = settings.home.experiences.gallery;
    let highlights = settings.home.experiences.highlights;
    this.setState({gallery: experience[gallery], highlights: experience[highlights]})
  }

  getText = (key) => {
    return getEmbedExperienceText('home', key);
  }

  getCurrentTheme = () => {
    return ({
      name: getEmbedThemeName(),
      info: getEmbedThemeInfo()
    })
  }

  renderHome = () => {
    return (
      <div className="home-container">
        <div className="title-1">{this.getText('socialMediaLounge')}</div>
        <div className="title-2">{this.getText('joinTheConversation')}</div>
        <div className="title-hashtag">
          <div className="hashtag">{this.getText('hashtag')}</div>
          <div className="photobooth" onClick={() => window.open(this.getText('photoboothUrl'), "_blank")}><div className='text'>{this.getText('photobooth')}</div></div>
        </div>
        <div className="content">
          <div className="experience">
            <div className='exp-container first'>
              {/* <Link to={`/highlights?theme=${themeName}`}> */}
              <div className='block' onClick={() => this.props.setExperience(this.state.highlights)}>
                <SelectionBox
                  src={highlight}
                  title={this.getText('highlightsTitle')}
                  tag={""}
                />
                <div className='tagline'>{this.getText('highlightsTag')}</div>
                </div>
              {/* </Link> */}
            </div>
            <div className='exp-container second'>
              {/* <Link to={`/gallery?theme=${themeName}`}> */}
              <div className='block' onClick={() => this.props.setExperience(this.state.gallery)}>
                <SelectionBox
                  src={featured}
                  title={this.getText('galleryTitle')}
                  tag={""}
                />
                <div className='tagline'>{this.getText('galleryTag')}</div>
                </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return this.renderHome();
  }
}

export const SelectionBox = (props) => {
  return (
    <div className="selection-container">
      <div className="selection">
        <div className={`top ${props.title.toLowerCase()}`}>
          <img src={props.src} alt="logo" />
          <div className='imgalt'></div>
        </div>
        <div className="below">
          <div className="selection-title">{props.title}</div>
          <div className="selection-tag">{props.tag}</div>
        </div>
      </div>
    </div>
  );
};

export const HomeWithRouter = withRouter(Home);
