import React, { Component } from "react";

import { getHighlights } from "../Services.js";
import { withRouter } from "react-router-dom";
import { getEmbedThemeName, getEmbedThemeInfo, getEmbedExperienceText } from "../theme/Loader";
import TextFeed from "../component/TextFeedv2";
import _ from 'lodash';


import "./highlights-scroll.css";

import Cardv2 from "../component/Cardv3.js";
import { triggerSAEvent } from "../utils.js";

export default class HighlightsScroll extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.animationInterval = null;
    this.state = {
      posts: [],
      post: null,
      status: false,
      fadeIn: false,
      fadeOut: false,
      postsToRender: [],
      isLoading: false,
      scrolled: false,
      end: false
    };
    this.myRef = React.createRef();
  }

  getCurrentTheme = () => {
    return { name: getEmbedThemeName(), info: getEmbedThemeInfo() };
  };

  apiFeatured = (animate) => {
    let {
      info: {
        highlights: { apiLimit, contentType, filter, format },
      },
    } = this.getCurrentTheme();
    let params = {
      limit: apiLimit,
      contentType,
      filter,
      format,
      from: null
    };

    getHighlights(
      (posts) => {
        this.setState({ posts, end: false, isLoading: false }, () => {
          if (animate) {
            this.toggleAnimationClass();
            // this.resetToTop();
            return;
          }
        });
      },
      () => { this.setState({ posts: [], end: true, isLoading: false }) },
      params
    );
    // console.log('in scheduleapi')
    // this.scheduleApi(apiRefreshRate);
  };

  fetchApi = (animate) => {
    this.setState({isLoading: true})
    this.apiFeatured(animate);
  };

  scheduleApi = (apiRefreshRate) => {
    this.interval = setInterval(() => this.fetchApi(true), apiRefreshRate * 1000);
  };

  // scheduleAnimation = (displayRefreshRate) => {
  //   this.animationInterval = setInterval(
  //     () => this.toggleAnimationClass(),
  //     displayRefreshRate * 1000
  //   );
  // };

  animate = () => {
    this.setState({ postsToRender: this.state.posts, fadeIn: true });
  };

  toggleAnimationClass = () => {
    this.setState({ fadeIn: false, fadeOut: true }, () =>
      setTimeout(this.animate, 500)
    );
  };

  synchronousSetState = (state) => {
    return new Promise((resolve, reject) => {
      this.setState(state, () => resolve());
    });
  }

  handleScroll = async () => {
    const el = this.myRef.current;
    if (el.scrollTop > 100 && !this.state.scrolled) {
      this.setState({ scrolled: true });
      return;
    }
    if (el.scrollTop <= 100 && this.state.scrolled) {
      this.setState({ scrolled: false });
      return
    }
    let offset =
      el.scrollHeight - el.scrollTop < 1.7 * el.clientHeight;

    if (offset && !this.state.isLoading) {
      let lastPostId = _.get(_.last(this.state.posts), 'id');
      !this.state.end && await this.synchronousSetState({ isLoading: true });
      let {
        info: {
          highlights: { apiLimit, contentType, filter, format },
        },
      } = this.getCurrentTheme();
      let params = {
        limit: apiLimit,
        contentType,
        filter,
        format,
        temporality: 'older',
        anchor: lastPostId
      };
      !this.state.end && this.fetchApiData(params);
    }
  };

  fetchApiData = (params) => {
    getHighlights(
      (posts) => {
        this.setState(prevState => ({ posts: _.concat(prevState.posts, posts), isLoading: false, end: false }), this.animate);
      },
      () => { this.setState({ end: true, isLoading: false }) },
      params
    );
  }

  componentDidMount() {
    this.fetchApi(true);
    this.props.amplitude && this.props.amplitude.dispatchAnalyticsEvent('LOADED HIGHLIGHTS')
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
    // clearInterval(this.animationInterval);
  }

  popUp = (post) => {
    this.setState({ post });
  };

  reset = () => {
    this.setState({ post: null });
  };

  getItems() {
    return this.state.postsToRender.map((post) => {
      if (post.getMediaUrl()) {
        return (
          <Cardv2
            portrait={post.isPortrait()}
            key={post.getId()}
            post={post}
            showPopup={this.popUp}
          />
        );
      } else {
        return <TextFeed key={post.getId()} post={post} />
      }
    });
  }

  back = () => {
    if (!this.props.prevExperience) {
      return;
    }
    this.props.setExperience(this.props.prevExperience)
    this.props.setCategory('')
    triggerSAEvent(`back to lounge from highlights`)
  };

  tweetAction = () => {
    let hashtags = getEmbedExperienceText(this.props.experienceName, 'highlightsHashtag');
    window.open(`https://twitter.com/intent/tweet?hashtags=${hashtags.join(',')}`, '', "height=400,width=400");
    triggerSAEvent(`post from highlights`)
  }

  getText = (key) => {
    return getEmbedExperienceText('highlights-scroll', key);
  }

  resetToTop = () => {
    const node = this.myRef.current;
    node.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // this.setState({scrolled: false})
  }

  render() {
    const title = getEmbedExperienceText(this.props.experienceName, "highlightsTitle");
    if (this.state.postsToRender.length <= 0) {
      return (
        <div className="new-highlights-container-scroll" id="cardsId" style={{ width: '100%', height: '100vh' }}>
          <div className="header">
            <div className="back" onClick={this.back}>
              <i className={`icon-fk ${this.getText('back-icon-fk')}`} onClick={this.back} />
              <div className='back-label'>{this.getText('back-text')}</div>
            </div>
            <div className="highlights-title-scroll title">{title}</div>
            <div className='action' onClick={this.tweetAction}>
              <i className={this.getText('action-icon-fk')}></i>
              <div className='x-logo'></div>
              {this.getText('action-text')}
            </div>
          </div>
          {this.state.isLoading ? <div className="spinner"></div> : null}
        </div>
      );
    }
    return (
      <div className="new-highlights-container-scroll" id="cardsId">
        <div className="header">
          <div className="back" onClick={this.back}>
            <i className={`icon-fk ${this.getText('back-icon-fk')}`} onClick={this.back} />
            <div className='back-label'>{this.getText('back-text')}</div>
          </div>
          <div className="highlights-title-scroll title">{title}</div>
          <div className='action' onClick={this.tweetAction}>
            <i className={this.getText('action-icon-fk')}></i>
            <div className='x-logo'></div>
            {this.getText('action-text')}
          </div>
        </div>
        <i className={`scroll-to-top icon-fk-up-1 ${this.state.scrolled ? '' : 'hide'}`} onClick={this.resetToTop} />
        <div
          onScroll={this.handleScroll}
          id="idd"
          ref={this.myRef}
          className={`cards ${this.state.fadeIn ? "fade-in" : ""} ${this.state.fadeOut ? "fade-out" : ""
            }`}
        >
          {this.getItems()}
          {this.state.isLoading ? <div className="spinner-loading"></div> : null}
        </div>
      </div>
    );
  }
}

export const withRouterHighlightsScroll = withRouter(HighlightsScroll);
