import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './container.css';
import { getEmbedExperienceText, getEmbedThemeInfo } from '../theme/Loader';

export class Container extends Component {

  goBack = () => {
    // console.log('-goback container-', this.props.prevExperience);
    if (!this.props.prevExperience) {
      return;
    }
    this.props.setExperience(this.props.prevExperience)
    this.props.setCategory('')
  }
  getText = (key) => {
    return getEmbedExperienceText('container', key)
  }

  render() {
    // console.log('container props', this.props)
    const { experienceName } = this.props;
    console.log('contaiiiner', getEmbedThemeInfo())
    const title = getEmbedThemeInfo().container[experienceName] || '';
    return (
      <div className={`router-container ${this.props.className ? this.props.className : ''}`}>
        <div className='landing-title'>{this.getText('title')}</div>
        <div className='router-container-back' onClick={this.goBack}><i className='icon-fk-left-dir'></i>Back</div>
        <div className='router-container-title'>{title}</div>
        {this.props.children}
      </div>
    )
  }
}

export const RouterContainer = withRouter(Container);
