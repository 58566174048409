import React, { Component } from 'react';
import { getEmbedExperienceText } from '../theme/Loader';
import { triggerSAEvent } from '../utils';
import './lounge.css';

const names = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four'
}

const experience = {
  1: 'highlights-scroll',
  // 2: 'badge',
  2: 'gallery-scroll',
  3: 'badge',
  4: 'leaderboard'
}

export default class Lounge extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.amplitude && this.props.amplitude.dispatchAnalyticsEvent('LOADED SOCIAL MEDIA HUB')
  }
  chooseExperience = (num) => {
    // if (num === 3) {
    //   console.log('in digital booth')
    //   const url = getEmbedExperienceText('lounge', 'photobooth-link')
    //   window.open(url);
    //   return
    // } else 
    if (num === 4) {
      // this.props.setCategory('cisco')
      this.props.setExperience(experience[num])
    } else {
      this.props.setCategory('');
      this.props.setExperience(experience[num])
    }
  }

  getText = (key) => {
    return getEmbedExperienceText('lounge', key);
  }

  renderActions = () => {
    let actions = this.getText('actions');
    return actions.map(({ description, btnTitle }, i) => {
      return (
        <div className={`${names[i + 1]}${i}`} key={description+i}>
          {this.renderAction(i, description, btnTitle)}
        </div>)
    })
  }

  tweetAction = () => {
    let hashtags = getEmbedExperienceText(this.props.experienceName, 'loungeHashtag');
    // console.log('hashtags', hashtags)
    window.open(`https://twitter.com/intent/tweet?hashtags=${hashtags.join(',')}`, '', "height=400,width=400");
    triggerSAEvent(`post from lounge`)
  }


  renderAction = (i, description, btnTitle) => {
    return (
      <div className={`interactive-button ${names[i + 1]}`} onClick={() => {
        this.chooseExperience(i + 1)
      }
      }>
        <div className='interaction-icon-fk'></div>
        <div className='interaction-description'>
          <div className='interaction-title'>{description}</div>
          <div className='interaction-action-btn'>{btnTitle}</div>
        </div>
      </div>
    )
  }
  openLink = () => {
    window.open(this.getText('photobooth-link'), "_blank");
    triggerSAEvent(`visit virtualbooth`)
  }

  render() {
    return (
      <div className='lounge-container'>
        <div className='lounge-header'>
          <div className='title'>{this.getText('title')}</div>
          <div onClick={this.tweetAction} className='action'><i className={this.getText('action-icon-fk')}></i>{this.getText('action-text')}</div>
        </div>
        <div className='lounge-content'>
          <div className='lounge-description'>
            <div className='description'>{this.getText('description')}</div>
            <div className='virtual-booth' onClick={this.openLink}>
              {this.getText('photobooth')}
            </div>
          </div>
          <div className='interactive-section'>
            {this.renderActions()}
          </div>
        </div>
      </div>
    )
  }
}
