import React from "react";

import "./filter.css";

export const Filter = ({
  list = [],
  type = "",
  selected = [],
  onChange = () => {},
  isMobile = false,
}) => {
  const isMultiSelect = type === "multi";
  const isRadio = type === "radio";

  const handleFilterChange = (selectedItem) => {
    const alreadySelected = Boolean(
      selected.find((current) => current === selectedItem)
    );
    let newFilters = [];
    if (isMultiSelect) {
      newFilters = alreadySelected
        ? selected.filter((currentItem) => currentItem !== selectedItem)
        : [...selected, selectedItem];
    } else {
      newFilters = alreadySelected
        ? isRadio
          ? [selectedItem]
          : []
        : [selectedItem];
    }
    return onChange(newFilters);
  };

  return (
    <div className={`fk-filter ${isMobile ? "fk-filter-mobile" : null}`}>
      {list.map(({ name, value }, index) => {
        const isSelected = Boolean(
          selected.find((current) => current === value)
        );
        return (
          <div
            key={`filter-${index}`}
            className={`fk-filter-item ${isSelected ? "fk-selected" : ""}`}
            onClick={() => handleFilterChange(value)}
          >
            {name}
          </div>
        );
      })}
    </div>
  );
};
