// import _ from "lodash";
import React, { useState } from "react";
import { checkIfAeApps } from "../../../../theme/Loader";

import { Filter } from "../Filter/Filter";

import "./filtersDropdown.css";

// const getFromLocalStorage = (attr, key = 'body') => {
//   console.log('getFromLocalStorage', attr, key)
//   const body = JSON.parse(localStorage.getItem(key) || "{}")
//   console.log('filters', body, _.get(body, attr, 'no-value'))
//   return _.get(body, attr, 'no-value')
// }

export const FiltersDropdown = ({
  selectedFilters,
  onFiltersConfirm,
  isMobile = false,
}) => {
  const aeApps = checkIfAeApps();
  const hashtagsList = [
    { name: "#CiscoIMPACT", value: "#CiscoIMPACT" },
    { name: "#BetterTogether", value: "#BetterTogether" },
    { name: "#BigWin", value: "#BigWin" },
    { name: "#InclusiveFuture", value: "#InclusiveFuture" },
  ];
  const geoList = [
    { name: "Americas", value: "amer" },
    { name: "EMEA", value: "emea" },
    { name: "APJC", value: "apjc" },
  ];

  const departmentList = [
    { name: "Arch", value: "architecture" },
    { name: "Chem", value: "chemical" },
    { name: "Civil", value: "civil" },
    { name: "CSE", value: "computerscience" },
    { name: "ECE", value: "ece" },
    { name: "EEE", value: "eee" },
    { name: "ICE", value: "ice" },
    { name: "Mech", value: "mechanical" },
    { name: "Metallurgy", value: "metallurgy" },
    { name: "Prod", value: "production" },
  ];

  const groupsList = [
    { name: "Chamaars", value: "chamaars" },
    { name: "Jaycees", value: "jaycees" },
    { name: "Leo", value: "leo" },
    { name: "Mech Family", value: "mechfamily" },
    { name: "Nocturnals", value: "nocturnals" },
    { name: "Rotaract", value: "rotaract" },
    { name: "Tamil Mandram", value: "tamilmandram" },
    { name: "Uforians", value: "uforians" },
  ];

  const sortList = [
    { name: "Latest", value: "time" },
    { name: "Popular", value: "rank" },
  ];
  const [filters, setFilters] = useState(selectedFilters);

  const handleFiltersChange = (currentFilters, type) => {
    setFilters({
      ...filters,
      [type]: currentFilters,
    });
  };

  const handleReset = () => {
    setFilters({
      sort: ["time"],
    });
  };

  const handleConfirm = () => {
    onFiltersConfirm(filters);
  };

  return (
    <div
      className={`fk-filters-dropdown-wrapper ${
        isMobile ? "fk-filters-dropdown-wrapper-mobile" : null
      }`}
    >
      <div className="fk-filters-dropdown">
        <div className="fk-filters-reset" onClick={handleReset}>
          Reset
        </div>
        {aeApps ? (
          <>
            <div className="fk-filters-dropdown-filter">
              <p className="fk-filter-label">Departments:</p>
              <Filter
                isMobile
                list={departmentList}
                type="multi"
                selected={filters["dept"]}
                onChange={(selectedFilters) =>
                  handleFiltersChange(selectedFilters, "dept")
                }
              />
            </div>
            <div className="fk-filters-dropdown-filter">
              <p className="fk-filter-label">Groups:</p>
              <Filter
                isMobile
                list={groupsList}
                type="multi"
                selected={filters["group"]}
                onChange={(selectedFilters) =>
                  handleFiltersChange(selectedFilters, "group")
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className="fk-filters-dropdown-filter">
              <p className="fk-filter-label">Hashtags:</p>
              <Filter
                isMobile
                list={hashtagsList}
                type="multi"
                selected={(filters["hashtag"] || []).map(
                  (currentValue) => `#${currentValue}`
                )}
                onChange={(selectedFilters) => {
                  return handleFiltersChange(
                    selectedFilters.map((currentValue) =>
                      currentValue.substring(1)
                    ),
                    "hashtag"
                  );
                }}
              />
            </div>
            <div className="fk-filters-dropdown-filter">
              <p className="fk-filter-label">Geo:</p>
              <Filter
                isMobile
                list={geoList}
                type="multi"
                selected={filters["location"]}
                onChange={(selectedFilters) =>
                  handleFiltersChange(selectedFilters, "location")
                }
              />
            </div>
          </>
        )}

        <div className="fk-filters-dropdown-filter fk-filters-dropdown-filter-border">
          <p className="fk-filter-label">Sort:</p>
          <Filter
            isMobile
            list={sortList}
            type="radio"
            selected={filters["sort"]}
            onChange={(selectedFilters) =>
              handleFiltersChange(selectedFilters, "sort")
            }
          />
        </div>

        <div className="fk-filters-confirm-btn">
          <div className="fk-filters-confirm" onClick={handleConfirm}>
            Show Results
          </div>
        </div>
      </div>
    </div>
  );
};
