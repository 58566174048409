import React, { Component } from 'react';
import { getEmbedExperienceText } from '../theme/Loader';

export default class Link extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.openLink();
    this.props.setExperience(this.props.prevExperience);
  }

  getText = (key) => {
    return getEmbedExperienceText('link', key);
  }

  openLink = () => {
    window.open(this.getText('url'), "_blank");
  }

  render() {
    return (
      <div className='link-container' onClick={this.openLink}></div>
    )
  }
}
