import React, { Component, useState, useEffect } from 'react';
import classnames from 'classnames';
import verified from './../assets/verified.svg';
import fkLogo from './../assets/logo-small-blue.png';
// import mute from './../assets/volume-mute-solid.svg';
// import unmute from './../assets/volume-up-solid.svg';
import play from './../assets/play.svg';

import './gallery-card.css';

export default class GalleryCard extends Component {

  authorName() {
    let { post } = this.props;
    return (
      <span className='gallery-name'>
        {post.getAuthorName()}
        {post.isVerifiedAuthor() ? <img className='gallery-verified' alt='post from verified author' src={verified} /> : ''}
      </span>
    )
  }

  openTweet() {
    let { post } = this.props;
    window.open(post.getUrl(), "_blank");
  }

  getLikes() {
    let { post } = this.props;
    if (post.getLikes() > 0) {
      // return ([
      //   <img className='like-icon-fk' src='./assets/heart.svg' alt='likes' />,
      //   <span className='like-count'>{post.getLikes()}</span>
      // ])
      return ([
        <span className='heart'></span>,
        <i className='icon-fk-heart-empty' />,
        <span className='like-count'>{post.getLikes()}</span>
      ])
    }
  }

  getRetweets() {
    let { post } = this.props;
    if (post.getRetweets() > 0) {
      // return ([
      //   <img className='retweet-icon-fk' src='./assets/retweet.svg' alt='retweets' />,
      //   <span className='retweet-count'>{post.getRetweets()}</span>
      // ])
      return ([
        <span className='retweet'></span>,
        <i className='icon-fk-retweet' />,
        <span className='retweet-count'>{post.getRetweets()}</span>
      ])
    }
  }

  getSource() {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === 'twitter') {
      return (
        <span className="gallery-src src-twitter">
          <i className="icon-fk-twitter-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === 'instagram') {
      return (
        <span className="gallery-src src-instagram">
          <i className="icon-fk-instagram-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === 'fankave') {
      return (
        <span className="gallery-src src-fankave">
          <img src={fkLogo} alt="fankave logo" />
        </span>
      );
    }
    return null;
  }
  componentDidCatch(error, errorInfo) {
    console.warn('video not ready', errorInfo);
  }

  render() {
    let { post } = this.props;
    let cardStyle = {
      backgroundImage: post.getMediaUrl() ? `url(${post.getMediaUrl()})` : 'initial'
    }
    let cardClass = classnames("gallery-card", { text: !post.getMediaUrl() })

    return (
      <div className='gallery-card-parent'>
        <div className={cardClass} style={cardStyle} aria-label="social media image" title="social media image">
          {this.getSource()}
          {post.isVideoMedia() ?
            <div>
              {/* <video src={post.getVideoUrl()} /> */}
              <div className='gallery-details' onClick={() => this.props.selectPost(post)}>
                <img src={play} alt="play" />
              </div>
            </div>

            :
            <a className='no-link' href={post.getUrl()} target="_blank" rel="noopener noreferrer" aria-label={post.getText() || 'social media post text'}>
              <div className="gallery-details">
              {this.getSource()}
              <div className="gallery-msg" aria-label={post.getText()}>
                {post.getText()}
              </div>
              <div className="gallery-stats">
                {this.getRetweets()}
                {this.getLikes()}
              </div>
            </div>
            </a>
          }

        </div>
        <div className="gallery-author">
          <img className="gallery-avatar" src={post.getAuthorPhoto()} alt="post author" />
          {this.authorName()}
          <div className='gallery-handle'>{post.getAuthorHandle()}</div>
          <div className='gallery-created-at'>{post.getCreatedAt()}</div>
        </div>
      </div>
    )
  }
}

const formatTime = (timeStamp) => {
  if (timeStamp <= 0 || !Number.isFinite(timeStamp)) {
    return `00:00`
  }
  //Get hours from Seconds
  const hours = timeStamp / (60 * 60)
  const absoluteHours = Math.floor(hours)
  const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

  //Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60
  const absoluteMinutes = Math.floor(minutes)
  const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

  //Get remainder from minutes and convert to seconds
  const seconds = (minutes - absoluteMinutes) * 60
  const absoluteSeconds = Math.floor(seconds)
  const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds
  if (absoluteHours) {
    return `${h}:${m}:${s}`
  }
  return `${m}:${s}`
}

export const VideoPlayer = ({ src, poster, cc }) => {
  const playerRef = React.useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  // const [canPlay, setCanPlay] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  // const [dimensions, setDimensions] = useState({ videoWidth: null, videoHeight: null });
  // const [isPortrait, setIsPortrait] = useState(false);
  const handlePlay = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pause()
        setPlaying(false);
      }
      if (!isPlaying) {
        playerRef.current.duration > 0 && playerRef.current.play()
        setPlaying(true);
      }
    }
  }
  // const checkIfVideoLoaded = () => {
  //   if (playerRef.current) {
  //     return playerRef.current.duration > 0 ? true : false;
  //   }
  //   return false;
  // }

  // const hoverPlay = () => {
  //   if (checkIfVideoLoaded()) {
  //     handlePlay();
  //   }
  // }
  const handleCanPlay = () => {
    if (playerRef.current) {
      setDuration(playerRef.current.duration || 0)
    }
    // setCanPlay(true);
  }
  const handlePlayEnd = () => {
    setPlaying(true);
    setCurrentTime(0);
  }
  const handleTimeUpdate = () => {
    if (playerRef.current) {
      setCurrentTime(playerRef.current.currentTime)
    }
  }
  // const handleSeek = (newTimeStamp) => {
  //   const player = playerRef.current;
  //   if (player) {
  //     player.currentTime = newTimeStamp;
  //     setCurrentTime(newTimeStamp);
  //   }
  // }
  const handleVolume = () => {
    const player = playerRef.current;
    if (player) {
      if (player.muted) {
        player.muted = false;
        setIsMuted(false);
      } else {
        player.muted = true;
        setIsMuted(true);
      }
    }
  }
  const handleInitialization = (e) => {
    // console.log(e.target.videoWidth);
    // setDimensions({ videoWidth: e.target.videoWidth, videoHeight: e.target.videoHeight })
    // if (e.target.videoWidth > e.target.videoHeight) {
    //   setIsPortrait(false);
    // } else if (e.target.videoWidth < e.target.videoHeight) {
    //   setIsPortrait(true)
    // } else if (e.target.videoWidth === e.target.videoHeight) {
    //   setIsPortrait(false)
    // }
  }
  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      // player.addEventListener('play', handlePlaying);
      player.addEventListener('canplay', handleCanPlay);
      player.addEventListener('ended', handlePlayEnd);
      player.addEventListener('timeupdate', handleTimeUpdate);
      player.addEventListener('loadedmetadata', handleInitialization)
    }
    // console.log('isprotrait', isPortrait);
    return () => {
      if (player) {
        // player.removeEventListener('play', handlePlaying);
        player.removeEventListener('canplay', handleCanPlay);
        player.removeEventListener('ended', handlePlayEnd);
        player.removeEventListener('timeupdate', handleTimeUpdate);
        player.removeEventListener('loadedmetadata', handleInitialization)
      }
    }
  })
  // const isPortrait = () => {
  //   if (dimensions.videoWidth) {

  //     if (dimensions.videoWidth > dimensions.videoHeight) {
  //       return false;
  //     }
  //     if (dimensions.videoHeight > dimensions.videoWidth) {
  //       return true;
  //     }
  //     return false;
  //   } else {
  //     return false;
  //   }

  // }

  return (
    <div className='video-container'>
      <video
        // style={isPortrait ? { right: '-50%', bottom: '-50%' } : { right: '-25%', bottom: '0%' }}
        // onMouseEnter={hoverPlay}
        // onMouseLeave={hoverPlay}
        onClick={handlePlay}
        ref={playerRef}
        id="video"
        preload="metadata"
        poster={poster || ''}
        // muted
        playsInline={true}
      >
        <source src={src}>
        </source>
      </video>
      <div className='controls-container'>
        <div className='closed-captions'>
          {cc && cc.length > 0 ?
            <div className='cc'>{cc}</div> :
            null
          }
        </div>
        <div className='controls'>
          <div className='left'>
            <div className='play-pause' onClick={handlePlay}>
              {isPlaying ?
                <i className='icon icon-fk-pause'></i> :
                <i className='icon icon-fk-play'></i>
              }
            </div>
            <span className='duration'>
              {formatTime(currentTime)}/{formatTime(duration)}
            </span>
          </div>
          <div className='right'>
            <div className='volume' onClick={handleVolume}>
              {isMuted ?
                <i className='icon icon-fk-mute'></i> :
                <i className='icon icon-fk-unmute'></i>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
