import React, { useState } from "react"

export function TextArea(props) {
  const [input, setInput] = useState(props.value || props.staticText || '');
  const [enter, setEnter] = useState(false);
  function handleKeyDown(e) {
    // console.log('handleKeyDown', e.key, e.key === 'Enter');
    if(e.key === 'Enter') {
      setEnter(true)
    }
    // setKey(e.key);
    // console.log(key);
  }
  const onChange = (e) => {
    let currentInput = e.target.value;
    if(props.staticText) {
      currentInput = e.target.value.length < props.staticText.length ? props.staticText : e.target.value;
    }
    setInput(currentInput.substring(0, props.limit));
    if(props.onEnter) {
      if(enter) {
        props.getText(currentInput);
      }
    } else {
      props.getText(currentInput);
    }
  }
  return (
    <textarea 
      className={input.length < 32 ? 'error': ''}
      type='text' 
      onKeyDown={handleKeyDown} 
      value={input} 
      onChange={onChange}
      placeholder={props.placeholder} />
  )
}
