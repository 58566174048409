import React, { Component } from "react";

import Landing from "./experience/Landing.js";
import PostsPopup from "./experience/PostsPopup";
import GalleryOld from "./experience/GalleryOld.js";
import Leaderboard from "./experience/Leaderboard.js";

import Home from "./component/Home.js";
import Highlights from "./experience/Highlights.js";
import HighlightsScroll from "./experience/HighlightsScroll.js";
import Gallery from "./experience/Gallery.js";
import GalleryScroll from "./experience/GalleryScroll.js";
import Lounge from "./experience/Lounge.js";

import ImpactGallery from "./experience/ImpactGalleryExperience";
import Stories from "./experience/Stories";

import {
  checkIfAeApps,
  getEmbedThemeName,
  getStorageExperience,
} from "./theme/Loader.js";

import "./App.css";
import "./theme/styles.css";
import { triggerSAEvent } from "./utils.js";
import User from "./component/User.js";
import Link from "./component/Link.js";
import ExternalLink from "./component/ExternalLink.js";
import Carousel from "./experience/Carousel.js";
import BadgeGenerate from "./experience/Badge.js";
import { AnalyticsProviderClass} from './Providers/AnalyticsProviderClass.js'
// import VideoGallery from './component/VideoGallery.js';

const EXPERIENCES = {
  user: User,
  landing: Landing,
  postspopup: PostsPopup,
  galleryold: GalleryOld,
  leaderboard: Leaderboard,
  "cisco-leaderboard": Leaderboard,
  home: Home,
  highlights: Highlights,
  "impact-gallery": ImpactGallery,
  gallery: Gallery,
  "gallery-scroll": GalleryScroll,
  "highlights-scroll": HighlightsScroll,
  lounge: Lounge,
  link: Link,
  "external-link": ExternalLink,
  stories: Stories,
  carousel: Carousel,
  badge: BadgeGenerate
};

const CustomRender = (props) => {
  // console.log('CUSTOM RENDER', props);
  const Element = props.experience;
  return (
      <Element
        amplitude={props.amplitude}
        prevExperience={props.prevExperience}
        experienceName={props.experienceName}
        experience={props.experience}
        category={props.category}
        setExperience={props.setExperience}
        setCategory={props.setCategory}
      />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    window.addEventListener(
      "experienceConfigEvent",
      this.triggerStorageListener
    );
    const experienceConfig = getStorageExperience() || {
      experience: window?.fkEmbedConfig?.experience,
      theme: window?.fkEmbedConfig?.theme,
      category: window?.fkEmbedConfig?.category,
      lang: window?.fkEmbedConfig?.lang,
      prevExperience: "",
    };
    this.state = experienceConfig;
    this.amplitude = null;
  }
  // EXPERIENCES[window?.fkEmbedConfig?.experience]

  componentDidMount() {
    try {
      this.amplitude = new AnalyticsProviderClass('Social Hub')
      this.setState({amplitude: true})
    } catch(err) {
      console.error('Error initializing amplitude')
    }
    // const script = document.createElement("script");
    // script.src = "https://scripts.simpleanalyticscdn.com/latest.js";
    // script.async = true;
    // script.defer = true;
    // document.body.appendChild(script);
    // const noscript = document.createElement("noscript");
    // const img = document.createElement("img");
    // img.src = "https://queue.simpleanalyticscdn.com/noscript.gif";
    // img.alt = "";
    // noscript.appendChild(img);
    // document.body.appendChild(noscript);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "experienceConfigEvent",
      this.triggerStorageListener
    );
  }

  triggerSA = () => {
    // trigger Simple Analytics Event
    // console.log('fk SAEvent Trigger');
    triggerSAEvent(`visit ${this.state.experience}`);
    // if(window.sa_event) {
    //   console.log(`fk SAEvent function call: ${this.state.experience}`);
    //   window.sa_event(`${this.state.experience}`)
    // }
  };

  triggerStorageListener = (e) => {
    const experienceConfig = getStorageExperience() || {
      experience: "",
      theme: "",
      category: "",
      lang: "",
      prevExperience: "",
    };
    this.setState(() => experienceConfig, this.triggerSA);
  };

  setExperience = (experience) => {
    // console.log('in experience app');
    this.setState(
      (prevState) => ({ experience, prevExperience: prevState.experience }),
      this.triggerSA
    );
  };

  setCategory = (category) => {
    // console.log('in set category app', category)
    this.setState({ category });
  };

  render() {
    let themeName = getEmbedThemeName();
    const aeApps = checkIfAeApps();
    return (
      <div className={`${themeName} ${aeApps ? "aeApps" : ""}`}>
        {Boolean(this.state.experience) && (
          <CustomRender
            amplitude={this.amplitude}
            prevExperience={this.state.prevExperience}
            experienceName={this.state.experience}
            experience={EXPERIENCES[this.state.experience]}
            category={this.state.category}
            setExperience={this.setExperience}
            setCategory={this.setCategory}
          />
        )}
      </div>
    );
  }
}

export default App;
