import React, { Component } from 'react';

import { getFeatured, getAll } from '../Services.js';

import './postspopup.css';

import { getEmbedThemeInfo } from '../theme/Loader.js';
import Cardv2 from '../component/Cardv2.js';
import { Container } from '../component/Container.js';

export default class PostsPopup extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      posts: [],
      post: null,
      status: false,
      fadeIn: false,
      fadeOut: false
    };
  }

  apiFeatured = () => {
    getFeatured(posts => {
      // fade in
      this.setState({ posts, status: false, fadeIn: true, fadeOut: false })
    })
  }

  apiAll = () => {
    getAll(posts => {
      // fade in
      this.setState({ posts, status: true, fadeIn: true, fadeOut: false })
    })
  }

  fetchApi = () => {
    if (this.state.status) {
      this.setState({ fadeIn: false, fadeOut: true }, this.apiFeatured);
    } else {
      this.setState({ fadeIn: false, fadeOut: true }, this.apiAll);
    }
  }

  schedule = (refreshRate) => {
    this.interval = setInterval(() => this.fetchApi(), refreshRate * 1000);
  }

  componentDidMount() {
    let { postspopup: { refresh, refreshRate } } = getEmbedThemeInfo();
    this.fetchApi();
    if (refresh) {
      this.schedule(refreshRate);
      return;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  popUp = post => {
    this.setState({ post });
  };

  reset = () => {
    this.setState({ post: null });
  };

  getItems() {
    console.log('getEmbedThemeInfo', getEmbedThemeInfo());
    let noOfPosts = getEmbedThemeInfo().count || 4;
    let postsToRender = this.state.posts.slice(0, noOfPosts);
    return postsToRender.map(post => (
      <Cardv2 key={post.getId()} post={post} showPopup={this.popUp} />
    ));
  }

  render() {
    // console.log('postspopup props', this.props);
    if (this.state.posts.length <= 0) {
      return (<div className='spinner'></div>);
    }
    if (this.state.post) {
      return (
        <div className="overlay">
          <Cardv2 post={this.state.post} />
          <div className="close">
            <div onClick={this.reset}>X</div>
          </div>
        </div>
      );
    }
    return (
      <Container experience={this.props.experience} {...this.props}>

        <div className={`cards ${this.state.fadeIn ? 'fade-in' : ''} ${this.state.fadeOut ? 'fade-out' : ''}`}>
          {this.getItems()}
        </div>
      </Container>
    );
  }
}
