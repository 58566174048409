import React, { useEffect, useRef, forwardRef, useReducer } from "react";
import "./autoComplete.css";

const OptionsList = forwardRef(
  (
    { options, selectedOption = null, onSelect, onHover, currentInput },
    ref
  ) => {
    return (
      <ul className="fk-options" ref={ref}>
        {options.slice(0, 6).map((option, index) => {
          return (
            <li
              id={`fk-option-${index}`}
              className={`fk-options-item ${
                selectedOption === index ? "fk-hovered" : ""
              }`}
              key={`${option}-${index}`}
              onClick={(e) => onSelect(option, e)}
              onMouseEnter={onHover}
            >
              <span className="fk-matched-suggestion">{currentInput}</span>
              {option.substring(currentInput.length, option.length)}
            </li>
          );
        })}
      </ul>
    );
  }
);

const reducer = (state, newState) => {
  return { ...state, ...newState };
};

const filterOptions = (options, key) => {
  return options.filter((option) =>
    option.toLowerCase().startsWith(key.toLowerCase())
  );
};

export const AutoComplete = ({
  selectedFilters = {},
  options = [],
  onChange = () => {},
  onSubmit = () => {},
  isMobile = false,
}) => {
  const containerRef = useRef(null);
  const dropDownRef = useRef(null);
  const inputRef = useRef(null);

  const { author, hashtag } = selectedFilters;
  const initialState = {
    userInput: `${hashtag ? "#" : ""}${hashtag?.[0] || author?.[0] || ""}`,
    selectedOption: null,
    filteredOptions: options,
    showSuggestions: false,
  };
  const [
    { userInput, selectedOption, filteredOptions, showSuggestions },
    dispatch,
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    const inputValue = `${hashtag ? "#" : ""}${
      hashtag?.[0] || author?.[0] || ""
    }`;
    dispatch({
      userInput: inputValue,
      filterOptions: filterOptions(options, inputValue),
    });
  }, [author, hashtag, options]);

  useEffect(() => {
    dispatch({
      filteredOptions: filterOptions(options, userInput),
    });
    // eslint-disable-next-line
  }, [options, userInput]);

  const onFocus = () => {
    window.dispatchEvent(
      new CustomEvent("searchfocus", {
        detail: {
          isFocused: true,
        },
      })
    );
    dispatch({ showSuggestions: Boolean(userInput && filteredOptions.length) });
  };

  const onBlur = () => {
    window.dispatchEvent(
      new CustomEvent("searchfocus", {
        detail: {
          isFocused: false,
        },
      })
    );
  };

  const handleClickOutside = (e) => {
    if (containerRef.current && dropDownRef.current) {
      if (
        !containerRef.current.contains(e.target) &&
        !dropDownRef.current.contains(e.target)
      ) {
        window.dispatchEvent(
          new CustomEvent("searchfocus", {
            detail: {
              isFocused: false,
            },
          })
        );
        dispatch({ showSuggestions: false, selectedOption: null });
      }
    }
  };

  const handleChange = (e) => {
    const currentInput = e.currentTarget.value;
    const currentOptions = filterOptions(options, currentInput);
    dispatch({
      selectedOption: null,
      filteredOptions: currentOptions,
      userInput: currentInput,
      showSuggestions: currentInput && currentOptions.length,
    });
    onChange(userInput, e);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (selectedOption !== null) {
        const currentInput = filteredOptions[selectedOption];
        const currentOptions = filterOptions(options, currentInput);
        dispatch({ userInput: currentInput, filteredOptions: currentOptions });
        handleSubmit(currentInput, e);
      } else {
        handleSubmit(userInput, e);
      }
      dispatch({ showSuggestions: false, selectedOption: null });
      inputRef.current.blur();
      return;
    }

    //Keyboard Navigation
    if (showSuggestions) {
      const itemsLength =
        filteredOptions.length < 6 ? filteredOptions.length : 6;
      if (e.keyCode === 38) {
        const currentOption =
          selectedOption !== null
            ? (itemsLength + selectedOption - 1) % itemsLength
            : itemsLength - 1;
        dispatch({ selectedOption: currentOption });
      }
      if (e.keyCode === 40) {
        const currentOption =
          selectedOption !== null ? (selectedOption + 1) % itemsLength : 0;
        dispatch({ selectedOption: currentOption });
      }
    }
  };

  const handleSearchClick = (e) => {
    dispatch({ showSuggestions: false, selectedOption: null });
    handleSubmit(userInput, e);
  };

  const handleSubmit = (value) => {
    if (value.startsWith("#")) {
      return onSubmit(value.substring(1), "hashtag");
    }
    return onSubmit(value, "author");
  };

  return (
    <div
      className={`fk-search ${isMobile ? "fk-search-mobile" : null}`}
      ref={containerRef}
    >
      <div className="fk-search-field">
        <input
          ref={inputRef}
          type="text"
          className="fk-search-box"
          value={userInput}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder="Name or #Hashtag"
        />
        <div className="fk-search-btn" onClick={handleSearchClick} />
      </div>
      {showSuggestions ? (
        <OptionsList
          ref={dropDownRef}
          options={filteredOptions}
          currentInput={userInput}
          selectedOption={selectedOption}
          onHover={() => {
            dispatch({ selectedOption: null });
          }}
          onSelect={(selectedOption) => {
            const currentOptions = filterOptions(options, selectedOption);

            dispatch({
              userInput: selectedOption,
              filteredOptions: currentOptions,
              showSuggestions: false,
              selectedOption: null,
            });
            handleSubmit(selectedOption);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
