import React, { createContext, useContext, useEffect, useState } from 'react'
import amplitude from 'amplitude-js'

const AMPLITUDE_API_KEY = '5306297edf15a76599e4ca3d4391e7c4'
const AMPLITUDE_OPTIONS = {
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
  disableCookies: true,
  trackingOptions: {
    city: true,
    country: true,
    carrier: true,
    device_manufacturer: true,
    device_model: true,
    dma: true,
    ip_address: true,
    language: true,
    os_name: true,
    os_version: true,
    platform: true,
    region: true,
    version_name: true,
  },
}

const initialState = {
  instance: null,
}

const AnalyticsContext = createContext({
  state: {
    ...initialState,
  },
  dispatchAnalyticsEvent: () => undefined,
  createUser: () => undefined,
})

export const useAnalyticsContext = () => useContext(AnalyticsContext)

export const AnalyticsProvider = (props) => {
  const { widget } = props
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    try {
      const instance = amplitude.getInstance(widget)
      instance.init(AMPLITUDE_API_KEY, null, AMPLITUDE_OPTIONS)
      instance.setTransport('beacon')
      setInstance(instance)
    } catch (err) {
      console.error('Amplitude Error', err)
    }
  }, [widget])

  // String User ID
  const createUser = (user, userProperties = {}) => {
    try {
      instance.setUserId(user)
      instance.setUserProperties(userProperties)
      instance.regenerateDeviceId()
    } catch (err) {
      console.error('Amplitude Error', err)
    }
  }

  // String Event Name, Object Event Values
  const dispatchAnalyticsEvent = (eventName, eventProperties = {}) => {
    try {
      instance.logEvent(eventName, eventProperties)
    } catch (err) {
      console.error('Amplitude Error', err)
    }
  }

  return (
    <AnalyticsContext.Provider
      value={{ state: { instance }, createUser, dispatchAnalyticsEvent }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  )
}
