import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { VideoPlayer } from "../VideoPlayer/VideoPlayer";
import { Loader } from "../Loader/Loader";
// import { getImageSize } from "../utils";
import ChatBubble from "../../../assets/impact21/images/default.jpg";
import nextIcon from "../../../assets/next-icon.png";
import prevIcon from "../../../assets/prev-icon.png";
import "./story.css";
import { CrossIcon } from "../Icons";

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} fk-slider-icons`}
      onClick={(e) => {
        onClick(e);
        e.stopPropagation();
      }}
    >
      <img className="fk-slider-icon" src={nextIcon} alt="next-icon" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} fk-slider-icons`}
      onClick={(e) => {
        onClick(e);
        e.stopPropagation();
      }}
    >
      <img className="fk-slider-icon" src={prevIcon} alt="prev-icon" />
    </div>
  );
};

const Slide = ({ url, isExpanded = false }) => {
  const imgRef = useRef(null);
  const quickPinchZoomRef = useRef(null);

  const onUpdate = ({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img && img.complete) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
      img.style.opacity = 1;
    }
  };

  const reset = () => {
    const { current: img } = imgRef;
    if (img && img.complete) {
      img.style.setProperty("transform", "unset");
      img.style.opacity = 1;
    }
  };

  const resetZoom = () => {
    const { current: img } = imgRef;
    const { current: quickPinchZoomElement } = quickPinchZoomRef;

    const { width, height } = img;
    const centerX = width / 2;
    const centerY = height / 2;

    quickPinchZoomElement.alignCenter({ x: centerX, y: centerY, scale: 1 });
  };

  // The image will keep it's zoom when it is swiped, we want to reset the zoom when we swipe back
  // to an image therefore we implement this here.
  useEffect(() => {
    if (!isExpanded) {
      reset();
    }
    return () => resetZoom();
  }, [isExpanded]);

  return (
    <QuickPinchZoom
      onUpdate={isExpanded ? onUpdate : () => {}}
      // onUpdate={() => {}}
      ref={quickPinchZoomRef}
      draggableUnZoomed={false}
      enabled={isExpanded}
      lockDragAxis
      shouldInterceptWheel={() => false}
    >
      <img
        className="fk-image"
        src={url}
        alt=""
        ref={imgRef}
        crossOrigin="anonymous"
      />
    </QuickPinchZoom>
  );
};

const VideoViewer = ({ thumbUrl, url, autoPlay, style, duration }) => {
  return (
    <VideoPlayer
      thumbUrl={thumbUrl}
      url={url}
      duration={duration}
      autoPlay={autoPlay}
      style={style}
    />
  );
};

const ImageViewer = ({ urls = [], isExpanded = false }) => {
  const settings = {
    dots: true,
    infinite: true,
    touchMove: false,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider
      className={`fk-image-slider ${isExpanded ? "fk-slide-expanded" : ""}`}
      {...settings}
    >
      {urls.map(({ url }, index) => (
        <Slide url={url} key={`image-${index}`} isExpanded={isExpanded} />
      ))}
    </Slider>
  );
};

const HeartIcon = () => (
  <svg x="0px" y="0px" width="25px" height="25px" viewBox="-1 -1 17 17">
    <path
      d="M13.91,6.75c-1.17,2.25-4.3,5.31-6.07,6.94c-0.1903,0.1718-0.4797,0.1718-0.67,0C5.39,12.06,2.26,9,1.09,6.75
 C-1.48,1.8,5-1.5,7.5,3.45C10-1.5,16.48,1.8,13.91,6.75z"
    ></path>
  </svg>
);

export const Story = ({
  story,
  texture = null,
  isLoading = false,
  width = "100%",
  height = "100%",
  autoPlay = false,
  isLikeLoading = false,
  isMobile = false,
  onLikeClick = () => {},
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isImage = (story.getMediaType() || "").includes("image");
  const tags = story.getHashtags() || ["myFY21Impact"];
  const userImage = story.getAuthorPhoto();
  // const imageSize = story.getMediaSize();
  const likes = story.getLikes() || 0;
  const author = `${story.getAuthorName() || "Anonymous User"}${
    story.getAuthorAlias() ? ` (${story.getAuthorAlias()})` : ""
  }`;
  const storyText = story.getText().slice(0, 280);
  const hashtags = tags.map((tag) => `#${tag} `);
  const { isLiked = false } = story.getUserMetrics() || {};
  const imageRef = useRef(null);
  // const [adjustedSize, setAdjustedSize] = useState(
  //   getImageSize(
  //     { width: imageSize.w || 300, height: imageSize.h || 400 },
  //     { width: 300, height: isImage ? 420 : 380 }
  //   )
  // );

  // useEffect(() => {
  //   setAdjustedSize(
  //     getImageSize(
  //       { width: imageSize.w || 300, height: imageSize.h || 400 },
  //       {
  //         width: imageRef.current?.clientWidth - 40 || 300,
  //         height: isImage ? 420 : 380,
  //       }
  //     )
  //   );
  // }, [imageSize, isImage]);

  // console.log("Story Data", story);

  const handleImagePop = (e) => {
    if (isMobile) {
      setIsExpanded(true);
    }
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setIsExpanded(false);
    e.stopPropagation();
  };
  return (
    <div
      className="fk-post-container"
      id="post"
      style={{ width, maxHeight: height }}
      ref={imageRef}
      onClick={(e) => e.stopPropagation()}
    >
      {isLoading && <Loader />}
      {isImage ? (
        <div
          className={`fk-post-image ${
            isExpanded ? "fk-post-expanded" : "fk-post-collapsed"
          }`}
          style={{}}
          onClick={handleImagePop}
        >
          {isExpanded && (
            <span className="fk-cross-icon" onClick={handleClose}>
              <CrossIcon />
            </span>
          )}
          <ImageViewer urls={story.getMediaUrls()} isExpanded={isExpanded} />
        </div>
      ) : (
        <div className="fk-post-video" style={{}}>
          <VideoViewer
            url={story.getVideoUrl()}
            thumbUrl={story.getMediaUrl()}
            duration={story.getMediaDuration()}
            autoPlay={autoPlay}
          />
        </div>
      )}
      <div className="fk-post-content">
        <div className="fk-text">{storyText}</div>
        <div className="fk-tags">{hashtags}</div>
        <div className="fk-user-details">
          <div className="fk-user-image">
            <img
              src={userImage || ChatBubble}
              onError={(e) => {
                if (e?.target?.src) {
                  e.target.src = ChatBubble;
                }
              }}
              className="fk-image"
              alt=""
            />
          </div>
          <div className="fk-user-name">{author || "Anonymous User"}</div>
          <div
            className={`fk-user-likes ${isLikeLoading ? "fk-disabled" : ""}`}
            onClick={() => !isLikeLoading && onLikeClick()}
          >
            <div
              className={`${isLiked ? "fk-liked-heart-icon" : "fk-heart-icon"}`}
            >
              <HeartIcon />
            </div>
            <div className="fk-likes-count">{likes}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
