import React, { Component } from 'react';
import periscope from '../assets/periscope.png';
import defaultImg from '../assets/default.jpg';
import { getThemeInfo } from "../theme/Loader";

import './textfeed.css';

export default class TextFeed extends Component {
  authorName() {
    let { post } = this.props;
    return <div className="name">{post.getAuthorName()}</div>;
  }

  truncate = (s = "", portrait = false) => {
    let charLimit = getThemeInfo().char_text_limit || 120;
    if (s.length <= charLimit) {
      return s;
    } else {
      return s.slice(0, charLimit).concat("...");
    }
  };

  getLikes() {
    let { post } = this.props;
    if (post.getLikes() > 0) {
      return (
        <span>
          <i className="like-icon-fk icon-fk-heart-empty" />
          <span className="like-count">{post.getLikes()}</span>
        </span>
      );
    }
    return <i className="like-icon-fk icon-fk-heart-empty"><span className="like-count"></span></i>;
  }
  getSource() {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === 'twitter') {
      return (
        <span className="src-twitter">
          <i className="icon-fk-twitter-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === 'instagram') {
      return (
        <span className="src-instagram">
          <i className="icon-fk-instagram-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === 'youtube') {
      return (
        <span className='src-twitter'>
          <i className='icon-fk-youtube' />
        </span>
      )
    }
    if (post.getSource() && post.getSource().toLowerCase() === 'periscope') {
      return (
        <span className='src-twitter'>
          <img src={periscope} alt="" />
        </span>
      )
    }
    return null;
  }

  getRetweets() {
    let { post } = this.props;
    if (post.getRetweets() > 0) {
      return (
        <span>
          <i className="retweet-icon-fk icon-fk-retweet" />
          <span className="retweet-count">{post.getRetweets()}</span>
        </span>
      );
    }
    return <i className="retweet-icon-fk icon-fk-retweet"><span className="retweet-count"></span></i>;
  }

  render() {
    let { post } = this.props;
    return (
      <a className="cardv2-a-tag" href={`${post.getUrl()}`} target="_blank" rel="noopener noreferrer">
        <div className='text-feed'>
          <div className="details">
            <div className="author">
              <img className="avatar" src={post.getAuthorPhoto() || defaultImg} alt="" />
              {this.authorName()}
              <div className="handle">{post.getAuthorHandle()}</div>
              <span className="created-at">{post.getCreatedAt()}</span>
            </div>
            <div className="msg">
              {this.truncate(post.getText())}
            </div>
          </div>
          <div className="stats">
            <div className="source">{this.getSource()}</div>
            <div>
            {this.getRetweets()}
              {this.getLikes()}
              </div>
          </div>
        </div>
      </a>
    );
  }
}
