import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import './styles.scss';
import { getEmbedExperienceText } from '../../theme/Loader';

const BadgeComponent = (props) => {
  const badgeWidth = 300;
  const badgeHeight = 500;
  const fabricCanvas = useRef(null);
  const imgCanvas = useRef(null);

  const toDataURLPromise = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  useEffect(() => {
    if (fabricCanvas.current) {
      return;
    }
    initFabric();
    //eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   renderText();
  // }, [props.userObj])

  const renderText = () => {
    const { location, handle, lastName, firstName } = props.userObj;
    location && fabricCanvas.current.add(new fabric.Text('Attending from', {
      left: 77,
      top: 255,
      fill: '#14294b',
      // fill: '#ffffff',
      evented: false,
      fontSize: 20,
      fontFamily: 'CiscoSansThin',
      fontWeight: 'normal'
    }));
    location && fabricCanvas.current.add(new fabric.Text(location, {
      left: 77,
      top: 280,
      fill: '#14294b',
      // fill: '#ffffff',
      evented: false,
      fontSize: 20,
      fontFamily: 'CiscoSansTT',
      fontWeight: 'bold'
    }));

    handle && fabricCanvas.current.add(new fabric.Text(handle, {
      left: 50,
      top: 205,
      fill: '#14294b',
      evented: false,
      fontSize: 24,
      fontFamily: 'CiscoSansThin',
      fontWeight: 600
    }));

    lastName && fabricCanvas.current.add(new fabric.Text(lastName, {
      left: 50,
      top: 160,
      fill: '#14294b',
      evented: false,
      fontSize: 24,
      fontFamily: 'CiscoSansThin',
      // fontWeight: 400
    }));

    firstName && fabricCanvas.current.add(new fabric.Text(firstName, {
      left: 50,
      top: 130,
      fill: '#14294b',
      evented: false,
      fontSize: 24,
      fontFamily: 'CiscoSansThin',
      fontWeight: 800
    }));
  }

  const setBackground = (url) => {
    var myImage = new Image();
    myImage.src = url;
    myImage.addEventListener('load', () => {
      const scaleX = badgeWidth / myImage.width;
      const scaleY = badgeHeight / myImage.height;
      fabric.Image.fromURL(url, (img) => {
        fabricCanvas.current.setBackgroundImage(img, () => {
          fabricCanvas.current.requestRenderAll();
          renderText()
        }, { scaleX, scaleY });
      })
    })
    // https://stackoverflow.com/questions/23104582/scaling-an-image-to-fit-on-canvas
  }

  const initFabric = () => {
    fabricCanvas.current = new fabric.Canvas(`fabricCanvas-${props.index}`, {
      width: badgeWidth,
      height: badgeHeight,
      selection: false,
      targetFindTolerance: 2,
      enableRetinaScaling: false,
      controlsAboveOverlay: false
    });

    toDataURLPromise(props.pic)
      .then(imageUrl => {
        setBackground(imageUrl)
      })
      .catch(err => {
        console.error('Error setting canvas', err)
      })

    // setBackground(props.pic);
  }

  const download = () => {
    var canvas = fabricCanvas.current;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    let url = canvas.toDataURL();
    var a = document.createElement('a');
    a.href = url;
    a.download = 'badge.png';
    a.click();
    try {
      props.onDownload();
    } catch (err) {
      console.log('error calling props: ', err)
    }
  }

  return (
    <div className={`fk-container ${props.display ? '' : 'hide'}`}>
      <div className='working-space'>
        <canvas id={`fabricCanvas-${props.index}`} className='fabric'></canvas>
        <canvas id="canvas" ref={imgCanvas} className='canvas'></canvas>
        <div className='download-copy'>{getEmbedExperienceText('badge', 'download-copy')}</div>
        <div className='download' onClick={download}>Download</div>
      </div>
    </div>
  )
}

export default BadgeComponent;