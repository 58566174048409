import * as THREE from "three";
// eslint-disable-next-line import/no-webpack-loader-syntax
const frag = require("!!raw-loader?esModule=false!../Shaders/item.frag");
// eslint-disable-next-line import/no-webpack-loader-syntax
const vert = require("!!raw-loader?esModule=false!../Shaders/default.vert");

export default class Item extends THREE.Group {
  constructor(opts) {
    super();
    Object.assign(this, opts);
    this.create();
  }

  create() {
    this.uniforms = {
      time: { type: "f", value: 1.0 },
      fogColor: { type: "c", value: new THREE.Color(this.config.fog.color) },
      fogNear: { type: "f", value: this.config.fog.fogNear },
      fogFar: { type: "f", value: this.config.fog.fogFar },
      tex: { type: "t", value: this.texture },
      opacity: { type: "f", value: 1.0 },
      progress: { type: "f", value: 0.0 },
      gradientColor: { type: "vec3", value: new THREE.Color(0x1b42d8) },
      resolution: {
        type: "v2",
        value: new THREE.Vector2(200, 300),
      },
      imageResolution: {
        type: "v2",
        value: new THREE.Vector2(
          this.texture.image.width,
          this.texture.image.height
        ),
      },
    };
    this.material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      fragmentShader: frag,
      vertexShader: vert,
      fog: true,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.scale.set(this.texture.size.width, this.texture.size.height, 1);
    // updates size of meshes after texture has been loaded
    // this.texture.onUpdate = () => {
    //   if (
    //     this.mesh.scale.x !== this.texture.size.width &&
    //     this.mesh.scale.y !== this.texture.size.height
    //   ) {
    //     this.mesh.scale.set(
    //       this.texture.size.width,
    //       this.texture.size.height,
    //       1
    //     )
    //     this.texture.onUpdate = null
    //   }
    // }

    const pos = new THREE.Vector2();
    let align = this.itemIndex % 8;

    let creationPolarity = null;
    if (this.creationType) {
      creationPolarity = this.creationType === "newer" ? +1 : -1;
    }
    // Pagination
    // if (this.adjacentItem) {
    //   align = this.adjacentItem.itemIndex + (creationPolarity % 4)
    // }
    const x = Math.round(Math.random() * ((this.config.width - 150) / 2)) + 200;
    const y =
      Math.round(Math.random() * ((this.config.height - 150) / 2)) + 200;
    // const x = 300
    // const y = 300
    if (align === 0) pos.set(-x, y); // 1
    if (align === 1) pos.set(x, -y); // 9
    if (align === 2) pos.set(-x, 0); // 4
    if (align === 3) pos.set(x, y); // 3
    if (align === 4) pos.set(0, -y); // 8
    if (align === 5) pos.set(0, y); // 2
    if (align === 6) pos.set(-x, -y); // 7
    if (align === 7) pos.set(x, 0); // 6
    // const positionNumber = Math.round(Math.random() * 300) + 100
    // if (align === 0) pos.set(-positionNumber, positionNumber) // bottom left
    // if (align === 1) pos.set(positionNumber, positionNumber) // bottom right
    // if (align === 2) pos.set(positionNumber, -positionNumber) // top right
    // if (align === 3) pos.set(-positionNumber, -positionNumber) // top left
    // pos.set(
    //   Math.round(Math.random() * this.config.width - this.config.width / 2),
    //   Math.round(
    //     (Math.random() * this.config.height) / 2 - this.config.height / 4
    //   )
    // )
    this.position.set(
      pos.x,
      pos.y,
      this.adjacentItem
        ? this.adjacentItem.position.z +
            creationPolarity * this.config.distanceFactor
        : this.itemIndex * this.config.distanceFactor
    );
    this.origPos = new THREE.Vector2(pos.x, pos.y);
    this.add(this.mesh);
  }

  setPosition(newPosition) {
    this.position.set(newPosition.x, newPosition.y, newPosition.z);
    this.origPos = new THREE.Vector2(newPosition.x, newPosition.y);
  }
}
