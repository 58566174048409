import _ from 'lodash';
export const transform = (v2post) => {
  return ({
    author: {
      alias: v2post.author?.alias,
      followers: v2post.author?.followers,
      id: v2post.author?.id,
      location: v2post.author?.location,
      name: v2post.author?.name,
      photo: v2post.author?.photo,
      verified: v2post.author?.verified
    },
    createdAt: v2post.createdAt,
    hashtags: _.get(v2post, 'hashtags', []),
    id: v2post.id,
    lang: v2post.lang,
    media: mediav2tov1(_.get(v2post, 'media', [])),
    meta: {
      moderation: _.get(v2post, 'moderation', {})
    },
    metrics: _.get(v2post, 'metrics', {likes: 0, shares: 0}),
    nativeId: _.get(v2post, 'id'),
    source: {logoUrl: "", name: _.get(v2post, 'source')},
    text: _.get(v2post, 'caption', ''),
    type: _.get(v2post, 'contentType'),
    url: _.get(v2post, 'url')
  })
}

const mediav2tov1 = (v2media) => {
  return v2media.map(mediaObj => ({
    id: mediaObj.id,
    mediaType: mediaObj.mediaType,
    sizes: mediaObj.sizes? mediaObj.sizes: {"full": {"h": 460, "w": 360}},
    thumbUrl: mediaObj.thumbUrl,
    url: mediaObj.url
  }))
}