import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
} from "react";

import { AutoComplete } from "../AutoComplete/AutoComplete";
import { getImpactSearchNoAuth } from "../../../Services";
import { useAnalyticsContext } from "../../../Providers/AnalyticsProvider";
import FiltersIcon from "../../../assets/filters-icon.svg";

import "./header.css";
import { FiltersDropdown } from "../Filters/FiltersDropdown/FiltersDropdown";

const transformSearch = (searchValues) => {
  const { names = [], hashtags = [] } = searchValues;
  return [
    ...new Set(names),
    ...new Set(hashtags.map((hashtag) => (hashtag ? `#${hashtag}` : ""))),
  ].filter((option) => !!option);
};

export const Header = forwardRef(
  (
    {
      selectedFilters = {},
      onFilterApplied = () => {},
      refreshToken = () => {},
      isMobile = false,
    },
    ref
  ) => {
    const { dispatchAnalyticsEvent } = useAnalyticsContext();
    const [searchOptions, setSearchOptions] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const headerRef = useRef(null);

    const getSearchOptions = useCallback(() => {
      return getImpactSearchNoAuth(
        (searchList) => {
          if (searchList) {
            setSearchOptions(transformSearch(searchList));
          }
        },
        (error) => {
          if (error.name === "UnauthorizedError") {
            refreshToken();
          }
        }
      );
    }, [refreshToken]);

    useImperativeHandle(
      ref,
      () => {
        return {
          getSearchOptions,
        };
      },
      [getSearchOptions]
    );

    useEffect(() => {
      getSearchOptions();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
    }, []);

    const handleClickOutside = (e) => {
      if (headerRef.current) {
        if (!headerRef.current.contains(e.target)) {
          setOpenDropdown(false);
        }
      }
    };

    const handleRefresh = () => {
      getSearchOptions();
      dispatchAnalyticsEvent("GALLERY_REFRESH");
      onFilterApplied({});
      setOpenDropdown(false);
    };

    const handleFiltersConfirm = (filters) => {
      const { author, ...remainingFilters } = filters;
      getSearchOptions();
      onFilterApplied(remainingFilters);
      setOpenDropdown(false);
    };

    const handleSearchSubmit = (selectedFilter, type) => {
      dispatchAnalyticsEvent("FILTER_SEARCH", { selectedFilter, type });
      getSearchOptions();
      setOpenDropdown(false);
      return onFilterApplied({
        [type]: [selectedFilter],
      });
      // if (selectedFilter) {
      //   return onFilterApplied({
      //     // ...selectedFilters,
      //     author: [selectedFilter],
      //   });
      // }
      // // eslint-disable-next-line
      // const { author, ...remainingFilters } = selectedFilters;
      // return onFilterApplied(remainingFilters);
    };
    
    const filtersCount =
      (selectedFilters?.group?.length || 0) +
      (selectedFilters?.dept?.length || 0);
    return (
      <div
        className={`fk-header-wrapper ${
          isMobile ? "fk-header-wrapper-mobile" : ""
        }`}
        ref={headerRef}
      >
        <div className="fk-filter-wrapper">
          <div
            className="fk-filters-anchor"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <div className="fk-filters-anchor-button">
              <img
                src={FiltersIcon}
                alt=""
                className="fk-filters-anchor-icon"
              />
              <span className="fk-filters-anchor-text">{`Filters & Sort`}</span>
              {Boolean(filtersCount) && (
                <span className="fk-filters-count">{filtersCount}</span>
              )}
            </div>
          </div>
          <div className="fk-header-refresh-container">
            <div className="fk-header-refresh" onClick={handleRefresh}></div>
          </div>
        </div>

        <AutoComplete
          selectedFilters={selectedFilters}
          options={searchOptions}
          onSubmit={handleSearchSubmit}
          isMobile={isMobile}
        />
        {openDropdown && (
          <FiltersDropdown
            selectedFilters={selectedFilters}
            onFiltersConfirm={handleFiltersConfirm}
            isMobile={isMobile}
          />
        )}
      </div>
    );
  }
);
