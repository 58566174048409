import React, { Component } from 'react';
import './category.css';

export class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={`category-container ${this.props.className}`}>
        <div className='category-image' onClick={this.props.redirect}>
          <img src={this.props.img} alt='icon-fk' />
        </div>
        <div className='category-details'>
          <div className='category-text'>{this.props.text}</div>
          <div className='category-button' onClick={this.props.redirect}>
            <div>{this.props.btnText}</div>
          </div>
        </div>
      </div>
    )
  }
}
