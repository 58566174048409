import React, { useState, useEffect } from 'react';
import { ImageRecorder, videoConstraints } from './ImageRecorder';
// import { ImageCapturee } from './ImgRecorder';
// import {VideoRecorder as ImageRecorder} from './VideoRecorder';
import qs from 'qs';
import _ from 'lodash';
import { StickerSelect } from './AddBgAndStickers';
import { EXPERIENCE } from './../../experience/Stories';
import { TextArea } from './TextArea';
import { Input } from './Input';
import defaultUser from './../../assets/impact21/images/default.jpg'
import { checkIfAeApps, getEmbedThemeInfo } from '../../theme/Loader';
// import logoImg from './../../assets/impact21/images/impact-logo.png'
import { getDept, getGroup, getHostel, getUrlParams } from '../../utils';
import { isIOS } from 'react-device-detect';

const LIMIT = 280;


let themeConfig = getEmbedThemeInfo();
const topic = themeConfig.ctag;

// aug 29 16:44:59 => 1661791499000
const aug29epoch = 1661791499000;
const d = new Date().valueOf();


let image = '';
let imageUrl = '';
let hashtags = [];
let hashtagSuggestions = ['oldyou', 'newyou', 'rect1997', 'REM2'];
let story = '';
// let user = {};
// let userInfo = {};
// let oktaToken = '';
let width = ''
let height = '';
let stickerInfo = [];
let bgInfo = [];

const resetData = () => {
  hashtags = [];
  story = '';
  width = '';
  height = '';
  image = '';
  imageUrl = '';
  stickerInfo = [];
  bgInfo = [];
}

const resetData2 = () => {
  width = '';
  height = '';
  image = '';
  imageUrl = '';
  stickerInfo = [];
  bgInfo = [];
}


const returnName = (props) => {
  // if (props.userInfo.nickname) {
  //   return props.userInfo.nickname
  // }
  if (props.ceventUser.preferredName) {
    return props.ceventUser.preferredName + ' ' + props.ceventUser.lastName;
  }
  if (props.ceventUser.firstName) {
    return props.ceventUser.firstName + ' ' + props.ceventUser.lastName;
  }
  if (props.userInfo.full_name) {
    return props.userInfo.full_name;
  }
  return '';
}

export function ImageCapture(props) {
  function getImage(imgBlob, videoConstraints) {
    image = imgBlob;
    imageUrl = imgBlob;
    width = videoConstraints.width;
    height = videoConstraints.height;
    // console.log('image blob', img)
    props.setExperience('PREVIEW_IMAGE');

  }
  return (
    <div className='fk-container image-capture'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='image-capture-container'>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        <div className='heading'>Capture a Photo</div>
        <div className='image-live-preview'>
          {/* <ImageRecorder
            onCapture={handleCapture}
          /> */}
          {<ImageRecorder type='Camera' cb={getImage}>
            <div className='capture-action'>
              <i className='icon-fk icon-fk-camera'></i>
              <div className='image camera'></div>
              <span className='text'>Capture</span>
            </div>
          </ImageRecorder>}
        </div>
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function ImagePreview(props) {
  const [hide, setHide] = useState(false);
  function setExperience(val) {
    props.setExperience(val);
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function getImage() {
    hideControls();
    setTimeout(() => {
      const canvas2 = document.getElementById('fabricCanvas-0');
      image = canvas2.toDataURL();
      return props.setExperience(EXPERIENCE.ADD_IMAGE_POST);
    }, 1000)

  }

  const getStickerInfo = ({ stickers, bg }) => {
    stickerInfo.push(stickers);
    bgInfo.push(bg);
  }

  const hideControls = () => {
    // console.log('hide set to true');
    setHide(true) && getStickerInfo();
  }
  return (
    <div className='fk-container image-preview'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='image-preview-container'>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        <div className='heading'>Add Stickers and Background</div>
        <div className='image-preview'>
          <img src={imageUrl} alt='preview' />
          <StickerSelect ceventUser={props.ceventUser} photo={imageUrl} width={width} height={height} hideControls={hide} index={0} display={true} getMediaAttrs={getStickerInfo} />
        </div>
        <div className='action'>
          <div className='redo' onClick={() => setExperience(EXPERIENCE.CAPTURE_IMAGE)}>
            <i className='icon-fk icon-fk-camera'></i>
            <div className='image camera'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={getImage}><span className='text'>Continue</span></div>
        </div>
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function AddImagePost(props) {
  const [limit, setLimit] = useState(LIMIT);
  const [hashtag, setHashtags] = useState(hashtags);
  const [firstLanding, setFirstLanding] = useState(true);
  function getText(value) {
    // console.log('limit', value.length);
    if (value.length > LIMIT) {
      return
    }
    story = value;
    // console.log('value', value, value.length)
    setLimit(LIMIT - value.length);
  }
  function resetHashtags(arr) {
    hashtags = arr;
    setHashtags(arr)
  }
  function removeHashtag(tag) {
    // console.log('state', hashtag)
    let newHashtags = hashtag.filter(e => e !== tag);

    resetHashtags(newHashtags);
  }
  function getHashtagText(value) {
    addHashtags(value);
  }
  function goToReview() {
    // console.log('lmi', limit)
    setFirstLanding(false);
    // if (limit < LIMIT) {
    //   props.setExperience(EXPERIENCE.IMAGE_POST_REVIEW)
    // }
    if (hashtag.length <= 0) {
    } else {
      props.setExperience(EXPERIENCE.IMAGE_POST_REVIEW)
    }
  }
  function addHashtags(tag) {
    let newHashtag = [...hashtag];
    // console.log(newHashtag.length, typeof newHashtag);
    if (newHashtag.length >= 4) {
      return;
    }
    newHashtag.push(tag)
    hashtags = newHashtag;
    setHashtags(newHashtag)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (story) {
      setLimit(LIMIT - story.length)
    }
  }, [])
  // console.log('story', story);
  // console.log('hashtags', hashtags)
  return (
    <div className='fk-container full-image'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='full-image-container'>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        <div className='heading'>Create Your Shared Story</div>
        <div className='add-post-container'>
          <div className='image-preview-and-retake'>
            <img src={image} alt='preview' width={videoConstraints.width} height={videoConstraints.height} />
            <div className='action-retake'>
              <div onClick={() => { resetData2(); props.setExperience(EXPERIENCE.CAPTURE_IMAGE) }} className='btn-redo'>
                <span className='text'>Retake</span>
              </div>
            </div>
          </div>
          <div className='add-story-container'>
            <div className='user-info'>
              <div className="user-image">
                <img src={defaultUser} alt="user" />
              </div>
              <div className='username'>
                <span className='firstname'>{returnName(props)}</span>
                <span className='lastname'></span>
              </div>
            </div>
            <div className='story-container'>
              <div className='heading'>Shared Story</div>
              <div className='sub-heading'>Add a caption with up to 280 characters</div>
              <div className={`text-area`}>
                <TextArea
                  placeholder="Optional"
                  value={story}
                  getText={getText}
                  limit={LIMIT}
                >
                </TextArea>
                <div className='text-area-meta'>
                  <span className='error-msg'>You must enter a story to continue</span>
                  <span className='character-count'>{limit}</span>
                </div>
              </div>
            </div>
            <div className={`hashtag-container ${(hashtag.length <= 0) && !firstLanding ? 'error' : ''}`}>
              <div className='heading'>Hashtags</div>
              <div className='sub-heading'>Select atleast one suggested hashtag (required)</div>
              <div className={`hashtags ${hashtags.length >= 4 ? 'hideinput' : ''}`}>
                {hashtags.map((hash, i) =>
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    {<i className='icon-fk icon-fk-cancel-circle' onClick={() => removeHashtag(hash)}></i>}
                  </div>)}
                <Input placeholder="Enter a #hashtag" getText={getHashtagText}></Input>
              </div>
              <div className='text-area-meta'>
                <span className='error-msg'>You must enter atleast one hashtag</span>
              </div>
              <div className='hashtags-suggestion'>
                {hashtagSuggestions.filter((tag, index) => {
                  if (props.ceventUser.registrationType === 'lasVegasFlow') {
                    if (aug29epoch - d <= -1) {
                      return tag
                    } else if (aug29epoch - d >= -1 && index <= 3) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    if (aug29epoch - d <= -1 && index <= 4) {
                      return true
                    } else if (aug29epoch - d >= -1 && index <= 3) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }).filter(e => !hashtags.includes(e)).map((hash) => (
                  <div key={hash} className='hashtag-wrapper' onClick={() => addHashtags(hash)}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    <i className='icon-fk icon-fk-plus'></i>
                    <div className='image icon-fk-add'></div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
        <div className='action-container full-image'>
          <div className='back' onClick={() => props.setExperience(EXPERIENCE.PREVIEW_IMAGE)}>
            <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={goToReview}>
            <span className='text'>Preview Story</span>
          </div>
        </div>
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function ImagePostReview(props) {
  const [publish, setPublishAction] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  function setExperience(val) {
    props.setExperience(val);
  }

  function publishPost() {
    setPublishAction(true)
    setApiLoading(true);
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    if (publish) {
      // console.log('make api call');
      // console.log('take use to download page');
      // const blob = dataURLtoBlob(image);
      const referrer = getUrlParams('referrer') || null;
      const aeApps = checkIfAeApps();
      const aeAppsToken = window.localStorage.getItem('playerToken') || null;
      const groupId = window.userConfig.groupId || null;
      const eventId = window.userConfig.eventId || null;
      const env = window.localStorage.getItem('fk-env') || null;
      const param = qs.stringify(
        {
          "x-access-token": props.token || aeAppsToken,
          aeApps, groupId, eventId, env,
          topic,
          referrer
        },
        { skipNulls: true }
      );
      const REACT_APP_ENV = process.env.REACT_APP_ENV || "local";
      const url = `https://${REACT_APP_ENV === 'prod' ? 'api' : 'dev.api'}.fanvoice.ai/cmsa/stories/${topic}/publish?${param}`;


      fetch(image).then(res => res.blob()).then(blob => {
        const formData = new FormData();
        formData.append("type", "image");
        formData.append("media", blob);
        formData.append('mimetype', blob.type);
        formData.append("story", story);
        formData.append("hashtags", JSON.stringify(_.uniq(hashtags)));
        formData.append("author", JSON.stringify({
          alias: props.userInfo.nickname,
          name: returnName(props),
          email: props.userInfo.email_address,
          group: getGroup(),
          dept: getDept(),
          hostel: getHostel(),
          location: getHostel(),
          photo: props.ceventUser.thumbnailUrl
        }));
        formData.append("width", width);
        formData.append("height", height);
        formData.append("mediaAttrs", JSON.stringify({ stickers: stickerInfo, backgrounds: bgInfo }));
        fetch(url, {
          // fetch(`http://localhost:8080/api/stories/${topic}/publish?x-access-token=${props.token}`, {
          // fetch(`https://dev.api.fankave.com/cmsx/stories/testimonialmvp/publish?x-access-token=${props.token}`, {
          method: 'POST',
          body: formData
        }).then(e => {
          if (e.status === 200) {
            console.log('published successfully', e);
          } else {
            console.error('publish failed', e);
          }
          setExperience(EXPERIENCE.DOWNLOAD_IMAGE)
        }).catch(err => {
          console.error("Error: ", err);
          setExperience(EXPERIENCE.DOWNLOAD_IMAGE)
        })
      });
    }
  })
  return (
    <div className='outer-container'>
      <div className='fk-container image-review'>
        <div className='top-left-logo'>
          <div className="logo">
            <img src={''} alt="" />
          </div>
        </div>

        <div className='heading-out'>Preview Story</div>
        <div className={`spinner ${apiLoading ? '' : 'hide'}`}></div>
        <div className='image-post-preview-container'>
          <div className='image-preview'>
            <img src={image} alt='preview' />
          </div>
          <div className='story'>
            {story}
          </div>
          <div className='hashtags'>
            {hashtags.map((hash, i) =>
              <div className='hashtag-wrapper' key={hash}>
                <div className='hashtag'>{`#${hash}`}</div>
              </div>)}
          </div>
          <div className='user-info'>
            <div className="user-image">
              <img src={defaultUser} alt="preview" />
            </div>

            <div className='username'>
              <span className='firstname'>{returnName(props)}</span>
              <span className='lastname'></span>
            </div>
          </div>
        </div>
        <div className='action'>
          <div className='back' onClick={() => setExperience(EXPERIENCE.ADD_IMAGE_POST)}>
            <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={publishPost}>
            <span className='text'>Publish Story</span>
          </div>
        </div>
      </div>
      <div className='footer'></div>
    </div>
  )
}

export function ImageDownload(props) {
  const aeApps = checkIfAeApps();
  const [referrer, setReferrer] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
    let referrer = getUrlParams('referrer') || null;
    setReferrer(referrer);
  }, []
  )
  function setExperience(val) {
    resetData();
    props.setExperience(val);
  }
  return (
    <div className='fk-container image-download'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='title'>
        Shared Story Posted
      </div>
      <div className='tagline'>
        Great news, your story is out there for all to see! Head over to the Shared Stories gallery to see how it looks and explore other stories.  Or, if you have more to say, create another one.</div>
      <div className='image-download-container'>
        <div className='image-download-preview'>
          <div className="download-video-hashtag">#rect1997</div>
          <div className="thank-you">Thank You.</div>
          <img src={image} alt='preview' width={videoConstraints.width} height={videoConstraints.height} />
          {referrer && isIOS ? null : <a href={image} download="image.png">
            <div className='download'>
              <i className='icon-fk icon-fk-download' />
              <div className='dwnld-img' download="image.png" href={image}>
                <div className="download-icon-bg">
                  <div className='image icdownload'>
                  </div>
                </div>
                <p>Download Image</p>
              </div>
            </div>
          </a>}
        </div>
      </div>
      <div className='action'>
        {!aeApps && <div className='close' onClick={() => { window.close() }}>{`Close & View Stories`}</div>}
        <div className='redo' onClick={() => setExperience('')}>Create a New Story</div>
      </div>
      <div className='footer'></div>
    </div>
  )
}
