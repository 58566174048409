import React from 'react'

import './blankScreens.css'

export const UnAuthorized = () => {
  return (
    <div className="fk-gallery-unauthorized">
      <div className="fk-unauthorized-content">
        <p className="fk-unauthorized-heading">
          {'Your Session has been Timed out'}
        </p>
        <p className="fk-unauthorized-text">
          {`Please Close your Browser and Login again`}
        </p>
      </div>
    </div>
  )
}
