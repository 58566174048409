import React, { useState, useEffect, useRef } from 'react';
import loadImage from 'image-promise';
import qs from 'qs';
import _ from 'lodash';

import { EXPERIENCE } from './../../experience/Stories';
import { videoConstraints, canvasConstraints } from './ImageRecorder'
import { StickerSelect } from './AddBgAndStickers';
import { TextArea } from './TextArea';
import { Input } from './Input';
import defaultUser from './../../assets/impact21/images/default.jpg'
import nextIcon from './../../assets/impact21/images/next.png';
import prevIcon from './../../assets/impact21/images/prev.png';
import { checkIfAeApps, getEmbedThemeInfo } from '../../theme/Loader';
// import logoImg from './../../assets/impact21/images/impact-logo.png'
import uploadIcon from './../../assets/impact21/images/upload-img-icon-white.svg'
import { getDept, getGroup, getHostel, getUrlParams } from '../../utils';
import { isIOS } from 'react-device-detect';

const LIMIT = 280;
let themeConfig = getEmbedThemeInfo();
const topic = themeConfig.ctag;

// aug 29 16:44:59 => 1661791499000
const aug29epoch = 1661791499000;
const d = new Date().valueOf();


let width;
let height;

let uploadUrls = [];
let canvasUrls = [];
let stickerInfo = [];
let bgInfo = [];
// let videoThumb = '';
let hashtags = [];

let hashtagSuggestions = ['oldyou', 'newyou', 'rect1997', 'REM2'];

const imageExtensions = ['image/png', 'image/jpg', 'image/jpeg'];
let story = '';
// let user = {};
// let userInfo = {};
// let oktaToken = '';

const returnName = (props) => {
  // if (props.userInfo.nickname) {
  //   return props.userInfo.nickname
  // }
  if (props.ceventUser.preferredName) {
    return props.ceventUser.preferredName + ' ' + props.ceventUser.lastName;
  }
  if (props.ceventUser.firstName) {
    return props.ceventUser.firstName + ' ' + props.ceventUser.lastName;
  }
  if (props.userInfo.full_name) {
    return props.userInfo.full_name;
  }
  return '';
}


const isAllowedFile = (files) => {
  try {
    const extensionArr = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i] instanceof File) {
        extensionArr.push(files[i].type)
      } else {
        const file = files.item(i);
        extensionArr.push(file.type)
      }
    }
    const types = extensionArr.map(ext => imageExtensions.includes(ext));
    return !types.includes(false);
  } catch (err) {
    console.error('err', err);
    return false
  }
}

const isAllowedSize = (files) => {
  try {
    const sizesArr = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i] instanceof File) {
        sizesArr.push(files[i].size)
      } else {
        const file = files.item(i);
        sizesArr.push(file.size)
      }
    }
    const types = sizesArr.map(size => (size / 1024 / 1024) > 5);
    return !types.includes(false);
  } catch (err) {
    return false
  }
}

const resetData = () => {
  hashtags = [];
  story = '';
  width = '';
  height = '';
  uploadUrls = [];
  canvasUrls = [];
  stickerInfo = [];
  bgInfo = [];
}

const setWidthandHeight = (w, h) => {
  // const dimensions = {width, height};
  width = w;
  height = h;
}

export function UploadImage(props) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [error, setError] = useState(false);
  const [errText, setErrText] = useState('');
  const canvasRef = useRef(null);

  // const getSize = (image, container) => {
  //   // console.log('getSize');
  //   console.log('image.width', image.width, image.height)
  //   console.log('container.width', container.width, container.height)
  //   const childRatio = image.width / image.height
  //   const parentRatio = container.width / container.height
  //   let width = container.width
  //   let height = container.height
  //   if (childRatio < parentRatio) {
  //     height = width / childRatio
  //   } else {
  //     width = height * childRatio
  //   }
  //   // console.log('width', 'height', width, height)
  //   return {
  //     width,
  //     height,
  //   }
  // }

  const gotoPreview = () => {
    props.setExperience(EXPERIENCE.UPLOAD_MULTIPLE)
  }
  const dropHandler = ev => {
    ev.preventDefault();
    // console.log('event dropHandler', ev);
    if (ev.dataTransfer.items) {
      if (ev.dataTransfer.items[0].kind === 'file') {
        let files = [];
        if (ev.dataTransfer.items.length > 5) {
          setSelectedFiles([]);
          setErrText('Maximum of 5 images allowed');
          setError(true)
          return;
        }
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          files.push(ev.dataTransfer.items[i].getAsFile())
        }
        // console.log('if... file.name = ' + file, file.name, file.size);
        if (!isAllowedFile(files)) {
          // console.log('extions error', file.name);
          setErrText('File extension not allowed',);
          setError(true)
          uploadUrls = [];
          canvasUrls = [];
          return;
        } else if (isAllowedSize(files)) {
          // console.log('size error', file.size, file.size/1024/1024);
          setErrText('File too large max 5Mb');
          setError(true)
          uploadUrls = [];
          canvasUrls = [];
          return;
        }
        // let urls = files.map(file => URL.createObjectURL(file));
        // console.log(url);
        // uploadUrl = url;
        // gotoPreview();
        let imagePromises =
          files.map(file => generateUrl(file));

        Promise.all(imagePromises)
          .then(e => {
            gotoPreview();
          })
          .catch(err => {
            console.error('err', err);
          })
      }
    } else {
      // console.log('else... file.name = ' + ev.dataTransfer.files[0]);
    }
  }

  const dragOverHandler = e => {
    e.preventDefault();
    // console.log('event dragOverHandler', e)
  }

  // function drawImageScaled(img, ctx) {
  //   var canvas = ctx.canvas;
  //   var hRatio = canvas.width / img.width;
  //   var vRatio = canvas.height / img.height;
  //   var ratio = Math.min(hRatio, vRatio);
  //   var centerShift_x = (canvas.width - img.width * ratio) / 2;
  //   var centerShift_y = (canvas.height - img.height * ratio) / 2;
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   ctx.drawImage(img, 0, 0, img.width, img.height,
  //     centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
  // }

  const generateUrl = (file) => {
    const objectUrl = URL.createObjectURL(file)
    // uploadUrl = objectUrl
    const image = new Image();
    image.crossOrigin = 'Anonymous'
    image.src = objectUrl;
    return loadImage(image)
      .then(image => {
        const ctx = canvasRef.current.getContext('2d');
        var hRatio = (videoConstraints.width || canvasConstraints.width) / image.width;
        var vRatio = (videoConstraints.height || canvasConstraints.height) / image.height;
        var ratio = Math.min(hRatio, vRatio);
        var centerShift_x = ((videoConstraints.width || canvasConstraints.width) - image.width * ratio) / 2;
        var centerShift_y = ((videoConstraints.height || canvasConstraints.height) - image.height * ratio) / 2;
        // const { width, height } = getSize(image, { width: videoConstraints.width || canvasConstraints.width, height: videoConstraints.height || canvasConstraints.height })
        // console.log('width', width, 'height', height);
        // console.log(canvasRef.current.getBoundingClientRect())
        setWidthandHeight(videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
        ctx.clearRect(0, 0, videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
        ctx.drawImage(image, 0, 0, image.width, image.height, centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);
        // console.log(videoConstraints.width)
        const src = canvasRef.current.toDataURL('image/jpeg', 1.0);
        // setTimeout(() => {
        // console.log('src', src);
        // cb(src, videoConstraints);
        uploadUrls.push(src);
      })
      .catch(err => console.error('Image failed to load :(', err))

  }
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    window.scrollTo(0, 0)
    if (selectedFiles.length === 0) {
      return
    }
    // console.log('slected file', selectedFiles, selectedFiles.name, selectedFiles.size);
    if (!isAllowedFile(selectedFiles)) {
      // console.log('extions error', selectedFiles.name);
      setErrText('File extension not allowed');
      setError(true)
      uploadUrls = [];
      canvasUrls = [];
      return;
    } else if (isAllowedSize(selectedFiles)) {
      // console.log('size error', selectedFiles.size, selectedFiles.size/1024/1024);
      setErrText('File too large max 5Mb', selectedFiles);
      setError(true)
      uploadUrls = [];
      canvasUrls = [];
      return;
    }
    let imagePromises = [];
    // console.log('imageSrc', imageSrc);
    for (let i = 0; i < selectedFiles.length; i++) {
      imagePromises.push(generateUrl(selectedFiles.item(i)))
    }
    Promise.all(imagePromises)
      .then(e => {
        gotoPreview();
      })
      .catch(err => {
        console.error('err', err);
      })
    // generateUrl(selectedFiles[0])
    // gotoPreview();

    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl)
    // }, [selectedFiles]
    // )
    // eslint-disable-next-line
  }, [selectedFiles])

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles([])
      return
    }
    if (e.target.files.length > 5) {
      setSelectedFiles([]);
      setErrText('Maximum of 5 images allowed');
      setError(true)
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFiles(e.target.files)
  }

  return (
    <div className='fk-container upload-image'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='upload-image-container'>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        {/* <div className="card-camera-icon">
          <img src={cameraIcon} alt="" />
        </div> */}
        <div className='heading'>Upload Image(s)</div>
        <canvas
          style={{ display: 'none' }}
          ref={canvasRef}
          width={videoConstraints.width || canvasConstraints.width}
          height={videoConstraints.height || canvasConstraints.height} />
        {/* <div className='image-live-preview'>
          {selectedFiles &&  <img src={preview} alt='preview'/> }
        </div> */}
        <div className='drag-n-drop' onDrop={dropHandler} onDragOver={dragOverHandler}>
          <div className='dnd-title'>
            Drag and Drop Files
          </div>
          <p>or</p>
          <div className='file-upload'>
            <label htmlFor="file-upload" className="custom-file-upload">
              <img src={uploadIcon} alt="" />
              <span className='text'>Upload Image(s)</span>
            </label>
            <div className='max-limit'>Add up to 5 Images</div>
            <input id="file-upload" type="file" accept="image/*" onChange={onSelectFile} multiple />
          </div>
        </div>
        {error && <div className='error'>{errText} </div>}
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function UploadImageMultiple(props) {
  const [selectedFiles, setSelectedFiles] = useState([])
  // eslint-disable-next-line
  const [something, setSomething] = useState('');
  const [error, setError] = useState(false);
  const [errText, setErrText] = useState('');
  const canvasRef = useRef(null);

  // const getSize = (image, container) => {
  //   // console.log('image.width', image.width, image.height)
  //   const childRatio = image.width / image.height
  //   const parentRatio = container.width / container.height
  //   let width = container.width
  //   let height = container.height
  //   if (childRatio < parentRatio) {
  //     height = width / childRatio
  //   } else {
  //     width = height * childRatio
  //   }
  //   // console.log('width', 'height', width, height)
  //   return {
  //     width,
  //     height,
  //   }
  // }

  const generateUrl = (file) => {
    const objectUrl = URL.createObjectURL(file)
    // uploadUrl = objectUrl
    const image = new Image();
    image.crossOrigin = 'Anonymous'
    image.src = objectUrl;
    return loadImage(image)
      .then(image => {
        const ctx = canvasRef.current.getContext('2d');
        var hRatio = (videoConstraints.width || canvasConstraints.width) / image.width;
        var vRatio = (videoConstraints.height || canvasConstraints.height) / image.height;
        var ratio = Math.min(hRatio, vRatio);
        var centerShift_x = ((videoConstraints.width || canvasConstraints.width) - image.width * ratio) / 2;
        var centerShift_y = ((videoConstraints.height || canvasConstraints.height) - image.height * ratio) / 2;
        // const { width, height } = getSize(image, { width: videoConstraints.width || canvasConstraints.width, height: videoConstraints.height || canvasConstraints.height })
        // console.log('width', width, 'height', height);
        // console.log(canvasRef.current.getBoundingClientRect())
        setWidthandHeight(videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
        ctx.clearRect(0, 0, videoConstraints.width || canvasConstraints.width, videoConstraints.height || canvasConstraints.height);
        ctx.drawImage(image, 0, 0, image.width, image.height, centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);
        // console.log(videoConstraints.width)
        const src = canvasRef.current.toDataURL('image/jpeg', 1.0);
        // setTimeout(() => {
        // console.log('src', src);
        // cb(src, videoConstraints);
        uploadUrls.push(src);
      })
      .catch(err => console.error('Image failed to load :(', err))

  }

  const onSelectFile = e => {
    setErrText(false);
    setError(false)
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles([])
      return
    }
    if (uploadUrls.length + e.target.files.length > 5) {
      setSelectedFiles([]);
      setErrText('Maximum of 5 images allowed');
      setError(true)
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFiles(e.target.files)
  }

  const deleteFile = (index) => {
    if (index > -1) { // only splice array when item is found
      uploadUrls.splice(index, 1); // 2nd parameter means remove one item only
    }
    setSomething(Math.floor(Math.random() * 1000));
  }

  useEffect(() => {
    if (uploadUrls.length === 0) {
      resetData();
      props.setExperience(EXPERIENCE.UPLOAD_IMAGE);
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    if (selectedFiles.length === 0) {
      return
    }
    // console.log('slected file', selectedFiles, selectedFiles.name, selectedFiles.size);
    if (!isAllowedFile(selectedFiles)) {
      // console.log('extions error', selectedFiles.name);
      setErrText('File extension not allowed');
      setError(true)
      return;
    } else if (isAllowedSize(selectedFiles)) {
      // console.log('size error', selectedFiles.size, selectedFiles.size/1024/1024);
      setErrText('File too large max 5Mb', selectedFiles);
      setError(true)
      uploadUrls = [];
      canvasUrls = [];
      return;
    }
    let imagePromises = [];
    // console.log('imageSrc', imageSrc);
    for (let i = 0; i < selectedFiles.length; i++) {
      imagePromises.push(generateUrl(selectedFiles.item(i)))
    }
    Promise.all(imagePromises)
      .then(e => {
        setSomething(Math.floor(Math.random() * 1000));
        // gotoPreview();
      })
      .catch(err => {
        console.error('err', err);
      })
    // generateUrl(selectedFiles[0])
    // gotoPreview();

    // free memory when ever this component is unmounted
    // return () => URL.revokeObjectURL(objectUrl)
    // }, [selectedFiles]
    // )
    // eslint-disable-next-line
  }, [selectedFiles])

  return (
    <div className='fk-container upload-image'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='upload-multiple-preview-image-container'>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        {/* <div className="card-camera-icon">
          <img src={cameraIcon} alt="" />
        </div> */}
        <div className='heading'>Upload Image(s)</div>
        {/* <div className='image-live-preview'>
          {selectedFiles &&  <img src={preview} alt='preview'/> }
        </div> */}
        <div className='upload-multiple-preview'>
          <canvas
            style={{ display: 'none' }}
            ref={canvasRef}
            width={videoConstraints.width || canvasConstraints.width}
            height={videoConstraints.height || canvasConstraints.height} />
          <div className="items">
            {uploadUrls.map((url, i) => (
              <div className='item' key={url + i}>
                <div className='item-image'><img src={url} alt={`img-${i}`} /></div>
                <div className='item-detail'>
                  <div className='item-detail-top'>
                    <div className='item-name'>{`image-${i + 1}`}</div>
                    <div className='item-delete' onClick={() => deleteFile(i)}><i className='icon-fk icon-fk-cancel-circle'></i></div>
                  </div>
                  <div className='item-detail-bottom'></div>
                </div>
              </div>))}
          </div>
          <div className='action'>
            <div className='file-upload'>
              <label htmlFor="file-upload" className={`custom-file-upload ${uploadUrls.length >= 5 ? 'disabled' : ''}`}>
                <img src={uploadIcon} alt="" />
                <span className='text'>Upload Images</span>
              </label>
              <input id="file-upload" disabled={uploadUrls.length >= 5 ? true : false} type="file" accept="image/*" onChange={onSelectFile} multiple />
            </div>
            <div className='continue' onClick={() => props.setExperience(EXPERIENCE.PREVIEW_UPLOAD)}>
              Continue
            </div>
          </div>
        </div>
        {/* <div className='drag-n-drop'>
          
        </div> */}
        {error && <div className='error'>{errText} </div>}
        <div className={`info ${error ? 'hide' : ''} `}>Maximum of 5 images allowed</div>
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function UploadPreview(props) {
  // console.log('upload image url', uploadUrl);
  const [hide, setHide] = useState(false);
  const [index, setIndex] = useState(0);
  const prev = () => {
    const length = uploadUrls.length;
    if (index === 0) {
      setIndex(length - 1)
    } else {
      setIndex(index - 1);
    }
  }
  const next = () => {
    const length = uploadUrls.length;
    if (index === length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1);
    }
  }
  function setExperience(val) {
    props.setExperience(val);
  }

  function getImage() {
    hideControls();
    setTimeout(() => {
      for (let i = 0; i < uploadUrls.length; i++) {
        const canvas2 = document.getElementById(`fabricCanvas-${i}`);
        canvasUrls.push(canvas2.toDataURL());
      }
      return props.setExperience(EXPERIENCE.ADD_UPLOAD_POST);
    }, 1000)
  }

  const getStickerInfo = ({ stickers, bg }) => {
    stickerInfo.push(stickers);
    bgInfo.push(bg);
  }

  const hideControls = () => {
    // console.log('hide set to true');
    setHide(true) && getStickerInfo();
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='fk-container upload-preview'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='upload-preview-container'>
        <div className='heading'>Add Stickers and Background</div>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        <div className='upload-preview'>
          <div className='abc'>
            <img src={uploadUrls[index]} alt='preview' />
            {uploadUrls.map((url, i) => (
              <div key={url + i}>
                <StickerSelect ceventUser={props.ceventUser} photo={uploadUrls[i]} hideControls={hide} display={i === index} index={i} getMediaAttrs={getStickerInfo} uploadUrls={uploadUrls} prev={prev} next={next} />
              </div>
            ))}
          </div>
        </div>
        <div className='action'>
          <div className='redo' onClick={() => {
            // resetData();
            setExperience(EXPERIENCE.UPLOAD_MULTIPLE)
          }}>
            <i className='icon-fk icon-fk-camera'></i>
            <div className='image camera'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={getImage}>
            Continue
          </div>
        </div>
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function AddUploadPost(props) {
  const [limit, setLimit] = useState(LIMIT);
  const [hashtag, setHashtags] = useState(hashtags);
  const [firstLanding, setFirstLanding] = useState(true);
  const [index, setIndex] = useState(0);
  const prev = () => {
    const length = canvasUrls.length;
    if (index === 0) {
      setIndex(length - 1)
    } else {
      setIndex(index - 1);
    }
  }
  const next = () => {
    const length = canvasUrls.length;
    if (index === length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1);
    }
  }
  function getText(value) {
    // console.log('limit', value.length);
    if (value.length > LIMIT) {
      return
    }
    story = value;
    // console.log('value', value, value.length)
    setLimit(LIMIT - value.length);
  }
  function resetHashtags(arr) {
    hashtags = arr;
    setHashtags(arr)
  }
  function removeHashtag(tag) {
    // console.log('state', hashtag)
    let newHashtags = hashtag.filter(e => e !== tag);

    resetHashtags(newHashtags);
  }
  function getHashtagText(value) {
    addHashtags(value);
  }
  function goToReview() {
    // console.log('lmi', limit)
    setFirstLanding(false);
    // if (limit < LIMIT) {
    //   props.setExperience(EXPERIENCE.UPLOAD_POST_REVIEW)
    // }
    if (hashtag.length <= 0) {
    } else {
      props.setExperience(EXPERIENCE.UPLOAD_POST_REVIEW)
    }
  }
  function addHashtags(tag) {
    let newHashtag = [...hashtag];
    // console.log(newHashtag.length, typeof newHashtag);
    if (newHashtag.length >= 4) {
      return;
    }
    newHashtag.push(tag)
    hashtags = newHashtag;
    setHashtags(newHashtag)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (story) {
      setLimit(LIMIT - story.length)
    }
  }, [])
  // console.log('story', story);
  // console.log('hashtags', hashtags)
  return (
    <div className='fk-container full-upload'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='full-upload-container'>
        <div className='heading'>Create Your Shared Story</div>
        <div className="cancel-btn-mobile" onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>
          <i className='icon-fk icon-fk-cancel-circle'></i>
          <p>Cancel</p>
        </div>
        <div className='add-post-container'>
          <div className='upload-preview-and-retake'>
            <div className={`prev ${canvasUrls.length > 1 ? 'show-arrow' : ''}`} onClick={prev}>
              <img className='prev-icon' src={prevIcon} alt='prev' />
            </div>
            <div className='abc'>
              <img src={canvasUrls[index]} alt='preview' />
            </div>
            <div className={`next ${canvasUrls.length > 1 ? 'show-arrow' : ''}`} onClick={next}>
              <img className='next-icon' src={nextIcon} alt='next' />
            </div>

            <div className='action-retake'>
              <div onClick={() => {
                canvasUrls = [];
                props.setExperience(EXPERIENCE.UPLOAD_MULTIPLE)
              }} className='btn-redo'>Replace Image</div>
            </div>
          </div>
          <div className='add-story-container'>
            <div className='user-info'>
              <div className="user-img">
                <img
                  src={props.ceventUser.thumbnailUrl || defaultUser}
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultUser;
                  }}
                />
              </div>
              <div className='username'>
                <span className='firstname'>{returnName(props)}</span>
                <span className='lastname'></span>
              </div>
            </div>
            <div className='story-container'>
              <div className='heading'>Shared Story</div>
              <div className='sub-heading'>Add a caption with up to 280 characters</div>
              <div className={`text-area`}>
                <TextArea
                  placeholder="Optional"
                  value={story}
                  getText={getText}
                  limit={LIMIT}>
                </TextArea>
                <div className='text-area-meta'>
                  <span className='error-msg'>You must enter a story to continue</span>
                  <span className='character-count'>{limit}</span>
                </div>
              </div>
            </div>
            <div className={`hashtag-container ${(hashtag.length <= 0) && !firstLanding ? 'error' : ''}`}>
              <div className='heading'>Hashtags</div>
              <div className='sub-heading'>Select atleast one suggested hashtag (required)</div>
              <div className={`hashtags ${hashtags.length >= 4 ? 'hideinput' : ''}`}>
                {hashtags.map((hash, i) =>
                  <div className='hashtag-wrapper' key={hash}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    {<i className='icon-fk icon-fk-cancel-circle' onClick={() => removeHashtag(hash)}></i>}
                  </div>)}
                <Input placeholder="Enter a #hashtag" getText={getHashtagText}></Input>
              </div>
              <div className='text-area-meta'>
                <span className='error-msg'>You must enter atleast one hashtag</span>
              </div>
              <div className='hashtags-suggestion'>
                {hashtagSuggestions.filter((tag, index) => {
                  if (props.ceventUser.registrationType === 'lasVegasFlow') {
                    if (aug29epoch - d <= -1) {
                      return tag
                    } else if (aug29epoch - d >= -1 && index <= 3) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    if (aug29epoch - d <= -1 && index <= 4) {
                      return true
                    } else if (aug29epoch - d >= -1 && index <= 3) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }).filter(e => !hashtags.includes(e)).map((hash) => (
                  <div key={hash} className='hashtag-wrapper' onClick={() => addHashtags(hash)}>
                    <div className='hashtag'>{`#${hash}`}</div>
                    <i className='icon-fk icon-fk-plus'></i>
                    <div className='image icon-fk-add'></div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
        <div className='action-container'>
          <div className='back' onClick={() => {
            canvasUrls = [];
            props.setExperience(EXPERIENCE.PREVIEW_UPLOAD)
          }}>
            <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={goToReview}>
            <span className='text'>Preview Story</span>
          </div>
        </div>
      </div>
      <div className='btn cancel-btn-desktop' onClick={() => { resetData(); props.setExperience(EXPERIENCE.CANCEL) }}>Cancel</div>
      <div className='footer'></div>
    </div>
  )
}

export function UploadPostReview(props) {
  const [publish, setPublishAction] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const prev = () => {
    const length = canvasUrls.length;
    if (index === 0) {
      setIndex(length - 1)
    } else {
      setIndex(index - 1);
    }
  }
  const next = () => {
    const length = canvasUrls.length;
    if (index === length - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1);
    }
  }
  function setExperience(val) {
    props.setExperience(val);
  }

  function publishPost() {
    setPublishAction(true)
    setApiLoading(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (publish) {
      // console.log('make api call');
      // console.log('take use to download page');
      // const blob = dataURLtoBlob(image);
      const referrer = getUrlParams('referrer') || null;
      const aeApps = checkIfAeApps();
      const aeAppsToken = window.localStorage.getItem('playerToken') || null;
      const groupId = window.userConfig.groupId || null;
      const eventId = window.userConfig.eventId || null;
      const env = window.localStorage.getItem('fk-env') || null;

      const param = qs.stringify(
        {
          "x-access-token": props.token || aeAppsToken,
          aeApps, groupId, eventId, env,
          topic,
          referrer
        },
        { skipNulls: true }
      );
      const REACT_APP_ENV = process.env.REACT_APP_ENV || "local";
      const url = `https://${REACT_APP_ENV === 'prod' ? 'api' : 'dev.api'}.fanvoice.ai/cmsa/stories/${topic}/publish/multi?${param}`;

      Promise.all(canvasUrls.map(url => {
        return fetch(url).then(res => res.blob())
      }))
        .then(blobsArr => {
          const formData = new FormData();
          for (let i = 0; i < blobsArr.length; i++) {
            formData.append('media', blobsArr[i]);
            formData.append('mimetype', blobsArr[i].type);
          }
          formData.append("type", "upload");
          formData.append("story", story);
          formData.append("hashtags", JSON.stringify(_.uniq(hashtags)));
          formData.append("author", JSON.stringify({
            alias: props.userInfo.nickname,
            name: returnName(props),
            email: props.userInfo.email_address,
            group: getGroup(),
            hostel: getHostel(),
            dept: getDept(),
            location: getHostel(),
            photo: props.ceventUser.thumbnailUrl
          }));
          formData.append("width", width);
          formData.append("height", height);
          formData.append("mediaAttrs", JSON.stringify({ stickers: stickerInfo, backgrounds: bgInfo }));
          fetch(url, {
            // fetch(`http://localhost:8080/api/stories/${topic}/publish/multi?x-access-token=${props.token}`, {
            // fetch(`https://dev.api.fankave.com/cmsx/stories/testimonialmvp/publish/multi?x-access-token=${props.token}`, {
            method: 'POST',
            body: formData
          })
            .then(e => {
              if (e.status === 200) {
                console.log('published successfully', e);
              } else {
                console.error('publish failed', e);
              }
              setExperience(EXPERIENCE.DOWNLOAD_UPLOAD)
            })
            .catch(err => {
              console.error("Error: ", err);
              setExperience(EXPERIENCE.DOWNLOAD_UPLOAD)
            })
        })
        .catch(err => {
          console.error('Error uploading files', err);
          setExperience(EXPERIENCE.DOWNLOAD_UPLOAD)
        })
    }
  })
  return (
    <div>
      <div className='fk-container upload-review'>
        <div className='top-left-logo'>
          <div className="logo">
            <img src={''} alt="" />
          </div>
        </div>
        <div className='heading-out'>Preview Story</div>
        <div className={`spinner ${apiLoading ? '' : 'hide'}`}></div>
        <div className='upload-post-review-container'>
          <div className='upload-preview'>
            <div className={`prev ${canvasUrls.length > 1 ? 'show-arrow' : ''}`} onClick={prev}>
              <img className='prev-icon' src={prevIcon} alt='prev' />
            </div>
            <div className='abc'>
              <img src={canvasUrls[index]} alt='preview' />
            </div>
            <div className={`next ${canvasUrls.length > 1 ? 'show-arrow' : ''}`} onClick={next}>
              <img className='next-icon' src={nextIcon} alt='next' />
            </div>
          </div>
          <div className='story'>
            {story}
          </div>
          <div className='hashtags'>
            {hashtags.map((hash, i) =>
              <div className='hashtag-wrapper' key={hash}>
                <div className='hashtag'>{`#${hash}`}</div>
              </div>)}
          </div>
          <div className='user-info'>
            <div className="user-image">
              <img
                src={props.ceventUser.thumbnailUrl || defaultUser}
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultUser;
                }}
              />
            </div>
            <div className='username'>
              <span className='firstname'>{returnName(props)}</span>
              <span className='lastname'></span>
            </div>
          </div>
        </div>
        <div className='action'>
          <div className='back' onClick={() => setExperience(EXPERIENCE.ADD_UPLOAD_POST)}>
            <i className='icon-fk icon-fk-left-arrow'></i>
            <div className='image back-icon-fk'></div>
            <span className='text'>Back</span>
          </div>
          <div className='continue' onClick={publishPost}>
            <span className='text'>Publish Story</span>
          </div>
        </div>
        <div className='footer'></div>
      </div>
    </div>
  )
}

export function UploadDownload(props) {
  const aeApps = checkIfAeApps();
  const [referrer, setReferrer] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0)
    let referrer = getUrlParams('referrer') || null;
    setReferrer(referrer);
  }, []
  )
  function setExperience(val) {
    resetData();
    props.setExperience(val);
  }
  const downloadAll = () => {
    canvasUrls.map((url, i) => {
      var a = document.createElement('a');
      a.href = url;
      a.download = `image-${i}.png`;
      a.click();
      return true;
    })
  }
  return (
    <div className='fk-container upload-download'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='title'>
        Shared Story Posted
      </div>
      <div className='tagline'>
        Great news, your story is out there for all to see! Head over to the Shared Stories gallery to see how it looks and explore other stories.  Or, if you have more to say, create another one.
      </div>
      <div className='upload-download-container'>
        <div className='upload-download-preview'>
          <div className="download-video-hashtag">#rect1997</div>
          <div className="thank-you">Thank You.</div>
          <div className={`abc ${canvasUrls.length > 1 ? "moreThan1" : ""}`}>
            {canvasUrls.map((url) => <img src={url} alt='preview' />)}
          </div>
          {referrer && isIOS ? null : <div download="photo.png" onClick={downloadAll}>
            <div className='download'>
              <i className='icon-fk icon-fk-download' />
              <div className='dwnld-img' download="photo.png">
                <div className="download-icon-bg">
                  <div className='image icdownload'>
                  </div>
                </div>
                <p>{canvasUrls.length > 1 ? "Download Images" : "Download Image"}</p>
              </div>
            </div>
          </div>
          }
        </div>
        <div className='action'>
          {!aeApps && <div className='close' onClick={() => { window.close() }}>{`Close & View Stories`}</div>}
          <div className='redo' onClick={() => setExperience('')}>Create a New Story</div>
        </div>
      </div>
      <div className='footer'></div>
    </div>
  )
}
