import React, { useEffect, useRef, useState } from 'react'
import BadgeComponent from '../component/Badge';
import './badge.css'
import template from './../assets/ciscoliveus2023/badge-id-2.png';
import { getEmbedExperienceText } from '../theme/Loader';

const BadgeGenerate = (props) => {
  const badgeRef = useRef(null);
  const userRef = useRef(null);
  // console.log('props', props)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [handle, setHandle] = useState('');
  const [location, setLocation] = useState('');
  const [error, setError] = useState(false)
  const [renderBadge, setRenderBadge] = useState(false);

  const onSubmit = () => {
    if (!firstName || !lastName || !location) {
      setError(true)
    } else {
      setError(false)
      setRenderBadge(true)
    }
  }

  const getText = (key) => {
    return getEmbedExperienceText('badge', key);
  }

  const back = () => {
    if (!props.prevExperience) {
      return;
    }
    props.setExperience(props.prevExperience)
    props.setCategory('')
    // triggerSAEvent(`back to lounge from highlights`)
  };

  const onDownload = () => {
    try {
      props.amplitude && props.amplitude.dispatchAnalyticsEvent('DOWNLOADED BADGE')
    } catch (err) {
      console.log('error making api call', err)
    }
  }

  const onRender = () => {
    try {
      props.amplitude && props.amplitude.dispatchAnalyticsEvent('CREATED BADGE')

    } catch (err) {
      console.log('error making api call', err)
    }
  }

  const renderBadgeComponent = () => {
    const userObj = { firstName, lastName, location, handle: handle.replace(/\s/g, '') ? `@${handle}` : '' }
    onRender()
    return <BadgeComponent pic={template} userObj={userObj} display={true} index={0} onDownload={onDownload} />
  }

  useEffect(() => {
    userRef?.current && userRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    badgeRef?.current && badgeRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [renderBadge])

  // useEffect(() => {
  //   setIndex(index + 1)
  // }, [firstName, lastName, handle, location])


  return (
    <div className='badge-container'>
      <div className="header">
        <div className="back" onClick={renderBadge ? () => setRenderBadge(false) : back}>
          <i className={`icon-fk ${getText('back-icon-fk')}`} />
          <div className='back-label'>{getText('back-text')}</div>
        </div>
        <div className="badge title">{renderBadge ? getText('download-description') : getText('title')}</div>
      </div>
      {!renderBadge && <div className='userInfo' ref={userRef}>
        <div className='title'>{getText('description')}</div>
        <div className='firstName'>
          {/* <label name='firstName'>First Name: </label> */}
          <input type='text' name='firstName' placeholder='First Name* (max 15 char limit)' value={firstName} onChange={(event) => setFirstName(event.target.value.slice(0, 15))}></input>
        </div>
        <div className='lastName'>
          {/* <label name='firstName'>Last Name: </label> */}
          <input type='text' name='lastName' placeholder='Last Name* (max 15 char limit)' value={lastName} onChange={(event) => setLastName(event.target.value.slice(0, 15))}></input>
        </div>
        <div className='handle'>
          <input type='text' name='handle' placeholder='Social Media Handle (max 15 char limit)' value={handle} onChange={(event) => setHandle(event.target.value.slice(0, 15))}></input>
        </div>
        <div className='location'>
          <input type='text' name='location' placeholder='Location* (max 15 char limit)' value={location} onChange={(event) => setLocation(event.target.value.slice(0, 15))}></input>
        </div>
        {error && <div className={`warning ${error ? 'red' : ''}`}>
          *All fields are required
        </div>}

        <div onClick={onSubmit} className='submit'>Create</div>
      </div>}
      {
        <div className='badge-actions' ref={badgeRef}>
          {renderBadge && renderBadgeComponent()}
          {renderBadge && <div className='submit' onClick={back}>Back to Hub</div>}
        </div>
      }
    </div>
  )
}

export default BadgeGenerate;