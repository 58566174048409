import React from 'react'
import { MouseScroll, KeyScroll } from '../Icons'

import './overlay.css'

export const Overlay = ({ isMobile = false, onAction = () => { } }) => {
  return (
    <div className="fk-gallery-overlay">
      <div className="fk-content">
        <p className="fk-heading">See the Story Gallery</p>
        <p className="fk-text">
          {`${isMobile
              ? 'Navigate the Gallery.'
              : 'Use your mouse, touchpad or keyboard arrows to navigate the gallery.'
            }  Find an intriguing tale and enjoy!`}
        </p>
        <div className={`fk-icons ${isMobile ? '' : 'fk-grid'}`}>
          <div className="fk-mouse-scroll-icon">
            <MouseScroll />
          </div>
          {!isMobile && (
            <>
              <p>OR</p>
              <div className="fk-key-scroll-icon">
                <KeyScroll />
              </div>
            </>
          )}
        </div>
        <div className="fk-action" onClick={() => onAction()}>
          <div className="fk-button">Let's get Started!</div>
        </div>
      </div>
    </div>
  )
}
