import React, { useState, useRef, useCallback, useEffect } from "react";
import { isIOS, isMobile } from "react-device-detect";
import Webcam from "react-webcam";
// import { isMobile } from "react-device-detect";
// import { TestimonialContext } from "../../context/TestimonialContext";
// import NotificationCard from "../NotificationCard/NotificationCard";
// import { StopIcon, RecordingIcon } from "../../assets";
import { useInterval } from './../../hooks/useInterval';
import { useInterval as captureInterval } from './../../hooks/useInterval';
import { convertSecondsToHourMinute } from "./../../utils";
import { videoConstraints, canvasConstraints } from './ImageRecorder';
// import { SET_URL, SET_URL_DURATION, SET_THUMB_URL } from "../../constants";
// import "./videorecorder.css";

export const VideoRecorder = (props) => {
  // const { dispatch } = useContext(TestimonialContext);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const [isStreamInit, setIsStreamInit] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoURL, setVideoURl] = useState("");
  const [error, setError] = useState("");
  const [captureOverlay, setCaptureOverlay] = useState(0);
  const [startOverlay, setStartOverlay] = useState(false);
  // const [showNotification, setShowNotification] = useState(false);
  const [recordingTime, setTime] = useState(0);
  const recordingTimeRef = useRef();
  recordingTimeRef.current = recordingTime;
  const [thumbUri, setThumbUri] = useState('');

  const decrementTime = (currentTime) => {
    return (20 - parseInt(currentTime));
  }

  useInterval(() => {
    // console.log('recording time', recordingTime);
    if (capturing && recordingTime >= 20) {
      handleStopCaptureClick();
    }
    capturing && setTime(recordingTime + 1);
  }, 1000);

  captureInterval(() => {
    // console.log('startOverlay', startOverlay, 'captureOverlay', captureOverlay)
    if (startOverlay && captureOverlay < 4) {
      // console.log('update captrueoverlay', startOverlay)
      setCaptureOverlay(captureOverlay + 1)
    } else if (captureOverlay >= 3 && !capturing) {
      // console.log('in selse');
      setStartOverlay(false);
      handleStartCaptureClick();
    }
  }, 1000)

  const startCountDown = () => {
    setStartOverlay(true);
  }


  // const videoWidth =
  //   window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  //   const videoConstraints = {
  //     width: isMobile
  //       ? undefined
  //       : videoWidth > 375
  //       ? 320
  //       : videoWidth < 375
  //       ? 286
  //       : 286,
  //     height: isMobile 
  //       ? undefined 
  //       : videoWidth > 375 
  //       ? 480 
  //       : videoWidth < 375 
  //       ? 380 
  //       : 380,
  //     facingMode: "user",
  //   }

  //   const canvasConstraints = {
  //     width: videoWidth > 375
  //       ? 320
  //       : videoWidth < 375
  //       ? 286
  //       : 286,
  //     height: videoWidth > 375 
  //       ? 480 
  //       : videoWidth < 375 
  //       ? 380 
  //       : 380,
  //     facingMode: "user",
  //   }

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    // console.log('handlestartcapturing');
    // setStartOverlay(true);
    // setTimeout(() => setCapturing(true), 3800);
    setCapturing(true);
    let options = { mimeType: "video/webm" };
    if (typeof MediaRecorder.isTypeSupported == "function") {
      if (MediaRecorder.isTypeSupported("video/webm")) {
        options = { mimeType: "video/webm" };
      } else if (MediaRecorder.isTypeSupported("video/mp4")) {
        //Safari 14.0.2 has an EXPERIMENTAL version of MediaRecorder enabled by default
        options = { mimeType: "video/mp4" };
      }
    }
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    // console.log('screenshot', webcamRef?.current?.getScreenshot())
    setThumbUri(webcamRef?.current?.getScreenshot())
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const showAccessBlocked = useCallback((err) => {
    if (typeof err === "object") {
      setError("Please Allow Camera & Mic Permission and Refresh page to continue")
      // props.setError('Please Allow Camera Permission to Continue')
    } else {
      setError("Please Allow Camera & Mic Permission and Refresh page to continue");
    }
  }, []);

  useEffect(() => {
    //webm type video file created
    if (recordedChunks.length) {
      let options = { type: "video/webm" };
      if (typeof MediaRecorder.isTypeSupported == "function") {
        if (MediaRecorder.isTypeSupported("video/webm")) {
          options = { type: "video/webm" };
        } else if (MediaRecorder.isTypeSupported("video/mp4")) {
          //Safari 14.0.2 has an EXPERIMENTAL version of MediaRecorder enabled by default
          options = { type: "video/mp4" };
        }
      }

      const blob = new Blob(recordedChunks, options);

      let url = window.URL.createObjectURL(blob);
      try {
        url = window.webkitURL.createObjectURL(blob);
      } catch {
        url = window.URL.createObjectURL(blob);
      }
      setVideoURl(url);
      // console.log('video url', url);
      props.cb({
        url: url,
        thumb: thumbUri,
        duration: recordingTime,
        width: videoConstraints.width || canvasConstraints.width,
        height: videoConstraints.height || canvasConstraints.height
      })
    }
    // eslint-disable-next-line
  }, [recordedChunks]);

  const dispatchURLDuration = useCallback(() => {
    // recordingTimeRef && recordingTimeRef.current
    //   console.log('recoding timer fre', recordingTimeRef.current);
    // eslint-disable-next-line
  }, [recordingTimeRef]);

  useEffect(() => {
    // console.log(isMobile);
    return () => {
      dispatchURLDuration();
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   console.log('isMobile', isMobile, 'isIos', isIOS);
  //   console.log('isMobile && isIOS ? 4 / 3 : 3 / 4', isMobile && isIOS ? 4 / 3 : 3 / 4);
  // })

  if (!(window.MediaRecorder || window.webkitMediaRecorder)) {
    return (
      <div className='error'>
        Unfortunately, this browser does not support the web technology
        that powers this app. We recommend desktop Chrome or Firefox.
      </div>
    )
  }

  return (
    <article className="video-recorder-wrapper">
      <figure className="video-wrapper">
        <div className="video-recording-container"
          style={{ width: videoConstraints.width, height: videoConstraints.height }}>
          {error ? (
            <div className='camera-error'>{error}</div>
          ) : (
            !videoURL && (
              <Webcam
                playsInline={true}
                audio={true}
                muted={true}
                ref={webcamRef}
                // videoConstraints={videoConstraints.height ? videoConstraints : canvasConstraints}
                // width={videoConstraints.width || canvasConstraints.width}
                // height={videoConstraints.height || canvasConstraints.height}
                videoConstraints={{
                  facingMode: 'user',
                  aspectRatio: { exact: isMobile && isIOS && videoConstraints.videoWidth < 450 ? 4 / 3 : 3 / 4 },
                  // aspectRatio: { exact: 3 / 4 },
                }}
                style={{ objectFit: "cover" }}
                onUserMedia={() => setIsStreamInit(true)}
                onUserMediaError={showAccessBlocked}
              />
            )
          )}

        </div>
      </figure>
      {captureOverlay > 0 && captureOverlay < 4 ? <div className='capture-overlay'>
        {captureOverlay === 1 ? 3 : captureOverlay === 2 ? 2 : captureOverlay === 3 ? 1 : ''}
      </div> : null}
      {capturing && (
        <div className="timer-button-container">
          <div className="timer-overlay">
            {" "}
            {convertSecondsToHourMinute(String(decrementTime(recordingTime)))}
          </div>
          <div className="stop-button-container">
            <button onClick={handleStopCaptureClick} className="stop-button">
              <div className='capture-action stop'>
                <i className='icon-fk icon-fk-stop'></i>
                <div className='image'>
                  <div className='stop-icon-fk'></div>
                </div>
                <p>Stop Recording</p>
              </div>
            </button>
          </div>
        </div>
      )}
      {isStreamInit && !capturing && (
        <div className="button-container">
          <button onClick={startCountDown} className="record-button">
            <div className='capture-action record'>
              <i className='icon-fk icon-fk-video'></i>
              <div className='image'>
                <div className='video-icon-fk'></div>
              </div>
              <p>Start Recording</p>
              <p className='info'>Record a 20 second video</p>
            </div>
          </button>
        </div>
      )}
    </article>
  );
};
