import React, { Component } from "react";

import { getGallery } from "../Services.js";
import GalleryCard from "../component/GalleryCard.js";
import { getEmbedThemeName, getEmbedThemeInfo, getEmbedExperienceText } from "../theme/Loader";
import { handleError } from '../utils.js';
import CircularArray from '../model/Circular';
import { v4 as uuidv4 } from 'uuid';

import "./gallery.css";

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      posts: [],
      status: true,
      fadeIn: false,
      fadeOut: false,
      postsToRender: [],
    };
  }

  getCurrentTheme = () => {
    return ({name: getEmbedThemeName(), info: getEmbedThemeInfo()})
  };

  apiApprovedPhotos = (animate) => {
    let {
      gallery: { apiLimit, contentType, filter, format },
    } = getEmbedThemeInfo();
    let params = {
      limit: apiLimit,
      contentType,
      filter,
      format,
    };
    getGallery(
      (posts) => {
        let circularPosts = new CircularArray(posts);
        // fade in
        this.setState({ posts: circularPosts }, () => {
          if (animate) {
            this.toggleAnimationClass();
            return;
          }
        });
      },
      handleError,
      params
    );
  };

  fetchApi = (animate) => {
    this.apiApprovedPhotos(animate);
  };

  scheduleApi = (refreshRate) => {
    this.interval = setInterval(() => this.fetchApi(), refreshRate * 1000);
  };

  scheduleAnimation = (displayRefreshRate) => {
    this.animationInterval = setInterval(
      () => this.toggleAnimationClass(),
      displayRefreshRate * 1000
    );
  };

  animate = () => {
    let {
      gallery: { displayLimit = 15 },
    } = getEmbedThemeInfo();
    let postsToRender = [];
    for (let i = 0; i < displayLimit; i++) {
      postsToRender.push(this.state.posts.current());
      this.state.posts.next();
    }
    this.setState({ postsToRender, fadeIn: true, fadeOut: false });
  };

  toggleAnimationClass = () => {
    this.setState({ fadeIn: false, fadeOut: true }, () =>
      setTimeout(this.animate, 500)
    );
  };

  componentDidMount() {
    let {
      info: {gallery: { refresh, apiRefreshRate, displayRefreshRate }},
    } = this.getCurrentTheme();
    this.fetchApi(true);
    if (refresh) {
      this.scheduleApi(apiRefreshRate);
      this.scheduleAnimation(displayRefreshRate);
      return;
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.animationInterval);
  }

  getItems() {
    let noOfPosts = this.getCurrentTheme().info.gallery_count || 3;
    return this.state.postsToRender.slice(0, noOfPosts).map((post) => {
      return <GalleryCard key={post.getId() +"_"+uuidv4()} post={post} />;
    });
  }

  back = () => {
    const themeName = this.getCurrentTheme().name;
    if(this.props.history) {
      this.props.history.push(`/home?theme=${themeName}`);
      return;
    }
    if(this.props.back) {
      return this.props.back();
    }
  };

  render() {
    const title = getEmbedExperienceText('gallery', 'galleryTitle')
    // const title = this.getCurrentTheme().info.home["/gallery"] || "Gallery";
    if (this.state.postsToRender.length <= 0) {
      return <div className="spinner"></div>;
    }
    return (
      <div className={`gallery-parent-container ${
        this.state.fadeIn ? 'fade-in': ''
      } ${this.state.fadeOut ? 'fade-out': ''}`}>
        <div className="header">
          <div className="back" onClick={this.back}>
            <i className="icon-fk icon-fk-left-open" />
          </div>
          <div className="gallery-parent-title">{title}</div>
        </div>
        <div
          className={`gallery-container`}
        >
          <div className="gallery-cards">{this.getItems()}</div>
        </div>
      </div>
    );
  }
}
