import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { waitForAddedNode } from './utils'
import ExternalLink from './component/ExternalLink'

waitForAddedNode({
  id: 'fk-social-root',
  recursive: false,
  done: (element) => {
    ReactDOM.render(<App />, element)
  },
})

waitForAddedNode({
  id: 'fk-link-root',
  recursive: false,
  done: (element) => {
    ReactDOM.render(<ExternalLink />, element)
  },
})
