import React, { Component } from "react";

import { getHighlights } from "../Services.js";
import { withRouter } from "react-router-dom";
import { getEmbedThemeName, getEmbedThemeInfo, getEmbedExperienceText } from "../theme/Loader";
import CircularArray from '../model/Circular';
import TextFeed from '../component/TextFeed';
import { v4 as uuidv4 } from 'uuid';

import { handleError } from '../utils';

import "./highlights.css";

import Cardv2 from "../component/Cardv2.js";

export default class Highlights extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.animationInterval = null;
    this.state = {
      posts: [],
      post: null,
      status: false,
      fadeIn: false,
      fadeOut: false,
      postsToRender: [],
    };
  }

  getCurrentTheme = () => {
    return ({name: getEmbedThemeName(), info: getEmbedThemeInfo()})
  };

  apiFeatured = (animate) => {
    let {
      info: {highlights: { apiLimit, contentType, filter, format }},
    } = this.getCurrentTheme();
    let params = {
      limit: apiLimit,
      contentType,
      filter,
      format,
    };

    getHighlights(
      (posts) => {
        let circularPosts = new CircularArray(posts);
        // fade in
        this.setState({ posts: circularPosts }, () => {
          if (animate) {
            this.toggleAnimationClass();
            return;
          }
        });
      },
      handleError,
      params
    );
  };

  fetchApi = (animate) => {
    this.apiFeatured(animate);
  };

  scheduleApi = (apiRefreshRate) => {
    this.interval = setInterval(() => this.fetchApi(), apiRefreshRate * 1000);
  };

  scheduleAnimation = (displayRefreshRate) => {
    this.animationInterval = setInterval(
      () => this.toggleAnimationClass(),
      displayRefreshRate * 1000
    );
  };

  animate = () => {
    let {
      info: {highlights: { displayLimit = 10 }},
    } = this.getCurrentTheme();
    let postsToRender = [];
    for (let i = 0; i < displayLimit; i++) {
      postsToRender.push(this.state.posts.current());
      this.state.posts.next();
    }
    this.setState({ postsToRender, fadeIn: true });
  };

  toggleAnimationClass = () => {
    this.setState({ fadeIn: false, fadeOut: true }, () =>
      setTimeout(this.animate, 500)
    );
  };

  componentDidMount() {
    let {
      info: {highlights: { refresh, apiRefreshRate, displayRefreshRate }},
    } = this.getCurrentTheme();
    this.fetchApi(true);
    if (refresh) {
      this.scheduleApi(apiRefreshRate);
      this.scheduleAnimation(displayRefreshRate);
      return;
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.animationInterval);
  }

  popUp = (post) => {
    this.setState({ post });
  };

  reset = () => {
    this.setState({ post: null });
  };

  getItems() {
    return this.state.postsToRender.map((post) => {
      if (post.getMediaUrl()) {
        return (
          <Cardv2
            portrait={post.isPortrait()}
            key={post.getId()+"_"+uuidv4()}
            post={post}
            showPopup={this.popUp}
          />
        )
     } else {
       return <TextFeed key={post.getId()} post={post} />;
     }
    });
  }
  
  // back = () => {
  //   const themeName = this.getCurrentTheme().name;
  //   if(this.props.history) {
  //     this.props.history.push(`/home?theme=${themeName}`);
  //     return;
  //   }
  //   if(this.props.back) {
  //     return this.props.back();
  //   }
  // };
  back = () => {
    // console.log('-goback highlights-', this.props.prevExperience);
    if(!this.props.prevExperience) {
      return;
    }
    this.props.setExperience(this.props.prevExperience)
    this.props.setCategory('')
  }

  render() {
    // const title = this.getCurrentTheme().info.home["/highlights"] || "Highlights";
    const title = getEmbedExperienceText('highlights', 'highlightsTitle')
    if (this.state.postsToRender.length <= 0) {
      return <div className="spinner"></div>;
    }
    return (
      <div className="new-highlights-container">
        <div className="header">
          <div className="back" onClick={this.back}>
            <i className="icon-fk icon-fk-left-open" />
          </div>
          <div className="hightlights-title">{title}</div>
        </div>
        <div
          className={`cards ${this.state.fadeIn ? "fade-in" : ""} ${
            this.state.fadeOut ? "fade-out" : ""
          }`}
        >
          {this.getItems()}
        </div>
      </div>
    );
  }
}

export const withRouterHighlights = withRouter(Highlights);
