import React, { Component } from "react";
import periscope from "../assets/periscope.png";
import defaultImg from "../assets/default.jpg";
import { getThemeInfo } from "../theme/Loader";
import {findHashtags} from '../utils.js'

import "./textfeed.css";

export default class TextFeed extends Component {
  authorName() {
    let { post } = this.props;
    return <div className="name">{post.getAuthorName()}</div>;
  }

  truncate = (s = "", portrait = false) => {
    let charLimit = getThemeInfo().char_text_limit || 120;
    if (s.length <= charLimit) {
      return s;
    } else {
      return s.slice(0, charLimit).concat("...");
    }
  };
  formatNum = (num) => {
    if(num > 999) {
      return (num/1000).toFixed(1) + "k";
    }
    return num;
  }

  getLikes() {
    let { post } = this.props;
    if (post.getLikes() > 0) {
      return (
        <span onClick={this.likeAction}>
          <i className="like-icon-fk icon-fk-heart-empty-1" />
          <span className="like-count">{this.formatNum(post.getLikes())}</span>
        </span>
      );
    }
    return (
      <i className="like-icon-fk icon-fk-heart-empty-1" onClick={this.likeAction}>
        <span className="like-count"></span>
      </i>
    );
  }
  getSource() {
    let { post } = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
      return (
        <span className="src-twitter">
          <i className="icon-fk-twitter-1" />
          <div className='x-logo'></div>
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === "instagram") {
      return (
        <span className="src-instagram">
          <i className="icon-fk-instagram-1" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === "youtube") {
      return (
        <span className="src-twitter">
          <i className="icon-fk-youtube" />
        </span>
      );
    }
    if (post.getSource() && post.getSource().toLowerCase() === "periscope") {
      return (
        <span className="src-twitter">
          <img src={periscope} alt="" />
        </span>
      );
    }
    return null;
  }

  getRetweets() {
    let { post } = this.props;
    if (post.getRetweets() > 0) {
      return (
        <span onClick={this.retweetAction}>
          <i className="retweet-icon-fk icon-fk-retweet" />
          <span className="retweet-count">{this.formatNum(post.getRetweets())}</span>
        </span>
      );
    }
    return (
      <i className="retweet-icon-fk icon-fk-retweet" onClick={this.retweetAction}>
        <span className="retweet-count"></span>
      </i>
    );
  }

  likeAction = () => {
    let {post} = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
    window.open(`https://twitter.com/intent/like?tweet_id=${post.getNativeId()}`, '', "height=400,width=400")
    }
  }

  retweetAction = () => {
    let {post} = this.props;
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
    window.open(`https://twitter.com/intent/retweet?tweet_id=${post.getNativeId()}`, '', "height=400,width=400")
    }
  }

  replyAction = () => {
    let {post} = this.props;
    let hashtags = findHashtags(post.getText())
    if (post.getSource() && post.getSource().toLowerCase() === "twitter") {
    window.open(`https://twitter.com/intent/tweet?in_reply_to=${post.getNativeId()}&hashtags=${hashtags.join(',')}`, '', "height=400,width=400")
    }
  }

  render() {
    let { post } = this.props;
    return (
        <div className="text-feed">
          <div className="source">{this.getSource()}</div>
          <div className="details">
            <div className="author">
              <img
                className="avatar"
                src={post.getAuthorPhoto() || defaultImg}
                alt=""
              />
              {this.authorName()}
              <div className="handle">{post.getAuthorHandle()}</div>
              <span className="created-at">{post.getCreatedAt()}</span>
            </div>
            <a href={`${post.getUrl()}`} target="_blank" rel="noopener noreferrer">
            <div className="msg">{this.truncate(post.getText())}</div>
            </a>
          </div>
          <div className="stats">
              <i className="reply-icon-fk icon-fk-reply-all" onClick={this.replyAction} />
              {this.getRetweets()}
              {this.getLikes()}
          </div>
        </div>
    );
  }
}
