import amplitude from 'amplitude-js'

const AMPLITUDE_API_KEY = '5306297edf15a76599e4ca3d4391e7c4'
const AMPLITUDE_OPTIONS = {
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
  disableCookies: true,
  trackingOptions: {
    city: true,
    country: true,
    carrier: true,
    device_manufacturer: true,
    device_model: true,
    dma: true,
    ip_address: true,
    language: true,
    os_name: true,
    os_version: true,
    platform: true,
    region: true,
    version_name: true,
  },
}

export class AnalyticsProviderClass {
  constructor(widget) {
    this.widget = widget
    this.instance = amplitude.getInstance(widget)
    this.instance.init(AMPLITUDE_API_KEY, null, AMPLITUDE_OPTIONS)
    this.instance.setTransport('beacon')
  }
  getInstance() {
    return this.instance
  }

  // String Event Name, Object Event Values
  dispatchAnalyticsEvent = (eventName, eventProperties = {}) => {
    try {
      // console.log('in event', eventName)
      this.instance.logEvent(eventName, eventProperties)
    } catch (err) {
      console.error('Amplitude Error', err)
    }
  }
}
