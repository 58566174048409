import qs from "qs";
import dayjs from "dayjs";
import _ from 'lodash';

export function getDateDiff(date) {
  try {
    let today = dayjs();
    let postDate = dayjs(date);
    let dateDiff = today.diff(postDate, "day");
    if (dateDiff < 1) {
      return "today";
    }
    if (dateDiff === 1) {
      return `yesterday`;
    }
    if (dateDiff <= 6) {
      return `${dateDiff} days ago`;
    }
    if (dateDiff <= 30) {
      let diff = today.diff(postDate, "week");
      if (diff === 1) {
        return `${diff} week ago`;
      }
      return `${diff} weeks ago`;
    }
    if (dateDiff < 365) {
      let diff = today.diff(postDate, "month");
      if (diff === 1) {
        return `${diff} month ago`;
      }
      return `${diff} months ago`;
    }
  } catch (err) {
    return dayjs(date).format("DD MMM");
  }
}

export const handleError = (error) => {
  console.error("Error fetching posts", error);
};

export const findHashtags = (searchText) => {
  const regexp = /\B\#\w\w+\b/g; // eslint-disable-line
  const result = searchText.match(regexp);
  if (result) {
    return result.map((str) => str.replace("#", ""));
  } else {
    return false;
  }
};

export const triggerSAEvent = (string) => {
  if (window.sa_event) {
    let url = "";
    try {
      url = window.location.href.split("?")[0];
    } catch (err) { }
    // console.log(`${url}: action: ${string}`)
    window.sa_event(`${url}: action: ${string}`);
  }
};

export const getRemainingTime = (timestamp) => {
  return timestamp - dayjs().unix();
};

export const waitForAddedNode = (params) => {
  const config = {
    subtree: !!params.recursive || !params.parent,
    childList: true,
  };
  const parent = params.parent || document;
  const el = document.getElementById(params.id);
  const creationObserver = new MutationObserver(function () {
    const target = document.getElementById(params.id);
    if (target) {
      this.disconnect();
      params.done(target);
      removalObserver.observe(parent, config);
    }
  });
  const removalObserver = new MutationObserver(function () {
    if (!document.getElementById(params.id)) {
      this.disconnect();
      creationObserver.observe(parent, config);
    }
  });
  if (el) {
    params.done(el);
    removalObserver.observe(parent, config);
  }
  if (!el) {
    creationObserver.observe(parent, config);
  }
};

export const convertSecondsToHourMinute = (milliSeconds) => {
  const sec_num = parseInt(milliSeconds, 10);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;

  const formattedTime = [minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .join(":");
  return formattedTime;
};

export const getQueryParamsValues = (queryString, key) => {
  const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true }) || {};
  return queryParams[key];
};

export const getUrlParams = (key) => {
  return getQueryParamsValues(window.location.search, key);
}

export const getFromLocalStorage = () => {
  const body = localStorage.getItem('body') || "{}";
  const aeAppsVars = JSON.parse(body);
  const department = aeAppsVars['Department'];
  // const group = aeAppsVars['Social Groups'];
  // const hostel = aeAppsVars['Final year hostel'];
  // const values = [department, group, hostel].filter(e => e).map(str => str.replace(/ /g, '').toLowerCase());
  const values = [department].filter(e => e).map(str => str.replace(/ /g, '').toLowerCase());
  return values
}

export const getHostel = () => {
  const body = localStorage.getItem('body') || "{}";
  const aeAppsVars = JSON.parse(body);
  const hostel = _.get(aeAppsVars, 'Final year hostel', '').replace(/ /g, '').toLowerCase();
  return hostel;
}

export const getDept = () => {
  const body = localStorage.getItem('body') || "{}";
  const aeAppsVars = JSON.parse(body);
  const hostel = _.get(aeAppsVars, 'Department', '').replace(/ /g, '').toLowerCase();
  return hostel;
}

export const getGroup = () => {
  const body = localStorage.getItem('body') || "{}";
  const aeAppsVars = JSON.parse(body);
  const hostel = _.get(aeAppsVars, 'Social Groups', '').replace(/ /g, '').toLowerCase();
  return hostel;
}
