import React, { Component } from 'react';

import { getHighlights } from '../Services.js';

import './carousel.scss';

import { getEmbedThemeInfo, getThemeInfo } from '../theme/Loader.js';
import Cardv2 from '../component/Cardv2.js';
import CircularArray from '../model/Circular';

function isGecko() {
    return /Gecko/.test(navigator.userAgent) && /Firefox/.test(navigator.userAgent);
}

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      post: null,
      defaultLoadLength: 4,
      postsToRender: [],
      index: 0,
      currentAction: '',
    };
  }

  componentDidMount() {
    // console.log('fankave: div mount')
    // console.log('info', getEmbedThemeInfo());
    let defaultLoadLength = getThemeInfo().carousel_row_count || 4;
    let filter = getEmbedThemeInfo().carousel_filter || null;
    let length = getEmbedThemeInfo().carousel_count || 10;
    // console.log('filter', filter);
    getHighlights(posts => {
      // console.log('fankave: api call successful', JSON.stringify(posts, null, 1))
      let circ = new CircularArray(posts);
      this.setState({ posts: circ, defaultLoadLength }, this.next);
    }, (err) => {
      console.error('fankave: Error fectching api call', err);
    }, {filter, limit: length});
  }

  popUp = post => {
    this.setState({ post });
  };

  reset = () => {
    this.setState({ post: null });
  };

  getItems = () => {
    // if (this.state.postsToRender.length === 0) {
    //   this.getNext();
    // }
    // let postsToRender = [];
    // for (let i = 0; i < this.state.defaultLoadLength; i++) {
    //   if (this.state.posts.length > 0) {
    //     postsToRender.push(this.state.posts.current());
    //     this.state.posts.next();
    //   }
    // }
    // console.log(this.state.postsToRender)
    // console.log('fankave: cards initialization', JSON.stringify(this.state.postsToRender, null, 1))
    // console.log('fankave: cards length', this.state.postsToRender.length)
    return this.state.postsToRender.filter((e) => e).map(post => (
      <Cardv2 key={post.getId()} post={post} showPopup={this.popUp} />
    ));
  }

  updatePosts = (posts) => {
    // console.log('index', this.state.posts.currentIndex)
    this.setState({ postsToRender: posts })
  }

  next = () => {
    // console.log('fankave: next next')
    var p = [];
    if (this.state.postsToRender.length === 0 && this.state.currentAction === '') {
      // console.log('nex 1')
      p.push(this.state.posts.current())
      for (let i = 0; i < 3; i++) {
        p.push(this.state.posts.next())
        // currentAction = 'next';
      }
      this.setState({ currentAction: 'next' }, () => this.updatePosts(p))
      return;
    }
    if (this.state.currentAction === 'next' && this.state.postsToRender.length !== 0) {
      // console.log('nex 2')
      // for(let i = 0; i < 4; i++) {
      //   p.push(this.state.posts.next())
      // }
      p.push(this.state.posts.next())
      // console.log(this.state.postsToRender)
      this.setState({ currentAction: 'next' }, () => this.updatePosts(this.state.postsToRender.concat(p).slice(1, 5)))
      return;
    }
    if (this.state.currentAction === 'prev' && this.state.postsToRender.length !== 0) {
      // console.log('fankave: next prev')
      for (let i = 0; i < 4; i++) {
        p.push(this.state.posts.next());
      }
      // for(let i = 0; i < 4; i++) {
      //   p.push(this.state.posts.next())
      // }
      this.setState({ currentAction: 'next' }, () => this.updatePosts(p))
      return;
    }
  }

  prev = () => {
    // console.log('rev')
    // console.log('fankave: prev')
    var p = [];
    if (this.state.postsToRender.length === 0 && this.state.currentAction === '') {
      // console.log('rev 1');
      p.push(this.state.posts.current())
      for (let i = 0; i < 3; i++) {
        p.push(this.state.posts.prev())
      }
      this.setState({ currentAction: 'prev' }, () => this.updatePosts(p.reverse()))
      return;
    }
    if (this.state.currentAction === 'prev' && this.state.postsToRender.length !== 0) {
      // console.log('fankave: prev prev')
      // console.log('rev 2');
      // for(let i = 0; i < 4; i++) {
      //   p.push(this.state.posts.prev())
      // }
      p.push(this.state.posts.prev())
      this.setState({ currentAction: 'prev' }, () => this.updatePosts(p.concat(this.state.postsToRender).slice(0, 4)))
      return;
    }
    if (this.state.currentAction === 'next' && this.state.postsToRender.length !== 0) {
      // console.log('fankave: prev next')
      // console.log('rev 3');
      for (let i = 0; i < 4; i++) {
        p.push(this.state.posts.prev());
      }
      // for(let i = 0; i < 4; i++) {
      //   p.push(this.state.posts.prev())
      //   console.log('in here');
      // }
      this.setState({ currentAction: 'prev' }, () => this.updatePosts(p.reverse()))
      return;
    }
  }





  getNext = () => {
    // console.log('fankave: getNext')
    let postsToRender = [];
    for (let i = 0; i < this.state.defaultLoadLength; i++) {
      // this.state.posts.next()
      if (this.state.postsToRender.length === 0 && postsToRender.length === 0) {
        postsToRender.push(this.state.posts.current())
      } else {
        postsToRender.push(this.state.posts.next());
      }
      // this.state.posts.next();
    }
    // this.setState(prevState => ({index: prevState.index+prevState.defaultLoadLength}))
    // console.log('next', postsToRender);
    this.setState({ postsToRender })
  }

  getPrevious = () => {
    // console.log('fankave: getPrev')
    // this.setState(prevState => ({index: prevState.index - prevState.defaultLoadLength}))
    let postsToRender = [];
    for (let i = 0; i < this.state.defaultLoadLength + 1; i++) {
      this.state.posts.prev();
      // this.state.posts.prev();
    }
    for (let i = 0; i < this.state.defaultLoadLength; i++) {
      postsToRender.push(this.state.posts.prev());
      // this.state.posts.prev();
    }
    // this.setState(prevState => ({index: prevState.index+prevState.defaultLoadLength}))
    // console.log('prev', postsToRender)
    this.setState({ postsToRender })
  }

  render() {
    // console.log('fankave: render')
    if (this.state.post) {
      // console.log('fankave: this.state.post not null')
      return (
        <div className="carousel-container">
          <Cardv2 post={this.state.post} />
          <div className="close">
            <div onClick={this.reset}>X</div>
          </div>
        </div>
      );
    }
    // console.log('fankave: rendering carousle container')
    if(isGecko()) {
      return null
    }
    return (
      <div className='carousel-container'>
        <div className='previous' onClick={this.prev}><i className='icon-left-open' /></div>
        <div className="carousel">
          {this.getItems()}
        </div>
        <div className='next' onClick={this.next}><i className='icon-right-open' /></div>
      </div>
    );
  }
}
