import React, { Component } from "react";

import { getFeatured, getAll } from "../Services.js";
import GalleryCard from "../component/GalleryCard.js";

import "./gallery-old.css";

import { getEmbedThemeInfo } from "../theme/Loader.js";
import { Container } from "../component/Container.js";

export default class GalleryOld extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      posts: [],
      status: true,
      fadeIn: false,
      fadeOut: false,
    };
  }

  apiFeatured = () => {
    getFeatured((posts) => {
      // fade in
      this.setState({ posts, status: false, fadeIn: true, fadeOut: false });
    });
  };

  apiAll = () => {
    getAll((posts) => {
      // fade in
      this.setState({ posts, status: true, fadeIn: true, fadeOut: false });
    });
  };

  fetchApi = () => {
    if (this.state.status) {
      // console.log(this.state.status);
      // fade out
      this.setState({ fadeIn: false, fadeOut: true }, this.apiFeatured);
    } else {
      // fade out

      // fade in
      this.setState({ fadeIn: false, fadeOut: true }, this.apiAll);
    }
  };

  schedule = (refreshRate) => {
    this.interval = setInterval(() => this.fetchApi(), refreshRate * 1000);
  };

  componentDidMount() {
    let {
      gallery: { refresh, refreshRate },
    } = getEmbedThemeInfo();
    // console.log('---gallery old', refresh, refreshRate)
    this.fetchApi();
    if (refresh) {
      this.schedule(refreshRate);
      return;
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getItems() {
    let noOfPosts = getEmbedThemeInfo().gallery_count || 3;
    let postsToRender = this.state.posts.slice(0, noOfPosts);
    return postsToRender.map((post) => (
      <GalleryCard key={post.getId()} post={post} />
    ));
  }

  render() {
    if (this.state.posts.length <= 0) {
      return <div className="spinner"></div>;
    }
    return (
      <Container experience={this.props.experience} {...this.props}>
        <div
          className={`gallery-container ${this.state.fadeIn ? "fade-in" : ""} ${
            this.state.fadeOut ? "fade-out" : ""
          }`}
        >
          <div className="gallery-title">Photomosaic</div>
          <div className="gallery-cards">{this.getItems()}</div>
        </div>
      </Container>
    );
  }
}
