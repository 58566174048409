import React, { Component } from 'react';
import { Category } from '../component/Category';
import classnames from 'classnames'
import './landing.css';
import highlight from '../assets/category/highlight-one.png';
import leaderboard from '../assets/category/leaderboard-one.png';
import featured from '../assets/category/featured.png';
import ciscoLeader from '../assets/category/cisco-leaderboard.png';


export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  redirectToSocial = () => {
    // console.log('in here',
    this.props.setExperience('postspopup');
    // return this.props.history.push('/postspopup?theme=clmel');
  }
  redirectToGallery = () => {
    // console.log('in here',
    this.props.setExperience('galleryold');
    // return this.props.history.push('/gallery?theme=clmel');
  }
  redirectToLeaderboard = () => {
    // console.log('in here',
    this.props.setExperience('leaderboard');
    // return this.props.history.push('/leaderboard?theme=clmel');
  }
  redirectToCiscoLeaderboard = () => {
    // console.log('in here',
    this.props.setExperience('cisco-leaderboard');
    this.props.setCategory('cisco');
    // return this.props.history.push('/cisco-leaderboard?theme=clmel&category=cisco');
  }
  render() {
    // console.log('landing props', this.props);
    return (
      <div className='landing-parent'>
        <div className='landing-header'>
          <div className='landing-parent-title'>
            Social Media Lounge
        </div>
          <div className='landing-action-btn'>
            <div className='action-btn'><i className='icon-fk-twitter-1'></i>Post</div>
          </div>
        </div>
        <div className='landing-container'>
          <div className='landing-description'>
            Welcome to the Social Media Lounge where you can see conversation highlights and featured photos.   Check out the Attendee and Cisco leaderboards to see who’s leading the #CiscoLive conversation on   Twitter.
          </div>
          <div className='row'>
            <div className='column'>
              <Category
                className={classnames({ one: true })}
                img={highlight}
                text={'#CiscoLive Highlights'}
                btnText={'SEE HIGHLIGHTS'}
                redirect={this.redirectToSocial}
              />
              <Category
                className={classnames({ three: true })}
                img={leaderboard}
                text={'Attendee Leaderboard'}
                btnText={'VIEW RANKINGS'}
                redirect={this.redirectToLeaderboard}
              />
            </div>
            <div className='column'>
              <Category
                className={classnames({ two: true })}
                img={featured}
                text={'Featured Photos'}
                btnText={'VIEW GALLERY'}
                redirect={this.redirectToGallery}
              />
              <Category
                className={classnames({ four: true })}
                img={ciscoLeader}
                text={'Cisco Leaderboard'}
                btnText={'TOP TWEETERS'}
                redirect={this.redirectToCiscoLeaderboard}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
