import { useEffect, useState } from "react";
import { OktaAuth } from "@okta/okta-auth-js";
import axios from "axios";

import { getQueryParamsValues, getRemainingTime, getUrlParams } from "../utils";
import Config from "./../Config";
import { getEmbedThemeInfo } from "../theme/Loader";

const EVENT_TYPE = "onImpactStorageChange";

const dispatch = (eventType) => {
  window.dispatchEvent(new CustomEvent(eventType));
};

const setToken = (token) => {
  sessionStorage.setItem("fk-impact-okta-auth", token);
  return dispatch(EVENT_TYPE);
};

const setEmail = (email) => {
  return sessionStorage.setItem("fk-impact-okta-email", email);
};

const removeToken = () => {
  sessionStorage.removeItem("fk-impact-okta-auth");
  sessionStorage.removeItem("fk-impact-okta-email");
  return dispatch(EVENT_TYPE);
};

const REACT_APP_ENV = process.env.REACT_APP_ENV || "local";
const appConfig = Config.api[REACT_APP_ENV];

let OktaConfig = {};
const themeConfig = getEmbedThemeInfo();
console.log('fk okta auth themeConfig', JSON.stringify(themeConfig))
if (themeConfig.ctag === "impact21") {
  OktaConfig = {
    base_url: "https://int-id.cisco.com",
    client_id: "0oax0xjlqRrWQsEoA1d6",
    redirectUri: "/implicit/callback",
  };
} else {
  OktaConfig = {
    base_url: appConfig.OKTA_URL,
    client_id: appConfig.OKTA_CLIENT_ID,
    redirectUri: appConfig.OKTA_REDIRECT_URI,
  };
}

const params = {
  // Required config
  issuer: `${OktaConfig.base_url}/oauth2/default`,
  clientId: `${OktaConfig.client_id}`,
  redirectUri: `${OktaConfig.redirectUri}`,
  // Use authorization_code flow
  responseType: "code",
  scope: "openid offline_access email",
  pkce: true,
};

const verifyAccessToken = (token) => {
  const referrer = getUrlParams("referrer") || null;
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios(
        `${appConfig.AUTH_CMS}verifyToken?x-access-token=${token}&referrer=${referrer}`
      );
      resolve(data);
    } catch (e) {
      console.error('-------fk cmsa error:', e)
      reject(e);
    }
  });

  // fetch()
  //     .then(e => e.json())
  //     .then(e => {
  //       // console.log('token valid, load user info into memory')
  //       // setUser({full_name: 'alen thomas', first_name: 'alen', last_name: 'thomas'});
  //       // setLoading(false);
  //       // return;
  //       // console.error('Error validating user', err);

  //       if (e && e.active) {
  //         userInfo = e;
  //         // console.log('USER', e);
  //         setUser(e);
  //         setLoading(false);
  //       } else {
  //         setUser(false);
  //         setLoading(false);
  //       }
  //       setLoading(false);
  //     }).catch(err => {
  //       // setUser(false);
  //       // setLoading(false);
  //       console.error('Error validating user', err);
  //       setUser({ first_name: 'Susan', last_name: 'Cargill' });
  //       setLoading(false);
  //       console.error('Error validating user', err);
  //     })
};

export const useOktaAuth = () => {
  const existingToken = getQueryParamsValues(
    window?.location?.search || "",
    "accessToken"
  );
  console.log("-------fk okta sdk params: ", params, "QueryToken: ", existingToken);
  const authClient = new OktaAuth(params);
  const [sessionToken, setSessionToken] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [schedulerId, setSchedulerId] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const generateToken = () => {
    setIsAuthenticating(true);
    if (existingToken) {
      // console.log('token generation')
      verifyAccessToken(existingToken)
        .then((res) => {
          const { email_address: email = "" } = res || {};
          // console.log('Response', email)
          setSessionData(res);
          setSessionToken(existingToken);
          setToken(existingToken);
          setEmail(email);
          setIsAuthenticating(false);
        })
        .catch((err) => {
          console.error("fankave okta auth err", err);
          setIsAuthenticating(false);
          resetToken();
        });
    } else {
      authClient.token
        .getWithoutPrompt({
          responseType: "id_token", // or array of types
          sessionToken: sessionToken, // optional if the user has an existing Okta session
        })
        .then((res) => {
          // console.log("fankave okta sdk response ", res);
          const tokens = res.tokens;
          const { accessToken, expiresAt, claims = {} } = tokens.accessToken;
          const { email_address: email = "" } = claims;
          // Do something with tokens, such as
          // authClient.tokenManager.setTokens(tokens)
          setSessionData(claims);
          setSessionToken(accessToken);
          setToken(accessToken);
          setEmail(email);
          const timeoutID = setTimeout(
            refreshToken,
            (getRemainingTime(expiresAt) - 300) * 1000
          );
          setSchedulerId(timeoutID);
          setIsAuthenticating(false);
        })
        .catch((err) => {
          console.error("fankave okta auth else err", err);
          setIsAuthenticating(false);
          resetToken();
        });
    }
  };

  const refreshToken = () => {
    console.log('in refresh token')
    if (!existingToken) {
      setIsAuthenticating(true);
      authClient.token
        .renew({ sessionToken, scopes: ["openid", "email"] }) // RefreshToken
        .then((res) => {
          const { accessToken, expiresAt, claims = {} } = res.accessToken;
          const { email_address: email = "" } = claims;
          setSessionData(claims);
          setSessionToken(accessToken);
          setToken(accessToken);
          setEmail(email);
          clearTimeout(schedulerId);
          const timeoutID = setTimeout(
            refreshToken,
            (getRemainingTime(expiresAt) - 300) * 1000
          );
          setSchedulerId(timeoutID);
          setIsAuthenticating(false);
        })
        .catch((err) => {
          // console.log("err", err);
          setIsAuthenticating(false);
          resetToken();
        });
    }
  };

  const resetToken = () => {
    if (sessionToken && !existingToken) {
      authClient.token.revoke(sessionToken);
      setSessionToken(null);
      setSessionData(null);
    }
    removeToken();
    if (schedulerId) {
      clearTimeout(schedulerId);
    }
  };

  useEffect(() => {
    // console.log('Pre Render')
    generateToken();
    window.fkImpactOktaAuth = {
      logout: resetToken,
    };

    // console.log('Post Render')
    return () => {
      resetToken();
      window.fkImpactOktaAuth = {
        logout: removeToken,
      };
    };
    // eslint-disable-next-line
  }, []);

  return [
    {
      isAuthenticated: !!sessionToken,
      sessionToken,
      sessionData,
      isAuthenticating,
    },
    refreshToken,
    resetToken,
  ];
};
