import React, { useEffect, useRef, useReducer } from "react";

import PlayButton from "../../../assets/play-icon-blue.svg";
import PauseButton from "../../../assets/impact21/images/pause-button2.png";
import { Loader } from "../Loader/Loader";

import "./videoPlayer.css";

const formatTime = (timeStamp) => {
  if (timeStamp <= 0 || !Number.isFinite(timeStamp)) {
    return `00:00`;
  }
  //Get hours from Seconds
  const hours = timeStamp / (60 * 60);
  const absoluteHours = Math.floor(hours);
  const h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  const m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  const seconds = (minutes - absoluteMinutes) * 60;
  const absoluteSeconds = Math.floor(seconds);
  const s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;
  if (absoluteHours) {
    return `${h}:${m}:${s}`;
  }
  return `${m}:${s}`;
};

const getTimeStamps = (timeStamp, duration) => {
  return {
    currentTime: formatTime(timeStamp),
    remainingTime: formatTime(duration - timeStamp),
  };
};

const reducer = (state, newState) => {
  return { ...state, ...newState };
};

const initialState = {
  canPlay: false,
  isWaiting: true,
  isPlaying: false,
  timeStamp: 0,
  duration: 0,
};

const Seekbar = ({ timeStamp, duration, onSeek }) => {
  const value = (timeStamp / duration) * 100;

  const handleSeek = (e) => {
    const { left = 0, width = 1 } = e.target.getBoundingClientRect();
    const x = e.pageX - left;
    const clickedValue = (x * e.target.max) / width;
    const newTimestamp = (clickedValue / 100) * duration;
    onSeek(newTimestamp);
    e.stopPropagation();
  };

  const { currentTime, remainingTime } = getTimeStamps(timeStamp, duration);
  return (
    <>
      <progress
        id="seek-bar"
        value={`${value}`}
        max="100"
        className="fk-video-seek-bar"
        onClick={handleSeek}
      >
        {value}%
      </progress>
      <div className="fk-video-timestamp">
        <div className="fk-current-time">{currentTime}</div>
        <div className="fk-remaining-time">{`-${remainingTime}`}</div>
      </div>
    </>
  );
};

export const VideoPlayer = ({
  thumbUrl,
  url,
  duration: propDuration,
  autoPlay,
  style = {},
}) => {
  const [{ canPlay, isWaiting, isPlaying, timeStamp, duration }, dispatch] =
    useReducer(reducer, {
      ...(propDuration
        ? { ...initialState, duration: propDuration }
        : initialState),
    });
  const playerRef = useRef(null);

  const handlePlay = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pause();
        return dispatch({ isPlaying: false });
      }
      if (!isPlaying) {
        playerRef.current.play();
        return dispatch({ isPlaying: true });
      }
    }
  };

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      if (autoPlay) {
        handlePlay();
      }
      player.addEventListener("canplay", handleCanPlay);
      player.addEventListener("waiting", handleWaiting);
      player.addEventListener("playing", handlePlaying);
      player.addEventListener("timeupdate", handleTimeUpdate);
      player.addEventListener("ended", handleEnd);
    }
    return () => {
      if (player) {
        player.removeEventListener("canplay", handleCanPlay);
        player.removeEventListener("waiting", handleWaiting);
        player.removeEventListener("playing", handlePlaying);
        player.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleCanPlay = () => {
    if (playerRef.current && !duration) {
      dispatch({ duration: playerRef.current.duration || 0 });
    }
    dispatch({ canPlay: true, isWaiting: false });
  };

  const handleWaiting = () => dispatch({ canPlay: false, isWaiting: true });
  const handlePlaying = () => dispatch({ canPlay: true, isWaiting: false });
  const handleEnd = () => dispatch({ isPlaying: false });
  const handleTimeUpdate = () => {
    if (playerRef.current) {
      dispatch({
        timeStamp: playerRef.current.currentTime || 0,
        duration: duration || playerRef.current.duration,
      });
    }
  };

  const handleSeek = (newTimeStamp) => {
    const player = playerRef.current;
    if (player) {
      player.currentTime = newTimeStamp;
      dispatch({ timeStamp: newTimeStamp });
    }
  };

  return (
    <div className="fk-video-player">
      <div
        className="fk-video-player-inner"
        onClick={(e) => {
          handlePlay();
          e.stopPropagation();
        }}
      >
        {!isPlaying && (
          <div
            className="fk-video-thumbnail"
            style={{
              ...(timeStamp > 0
                ? {}
                : {
                    backgroundImage: `url(${thumbUrl})`,
                  }),
            }}
          >
            <div className="fk-video-play">
              <img
                src={PlayButton}
                className="fk-video-play-icon"
                alt="thumbnail"
              />
            </div>
          </div>
        )}
        {isWaiting && isPlaying && <Loader />}
        {isPlaying && (
          <div className="fk-video-overlay">
            <img
              src={PauseButton}
              className="fk-video-pause-icon"
              alt="pause-icon"
            />
          </div>
        )}
        <div className="fk-video-player-container" style={style}>
          <video
            className="fk-video-player-screen"
            ref={playerRef}
            crossOrigin="'anonymous"
          >
            <source src={url} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </div>
      </div>
      <div className="fk-video-control">
        <Seekbar
          timeStamp={timeStamp}
          duration={duration}
          onSeek={canPlay ? handleSeek : (_) => {}}
        />
      </div>
    </div>
  );
};
