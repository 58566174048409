import _ from "lodash";
import { getThemeName, getCharLimit } from "../theme/Loader.js";
import dayjs from "dayjs";
import { getDateDiff } from "../utils.js";
export default class Post {
  constructor(data) {
    this.embed = data.content.sections[0].embed;
    this.source = data.source;
  }

  getId() {
    return this.embed.id;
  }

  getAuthorName() {
    return _.get(this.embed.author, "name", "Instagram User");
  }

  isVerifiedAuthor() {
    return this.embed.author.verified;
  }

  getAuthorHandle() {
    return `@${_.get(this.embed.author, "alias", "insta user")}`;
  }

  getAuthorPhoto() {
    return this.embed.author.photo;
  }
  isVideoMedia() {
    let url = _.get(this.embed, "media[0].url");
    const supportedVideoTypes = [
      "3gp",
      "3gpp",
      "mov",
      "mp4",
      "mp4v",
      "mpg4",
      "ogv",
      "webm",
    ];
    if (url) {
      // console.log('url', url);
      let isVideo = supportedVideoTypes.filter(
        (extension) => url.search(extension) > -1
      );
      return isVideo.length > 0 ? true : false;
    }
    return false;
  }

  getCreatedAt() {
    switch (getThemeName()) {
      case "cisco-retail":
        return dayjs(this.embed.createdAt).format("DD MMM");
      case "linkedin":
        return getDateDiff(this.embed.createdAt);
      case "nuhuskies":
        return dayjs(this.embed.createdAt).format("MMM DD");
      case "clmel":
        return dayjs(this.embed.createdAt).format("DD MMM");
      default:
        return dayjs(this.embed.createdAt).format("DD MMM YY");
    }
  }

  getHashtags() {
    return _.get(this.embed, "hashtags");
  }

  getMediaType() {
    return _.get(this.embed, "media[0].mediaType");
  }

  getMediaUrl() {
    let url1 = _.get(this.embed, "media[0].url");
    let url2 = this.getThumbnailUrl();
    if (url1) {
      return url1;
    }
    if (url2) {
      return url2;
    }
    return "";
  }

  getMediaUrls() {
    return _.get(this.embed, "media") || [];
  }

  getThumbnailUrl() {
    return _.get(this.embed, "media[0].thumbUrl");
  }

  getText(hyperlinks = false) {
    // return this.embed.text;
    return _.truncate(this.embed.text, {
      length: getCharLimit(),
      separator: /,? + \\./,
    });
  }

  getLikes() {
    return _.get(this.embed, "metrics.likes");
  }

  getRetweets() {
    return _.get(this.embed, "metrics.shares");
  }

  getUrl() {
    return _.get(this.embed, "url");
  }

  getType() {
    return _.get(this.embed, "type").toLowerCase();
  }

  getSource() {
    return _.get(this.source, "type");
  }

  isPortrait() {
    try {
      let { w, h } = _.get(this.embed, "media[0].sizes.full");
      return h > w;
    } catch (err) {
      console.info("no media");
      return false;
    }
  }
}
