import React, { useState, useEffect } from 'react';
import { ImageCapture, ImagePostReview, ImagePreview, AddImagePost, ImageDownload } from './../component/Stories/ImageViews';
import { RecordVideo, RecordVideoPreview, AddVideoPost, VideoPostReview, VideoDownload } from '../component/Stories/VideoViews';
import { UploadImage, UploadImageMultiple, UploadPreview, AddUploadPost, UploadPostReview, UploadDownload } from '../component/Stories/UploadViews';
// import logoImg from "../assets/impact21/images/impact-logo.png"
import { checkIfAeApps, getEmbedThemeInfo } from '../theme/Loader';
import _ from 'lodash';
import qs from "qs";
import { getUrlParams } from '../utils';
export const EXPERIENCE = {
  'CANCEL': 'CANCEL',
  'CAPTURE_IMAGE': 'CAPTURE_IMAGE',
  'PREVIEW_IMAGE': 'PREVIEW_IMAGE',
  'ADD_IMAGE_POST': 'ADD_IMAGE_POST',
  'IMAGE_POST_REVIEW': 'IMAGE_POST_REVIEW',
  'DOWNLOAD_IMAGE': 'DOWNLOAD_IMAGE',

  'RECORD_VIDEO': 'RECORD_VIDEO',
  'PREVIEW_VIDEO': 'PREVIEW_VIDEO',
  'ADD_VIDEO_POST': 'ADD_VIDEO_POST',
  'VIDEO_POST_REVIEW': 'VIDEO_POST_REVIEW',
  'DOWNLOAD_VIDEO': 'DOWNLOAD_VIDEO',

  'UPLOAD_IMAGE': 'UPLOAD_IMAGE',
  'UPLOAD_IMAGE_MULTIPLE': 'UPLOAD_IMAGE_MULTIPLE',
  'PREVIEW_UPLOAD': 'PREVIEW_UPLOAD',
  'ADD_UPLOAD_POST': 'ADD_UPLOAD_POST',
  'UPLOAD_POST_REVIEW': 'UPLOAD_POST_REVIEW',
  'DOWNLOAD_UPLOAD': 'DOWNLOAD_UPLOAD',
};

export const resetData = () => {

}

export function Story(props) {
  const aeApps = checkIfAeApps();
  function captureImage() {
    // console.log('capture image')
    props.setExperience(EXPERIENCE.CAPTURE_IMAGE);
  }
  function recordVideo() {
    props.setExperience(EXPERIENCE.RECORD_VIDEO);
  }
  function uploadImage() {
    props.setExperience(EXPERIENCE.UPLOAD_IMAGE);
  }

  if (props.loading) {
    return (
      <div className='action-container'>
        <div className='spinner'></div>
      </div>
    )
  }
  if (!props.userInfo.email_address) {
    return (
      <div className='action-container'>
        <div className='auth-error'>
          Unauthorized User
        </div>
      </div>
    )
  }
  return (
    <div className='action-container'>
      <div className='top-left-logo'>
        <div className="logo">
          <img src={''} alt="" />
        </div>
      </div>
      <div className='title'>
        Enter the #REC Reunion Digital Booth
      </div>
      <div className='tagline'>
        Participate in the #REC Reunion community with your photos and videos.
      </div>
      <div className='action-items'>
        <div className='action-card first' onClick={captureImage}>
          <div className="top-line"></div>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-camera'></i>
            <div className='image camera'></div>
          </div>
          <div className='action-heading'>
            Capture a Photo
          </div>
          <div className='action-description camera'>
            Kick off your tale by capturing a photo here. Add some stickers and have some fun!
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn'>
              <div>Start</div>
            </div>
          </div>
        </div>
        {!aeApps && <div className='action-card second' onClick={recordVideo}>
          <div className="top-line"></div>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-video'></i>
            <div className='image video'></div>
          </div>
          <div className='action-heading'>
            Record a Video
          </div>
          <div className='action-description video'>
            Add some energy to your story with a video. Click below to create a video.
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn'>
              <div>Start</div>
            </div>
          </div>
        </div>}
        <div className='action-card third' onClick={uploadImage}>
          <div className="top-line"></div>
          <div className='action-pic'>
            <i className='icon-fk icon-fk-up-circle'></i>
            <div className='image upload'></div>
          </div>
          <div className='action-heading'>
            Upload Image(s)
          </div>
          <div className='action-description upload'>
            Already have the perfect Image(s) for your story. Upload up to 5 here.
          </div>
          <div className='action-btn-bg'>
            <div className='action-btn' >
              <div>Start</div>
            </div>
          </div>
        </div>
        {!aeApps &&
          <div className='action-card fourth'>
            <div className="top-line"></div>
            <div className='action-pic'>
              <i className='icon-fk icon-fk-twitter'></i>
              <div className='image twitter'></div>
            </div>
            <div className='action-heading'>
              Social Upload
            </div>
            <div className='action-description twitter'>
              Don't forget to share your video or image on Twitter and Instagram. Be sure to include <strong>#rect1997</strong> so everyone can enjoy it.
            </div>
            <div className='action-btn-bg'>
              <div className='action-btn' onClick={uploadImage}>
                <div>Start</div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className='footer'>
      </div>
    </div>
  )
}

const defaultUser = { full_name: '', first_name: '', last_name: '', email_address: '', preferred_username: '' };
const defaultCevent = { firstName: '', lastName: '', attendeeType: '', preferredName: '', geographicLocation: '', thumbnailUrl: '', registrationType: '' }
// const defaultUser = { full_name: 'Test User', first_name: 'Test', last_name: 'User', email_address: 'test@cisco.com' };
// const defaultCevent = { firstName: 'Test', lastName: 'User', attendeeType: 'clubCisco', preferredName: 'Test User', geographicLocation: 'amer', thumbnailUrl: 'https://storage.googleapis.com/cmsxcontent/impact21/images/6961394796543877121.png', registrationType: 'lasVegasFlow' };
// registrationType: 'lasVegasFlow' || 'digitalOnlyFlow'
export default function StoryFlow() {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const accessToken = url.searchParams.get('accessToken');

  const [experience, setExperience] = useState(null);
  const [user, setUser] = useState(defaultUser);
  const [loading, setLoading] = useState(false);
  const [ceventUser, setCeventUser] = useState(defaultCevent);
  useEffect(() => {
    const innerHeight = window.innerHeight;
    console.log('height', innerHeight);
    let themeConfig = getEmbedThemeInfo();
    const referrer = getUrlParams('referrer') || null;

    const aeApps = checkIfAeApps();
    const aeAppsToken = window.localStorage.getItem('playerToken') || null;
    const groupId = window.userConfig?.groupId || null;
    const eventId = window.userConfig?.eventId || null;
    const env = window.localStorage.getItem('fk-env') || null;

    setLoading(true);
    const param = qs.stringify(
      {
        "x-access-token": accessToken || aeAppsToken,
        aeApps, groupId, eventId, env,
        topic: themeConfig.ctag,
        referrer
      },
      { skipNulls: true }
    );
    const REACT_APP_ENV = process.env.REACT_APP_ENV || "local";
    const url = `https://${REACT_APP_ENV === 'prod' ? 'api' : 'dev.api'}.fanvoice.ai/cmsa/verifyToken?${param}`;
    fetch(url)
      .then(e => e.json())
      .then(userResponse => {
        if (userResponse && userResponse.active) {
          // console.log('fk okta user', userResponse);
          setUser(Object.assign({}, defaultUser, userResponse));
          // setUser(defaultUser)
          setLoading(false);
        } else if (aeApps) {
          const x = localStorage.getItem('body') || "{}";
          const localStorageData = JSON.parse(x);
          const full_name = _.get(localStorageData, 'Name', '');
          const email_address = _.get(localStorageData, 'Email', '');
          const department = _.get(localStorageData, 'Department', '');
          const hostel = _.get(localStorageData, 'Final year hostel', '');
          const group = _.get(localStorageData, 'Social Groups', '');
          const nickname = _.get(localStorageData, 'Nickname', '');
          setUser({ full_name, email_address, department, hostel, group, nickname });
          setLoading(false);
        }
      }).catch(err => {
        console.error('Error validating user', err);
        setUser(defaultUser);
        setLoading(false);
      })
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user.email_address) {
      const referrer = getUrlParams('referrer') || null;
      let themeConfig = getEmbedThemeInfo();
      const aeApps = checkIfAeApps();
      !aeApps && setLoading(true);
      !aeApps && fetch(`https://api.fanvoice.ai/cmsa/getUser?x-access-token=${accessToken}&email=${user.email_address}&topic=${themeConfig.ctag}&referrer=${referrer}`)
        .then(e => e.json())
        .then(({ data = [] }) => {
          if (data.length > 0 && data[0].user) {
            // console.log('fk okta cvent user', data);
            // console.log('fk okta cvent user instance', data[0].user);
            let attendeeType = _.get(data, '[0].user.attendeeType', '');
            let preferredType = Array.isArray(attendeeType) ? attendeeType[0] : attendeeType;
            setCeventUser(Object.assign({}, defaultCevent, data[0].user, { attendeeType: preferredType }));
            setLoading(false);
          } else {
            setCeventUser(defaultCevent);
            setLoading(false);
          }
        }).catch(err => {
          console.error('Error fetching cevent data user', err);
          setCeventUser(defaultCevent);
          setLoading(false);
        })
    }
    //eslint-disable-next-line
  }, [user])

  switch (experience) {
    case EXPERIENCE.CAPTURE_IMAGE:
      return <ImageCapture setExperience={setExperience} />;
    case EXPERIENCE.PREVIEW_IMAGE:
      return <ImagePreview setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.ADD_IMAGE_POST:
      return <AddImagePost setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.IMAGE_POST_REVIEW:
      return <ImagePostReview setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.DOWNLOAD_IMAGE:
      return <ImageDownload setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />
    case EXPERIENCE.RECORD_VIDEO:
      return <RecordVideo setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.PREVIEW_VIDEO:
      return <RecordVideoPreview setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.ADD_VIDEO_POST:
      return <AddVideoPost setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.VIDEO_POST_REVIEW:
      return <VideoPostReview setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.DOWNLOAD_VIDEO:
      return <VideoDownload setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.UPLOAD_IMAGE:
      return <UploadImage setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.UPLOAD_MULTIPLE:
      return <UploadImageMultiple setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.PREVIEW_UPLOAD:
      return <UploadPreview setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.ADD_UPLOAD_POST:
      return <AddUploadPost setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.UPLOAD_POST_REVIEW:
      return <UploadPostReview setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />;
    case EXPERIENCE.DOWNLOAD_UPLOAD:
      return <UploadDownload setExperience={setExperience} userInfo={user} token={accessToken} ceventUser={ceventUser} />
    case EXPERIENCE.CANCEL:
      return <Story setExperience={setExperience} userInfo={user} token={accessToken} loading={loading} ceventUser={ceventUser} />
    default:
      return <Story setExperience={setExperience} userInfo={user} token={accessToken} loading={loading} ceventUser={ceventUser} />
  }
}
