
export default class CircularArray {
  constructor(arr) {
    this.arr = arr || [];
    this.currentIndex = 0;
    this.length = arr.length;
  }
  next() {
    const i = this.currentIndex;
    const arr = this.arr;
    this.currentIndex = i < arr.length - 1 ? i + 1 : 0;
    return this.current();
  }
  prev() {
    const i = this.currentIndex;
    const arr = this.arr;
    this.currentIndex = i > 0 ? i - 1 : arr.length - 1;
    return this.current();
  }
  current() {
    return this.arr[this.currentIndex];
  }
  length() {
    return this.arr.length;
  }
}