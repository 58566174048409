import React, { Component } from "react";
import {getEmbedThemeInfo} from './../theme/Loader.js'
import { OktaAuth } from '@okta/okta-auth-js'


const OktaConfig = {
  'base_url': 'https://int-id.cisco.com',
  'client_id': '0oax0xjlqRrWQsEoA1d6'

};

// "https://int-id.cisco.com/oauth2/default"

var config = {
  // Required config
  issuer: `${OktaConfig.base_url}/oauth2/default`,
  clientId: `${OktaConfig.client_id}`,
  redirectUri: '/implicit/callback',
  // Use authorization_code flow
  responseType: 'code',
  scope: 'openid offline_access email',
  pkce: true
};
export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {user: null, cookie: null, fetchUser: false, err: null, tokens: {idToken: null, accessToken: null}};
    this.timer = null;
    this.authClient = new OktaAuth(config);
  }

  readCookie = () => {
    let cookie = this.getCookie('rfjwt');
    // console.log('fk widget read cookie: ', cookie)
    if(cookie) {
      this.setState({cookie}, this.stopInterval);
    }
  }

  stopInterval = () => {
    this.timer && clearInterval(this.timer);
    // if(!this.state.fetchUser) {
    //   console.log('fk widget api call to fk server')
    //   this.verifyToken();
    // }
  }


  validateToken = () => {
    fetch(`https://devapi.fankave.com/admin/oktaCheck`, {
      method: 'POST',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({token: 'sometoken', cookie: 'some cookie'})
    })
    .then(e => {
      if(e.status !== 200) {
        throw new Error(e.status)
      }
      return e.json();
    })
    .then(e => {
      console.log('fk widget fk server api response: ', e, );
      if(e.user.responseCode === "104") {
        console.error('Error: jwt expired');
        this.setState({fetchUser: true})
        return;
      }
      if(e.user) {
        this.setState({user: e.user, fetchUser: true})
      }
    })
    .catch(err => {
      console.error('Error in api response: ', err)
      this.setState({fetchUser: true})
    });
  }

  revokeToken = () => {
    console.log('in revoke token');
    // this.authClient.revokeAccessToken()
    this.authClient.token.revoke(this.state.tokenObj)
    .then(e => {
      console.log('after revoke token\n', 'promise response', e);
    })
    .catch(err => {
      console.error('revoking failed', err);
    })
  }

  renewToken = () => {
    console.log('in renewtoken\n', 'current accessToken: ', this.state.tokens.accessToken)
    this.authClient.token.renew({accessToken: this.state.tokens.accessToken, scopes: ['openid', 'email']})
    .then(e => {
      console.log('after renew token\n', 'promise response', e);
      this.setState(prevState => ({tokens: {accessToken: e.accessToken, idToken: prevState.tokens.idToken}, tokenObj: e}), this.revokeToken)
    })
    .catch(err => {
      console.error('renew token failed', err);
    })
  }

  getOktaTokens = () => {
    console.log('initiating getwithout prompt');
    this.authClient.token.getWithoutPrompt({
      responseType: 'id_token', // or array of types
      sessionToken: 'testSessionToken', // optional if the user has an existing Okta session
      scopes: ['offline_access', 'openid', 'email']
    })
    .then((res) => {
      console.log('Okta response', res);
      if(res.tokens) {
        const {accessToken: {accessToken}, idToken: {idToken}} = res.tokens;
        this.setState({tokens: {accessToken, idToken}}, this.renewToken);
      } else {
        console.log('Err: no tokens found')
      }
    })
    .catch((err) => {
      console.log('Error in getwithout prompt', err);
      this.setState({err: 'User not loggedin'})
      // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
    });
  }
  componentDidMount() {
    console.log('config for okta', config);

    // window.addEventListener("message", this.handleMessage, false);
    this.getOktaTokens();
    this.timer = setInterval(this.getOktaTokens, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    // window.removeEventListener("message", this.handleMessage);
    this.stopInterval();
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  verifyToken = () => {
    fetch(`https://devapi.fankave.com/admin/oktaCheck`, {
      method: 'POST',
      headers: {
        'Content-Type': "application/json"
      },
      body: JSON.stringify({token: this.state.tokens.idToken, cookie: this.state.cookie})
    })
    .then(e => {
      if(e.status !== 200) {
        throw new Error(e.status)
      }
      return e.json();
    })
    .then(e => {
      console.log('fk widget fk server api response: ', e, );
      if(e.user.responseCode === "104") {
        console.error('Error: jwt expired');
        this.setState({fetchUser: true})
        return;
      }
      if(e.user) {
        this.setState({user: e.user, fetchUser: true})
      }
    })
    .catch(err => {
      console.error('Error in api response: ', err)
      this.setState({fetchUser: true})
    });
  }

  handleMessage = (e) => {
    const theme = getEmbedThemeInfo();
    // console.log('in here', e.data, e.origin);
    // console.log('-----handleMessage-----', theme.rainfocusOrigin)
    if(e.origin===`${theme.rainfocusOrigin}`) {
      // console.log('receiving data from parent', e.data);
      this.setState({token: e.data.token}, this.verifyToken);
    }
  }
  openLink = () => {
    return window.open(`https://demofankave.com/impact/uploads/index.html?idToken=${this.state.tokens.idToken}`);
  }
  render() {
    if(this.state.tokens.idToken) {
      return (
        <div>
        <div>Successfully got tokens</div>
        <div>Widget renders here</div>
        <button style={{display: `${this.state.tokens.idToken ? 'block': 'none'}`}} onClick={this.openLink}>Open Fankave link</button>
        </div>
      )
    }
    if(this.state.user) {
      return (
        <div>
          <div>Welcome: {this.state.user.firstname + ' ' + this.state.user.lastname}</div>
          <div>Email: {this.state.user.email}</div>
          <div>Attendee ID: {this.state.user.attendeeId}</div>
          <div>Designation: {this.state.user.jobtitle}</div>
        </div>
      )
    }
    if(this.state.err) {
      return (
        <div className='err'>Error validating user</div>
      )
    }
    return (
      <div>js embed content before getting user info</div>
    )
  }
}
