import React from 'react'

import './blankScreens.css'

export const NoContent = () => {
  return (
    <div className="fk-gallery-no-content">
      <p className="fk-no-content-heading">{'No Content Available'}</p>
      <p className="fk-no-content-text">
        {`No results found. To create a new story, click "Share Stories" on the main page`}
      </p>
    </div>
  )
}
