import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import {
  useAnalyticsContext,
  AnalyticsProvider,
} from '../Providers/AnalyticsProvider'
import { getEmbedThemeInfo, getEmbedExperienceText } from '../theme/Loader'

// import './external-link.css'

const ExternalLink = () => {
  const btnTitle = getEmbedExperienceText('external-link', 'btn-title')
  const { createUser, dispatchAnalyticsEvent } = useAnalyticsContext()
  const [sessionToken, setSessionToken] = useState(
    sessionStorage.getItem('fk-impact-okta-auth') || ''
  )

  const openLink = (token) => {
    const themeConfig = getEmbedThemeInfo()
    token && dispatchAnalyticsEvent('CREATE_STORY_CLICK', { session: token })
    const url = _.get(themeConfig, 'external_link', '')
    console.log('fk okta auth themeConfig', themeConfig);
    console.log('fk okta auth url', url);
    // console.log(`${url}?accessToken=${token}`)
    return window.open(`${url}?accessToken=${token}`)
  }

  const onSessionChange = () => {
    const newSessiontoken = sessionStorage.getItem('fk-impact-okta-auth') || ''
    console.log('fk okta auth session updated: ', newSessiontoken)
    if (newSessiontoken) {
      createUser(newSessiontoken)
    }
    setSessionToken(newSessiontoken)
  }

  useEffect(() => {
    if (sessionToken) {
      createUser(sessionToken)
    }
    window.addEventListener('onImpactStorageChange', onSessionChange)
    return () => {
      window.removeEventListener('onImpactStorageChange', onSessionChange)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className={`external-link ${!!sessionToken ? '' : 'disabled'}`}
      onClick={!!sessionToken ? () => openLink(sessionToken) : () => { }}
    >
      {btnTitle}
    </div>
  )
}

export default () => {
  return (
    <AnalyticsProvider widget="create-story-link">
      <ExternalLink />
    </AnalyticsProvider>
  )
}
