const env =  window?.fkEmbedConfig?.env || 'dev'
const redirectUrls = {
  stage: "https://impact-stage.cisco.com/c/r/impact/myimpact/index.html",
  pStage: "https://impact-pstage.cisco.com/c/r/impact/myimpact/index.html",
  dev: "https://impact-dev.cisco.com/c/r/impact/myimpact/index.html"
}

export default {
  api: {
    local: {
      AUTH_CMS: 'https://api.fanvoice.ai/cmsa/',
      CONTENT_URL: 'https://api.fanvoice.ai/v1.0/cms/content/',
      CMS_X: 'https://api.fanvoice.ai/cmsx/',
      OKTA_URL: 'https://int-id.cisco.com',
      OKTA_CLIENT_ID: '0oax0xjlqRrWQsEoA1d6',
      OKTA_REDIRECT_URI: '/implicit/callback',
      V2URL: 'https://api.fanvoice.ai/fvm/content/posts'
    },
    dev: {
      AUTH_CMS: 'https://dev.api.fanvoice.ai/cmsa/',
      CONTENT_URL: 'https://api.fanvoice.ai/v1.0/cms/content/',
      CMS_X: 'https://api.fanvoice.ai/cmsx/',
      OKTA_URL: 'https://int-id.cisco.com',
      OKTA_CLIENT_ID: '0oax0xjlqRrWQsEoA1d6',
      OKTA_REDIRECT_URI: redirectUrls[env],
      V2URL: 'https://dev.api.fanvoice.ai/fvm/content/posts'
    },
    prod: {
      AUTH_CMS: 'https://api.fanvoice.ai/cmsa/',
      CONTENT_URL: 'https://api.fanvoice.ai/v1.0/cms/content/',
      CMS_X: 'https://api.fanvoice.ai/cmsx/',
      OKTA_URL: 'https://id.cisco.com',
      OKTA_CLIENT_ID: '0oa1f7qha1UW1t5Zk5d7',
      OKTA_REDIRECT_URI: '/myimpact',
      V2URL: 'https://api.fanvoice.ai/fvm/content/posts'
    }
    // prod: {
    //   AUTH_CMS: 'https://api.fanvoice.ai/cmsa/',
    //   CONTENT_URL: 'https://api.fanvoice.ai/v1.0/cms/content/',
    //   CMS_X: 'https://api.fanvoice.ai/cmsx/',
    //   OKTA_URL: 'https://int-id.cisco.com',
    //   OKTA_CLIENT_ID: '0oax0xjlqRrWQsEoA1d6',
    //   OKTA_REDIRECT_URI: '/implicit/callback'
    // },
  }
};
