import React, { useState } from "react";


function sanitize(string) {
  return string.replace(/[^a-z0-9#]/gi,'');
}

function sanitizeHash(string) {
  return string.replace(/[^a-z0-9]/gi,'');
}

export function Input(props) {
  const [input, setInput] = useState(props.value || '');
  // const [enter, setEnter] = useState(false);
  function handleKeyDown(e) {
    // console.log('handleKeyDown', e.key, e.key === 'Enter');
    if(e.key === 'Enter') {
      // console.log('enter pressed');
      props.getText(sanitizeHash(input));
      setInput('')
      // setEnter(true)
    }
    // setKey(e.key);
    // console.log(key);
  }
  const onChange = (e) => {
    // if(e.key === 'Enter') {
      // setInput('');
      // props.getText(e.target.value);
    // } else {
      setInput(sanitize(e.target.value));
      // props.getText(e.target.value);
    // }
  }
  return (
    <input type='text' onKeyDown={handleKeyDown} value={input} onChange={onChange} placeholder={props.placeholder}/>
  )
}
