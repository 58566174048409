import React, { useEffect, useState, useRef } from "react";

import { useOktaAuth } from "../hooks/useOktaAuth";
import {
  AnalyticsProvider,
  useAnalyticsContext,
} from "../Providers/AnalyticsProvider";

import ImpactGallery from "../component/ImpactGallery/ImpactGallery";
import ImpactGalleryMobile from "../component/ImpactGallery/ImpactGalleryMobile";
import { Loader } from "../component/ImpactGallery/Loader/Loader";
import { UnAuthorized } from "../component/ImpactGallery/BlankScreens/UnAuthorized";
import { getQueryParamsValues } from "../utils";
import { checkIfAeApps } from "../theme/Loader.js";

import "./impactGalleryExperience.css";

const ImpactGalleryExperience = () => {
  const existingToken = getQueryParamsValues(
    window?.location?.search || "",
    "accessToken"
  );
  const isAEApps = checkIfAeApps();
  const isEventbase = Boolean(existingToken);
  const [{ isAuthenticated, sessionData, isAuthenticating }, refreshToken] =
    useOktaAuth();
  const { createUser } = useAnalyticsContext();
  const galleryRef = useRef(null);
  const [[width, screenWidth], setSize] = useState([0, 0]);
  useEffect(() => {
    if (galleryRef.current) {
      setSize([galleryRef.current.clientWidth, window.innerWidth]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated)
      createUser(
        sessionStorage.getItem("fk-impact-okta-auth") || "",
        sessionData
      );
  }, [isAuthenticated, sessionData, createUser, width]);

  return (
    <div className={`fk-gallery-container fk-gallery-embed`} ref={galleryRef}>
      {isAuthenticating && <Loader />}
      {isAuthenticated ? (
        width ? (
          screenWidth > 768 && !isAEApps ? (
            <ImpactGallery
              width={width}
              height={740}
              refreshToken={refreshToken}
            />
          ) : (
            <ImpactGalleryMobile
              width={width}
              height={isEventbase ? "100%" : 740}
              refreshToken={refreshToken}
            />
          )
        ) : (
          <></>
        )
      ) : isAuthenticating ? (
        <></>
      ) : (
        <UnAuthorized />
      )}
      <div className="fk-footer-overlay" />
    </div>
  );
};

export default () => {
  return (
    <AnalyticsProvider widget="impact-gallery">
      <ImpactGalleryExperience />
    </AnalyticsProvider>
  );
};
