// import bg1 from './../../assets/impact21/images/bgs/09.jpg'
// import bg2 from './../../assets/impact21/images/bgs/10.jpg'
import bg3 from './../../assets/impact21/images/bgs/11.jpg'
import bg4 from './../../assets/impact21/images/bgs/12.png'
import bg5 from './../../assets/impact21/images/bgs/13.png'
import bg6 from './../../assets/impact21/images/bgs/14.jpg'

// common
import st1 from './../../assets/impact21/images/stickers/23.png'
import st2 from './../../assets/impact21/images/stickers/24.png'
import st3 from './../../assets/impact21/images/stickers/25.png'
import st4 from './../../assets/impact21/images/stickers/26.png'
import st5 from './../../assets/impact21/images/stickers/27.png'
import st6 from './../../assets/impact21/images/stickers/28.png'
import st7 from './../../assets/impact21/images/stickers/29.png'

//geo
import st8 from './../../assets/impact21/images/stickers/30.png'
import st9 from './../../assets/impact21/images/stickers/31.png'
import st10 from './../../assets/impact21/images/stickers/32.png'

//role-based
// import st11 from './../../assets/impact21/images/stickers/33.png'
// import st12 from './../../assets/impact21/images/stickers/34.png'
// import st15 from './../../assets/impact21/images/stickers/15.png'
// import st16 from './../../assets/impact21/images/stickers/16.png'
// import st17 from './../../assets/impact21/images/stickers/17.png'

//images sliders icons
import nextIcon from './../../assets/impact21/images/next.png';
import prevIcon from './../../assets/impact21/images/prev.png';

import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import axios from 'axios';

// import { isMobile } from "react-device-detect";
import { videoConstraints, canvasConstraints } from './ImageRecorder';

import corner from './../../assets/impact21/images/corner.png';
import deleteIcon from './../../assets/impact21/images/delete.png';
import rotate from './../../assets/impact21/images/rotate.png';
import scale from './../../assets/impact21/images/scale.png';

let commonStickers = [
  { name: 'came_for_gossip', url: st1 },
  { name: 'reunion_crasher', url: st2 },
  { name: 'still_looking_good', url: st3 },
  { name: 'still_together', url: st4 },
  { name: 'used_to_be_cool', url: st5 },
  { name: 'reunited', url: st6 },
  { name: 'got_old', url: st7 },
  { name: 'old_enough', url: st8 },
  { name: 'i_dont_recognise', url: st9 },
  { name: 'trouble_maker', url: st10 },
  // { name: 'bw_came_for_gossip', url: st11 },
  // { name: 'bw_reunion_crasher', url: st12 },
  // { name: 'inclusiveFuture', url: st3 },
  // { name: 'betterTogether', url: st5 },
  // { name: 'ciscoIsOne', url: st7 },
  // { name: 'weDidIt', url: st8 },
  // { name: 'cantStop', url: st9 },
  // { name: 'unstoppable', url: st9_1 }
];
let geoStickers = [
  // { name: 'amer', url: st10 },  // AMERICAS || AMER
  // { name: 'emea', url: st11 },      // emea
  // { name: 'apjc', url: st12 }       //apjc
];
let roleBasedStickers = [
  // { name: 'achiever', url: st13 },          // done
  // { name: 'salesChampion', url: st14 },     // done
  // { name: 'managerExcellence', url: st15 }, // done
  // { name: 'clubCisco', url: st16 },         // done
  // { name: 'fame', url: st17 },              // "Hall-of-Fame": "HoF Alumni",
];
// let stickers = [];

// returnStickerSet();
let backgrounds = [
  // { name: 'indoor1', url: bg1 },
  // { name: 'orange', url: bg1 },
  // { name: 'classroom', url: bg2 },
  { name: 'new_college', url: bg3 },
  { name: 'hostel', url: bg4 },
  { name: 'opal_hostel', url: bg5 },
  { name: 'clock_tower', url: bg6 }
  // { name: 'vegas', url: bg6 }
];

const toDataURLPromise = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export const toDataURL = (url, callback) => {
  const xhRequest = new XMLHttpRequest();
  xhRequest.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhRequest.response);
  };
  xhRequest.open('GET', url);
  xhRequest.responseType = 'blob';
  xhRequest.send();
};

function loadImage(data) {
  const new_blob = new Blob([data], { type: 'image/png' });
  const url = URL.createObjectURL(new_blob);
  const img = document.createElement('img');
  img.crossOrigin = 'Anonymous'
  img.src = url;
  return new Promise((res, rej) => {
    img.onload = () => res(img);
    img.onerror = rej;
  });
}

export const getError = (error) => {
  const decodedString = String.fromCharCode.apply(
    null,
    new Uint8Array(error.response.data)
  );
  const obj = JSON.parse(decodedString);
  return obj.errors.map((e) => e.title).join();
};

export const getBackgroundThirdParty = async (fg) => {

  const imageBlob = await fetch(fg.src).then(res => res.blob());
  const formData = new FormData();
  formData.append("image_file", imageBlob);
  formData.append("size", "auto");
  return axios({
    data: formData,
    url: 'https://api.remove.bg/v1.0/removebg',
    method: 'POST',
    headers: {
      'X-Api-Key': 'ZUm49JK4PNAchnmLM1x3VrM8',
    },
    responseType: 'arraybuffer',
  })
    .then((resp) => {
      return loadImage(resp.data)
    })
    .then((img) => {
      return ({ url: img.src })
    })
    .catch((err) => ({ error: getError(err) }));
};

const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
// aug 29 16:44:59 => 1661791499000
const aug29epoch = 1661791499000;
const d = new Date().valueOf();
export const StickerSelect = (props) => {
  const returnStickerSet = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let geoParam = urlParams.get('geo') || props.ceventUser.geographicLocation.slice(0, 4).toLowerCase();
    let roleParam = urlParams.get('role') || props.ceventUser.attendeeType.toLowerCase();
    const geoSticks = geoStickers.filter(e => e.name.toLowerCase() === geoParam.toLowerCase());
    const roleSticks = roleBasedStickers.filter(e => e.name.toLocaleLowerCase() === roleParam.toLowerCase()).filter(() => aug29epoch - d <= -1);
    return commonStickers.filter((stick, i) => {
      if (aug29epoch - d <= -1) {
        return stick;
      } else if (i === 7) {
        return false;
      } else {
        return stick;
      }
    }).concat(geoSticks).concat(roleSticks);
  }
  const stickers = returnStickerSet();
  const fabricCanvas = useRef(null);
  const photoCanvas = useRef(null);
  const [clippedImage, setClippedImage] = useState('');
  const [currentBackground, setCurrentBackground] = useState(-1);
  const [resolvedStickers, setResolvedStickers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStickers, setSelectedStickers] = useState([]);
  const [selectedBg, setSelectedBg] = useState([]);

  const loadUserImage = () => {
    const image = new Image();
    image.crossOrigin = 'Anonymous'
    image.src = props.photo;
    return image; // returns a new Image Object
  };

  const getImage = (image) => {
    const rotateImg = document.createElement('img');
    rotateImg.crossOrigin = 'Anonymous'
    rotateImg.src = image;
    return rotateImg; // returns a html image element
  };

  const transformOverlayToNewBg = () => {
    /*
    1. get all stickers and store in variable
    2. remove stickers from canvas
    3. take a snapshot of current canvas ( changed background + clipped image overlay)
    4. set this as the new canvas background
    5. remove previous overlay
    6. add stickers back
    7. render again
    */
    const currentStickers = fabricCanvas.current.getObjects();
    fabricCanvas.current.remove(...fabricCanvas.current.getObjects());
    const newBgUrl = fabricCanvas.current.toDataURL();
    setBackgroundFromURL(newBgUrl);
    fabricCanvas.current.setOverlayImage(null, () => { });
    for (let i = 0; i < currentStickers.length; i++) {
      fabricCanvas.current.add(currentStickers[i]);
    }
    fabricCanvas.current.requestRenderAll();
  }

  const setOverLay = (imgURL) => {
    const jeeFaceFilterCanvas = document.getElementById(`imageCanvas-${props.index}`);
    const canvasWidth = jeeFaceFilterCanvas.width;
    const canvasHeight = jeeFaceFilterCanvas.height;
    fabric.Image.fromURL(
      imgURL,
      (photoImage) => {
        const scaleX = canvasWidth / photoImage.width;
        const scaleY = canvasHeight / photoImage.height;
        fabricCanvas.current.setOverlayImage(photoImage, () => {
          fabricCanvas.current.requestRenderAll();
          setTimeout(transformOverlayToNewBg, 100);
        }, { width: photoCanvas.current.width, height: photoCanvas.current.height, scaleX, scaleY })
      },
      { crossOrigin: 'Anonymous' }
    );
  };

  const setBackgroundFromURL = (dataUrl) => {
    const jeeFaceFilterCanvas = document.getElementById(`imageCanvas-${props.index}`);
    const canvasWidth = jeeFaceFilterCanvas.width;
    const canvasHeight = jeeFaceFilterCanvas.height;
    fabric.Image.fromURL(
      dataUrl,
      (photoImage) => {
        const scaleX = canvasWidth / photoImage.width;
        const scaleY = canvasHeight / photoImage.height;
        fabricCanvas.current.setBackgroundImage(photoImage, () => {
          fabricCanvas.current.requestRenderAll();
        }, { scaleX, scaleY })
      },
      { crossOrigin: 'Anonymous' }
    );
  };

  const deleteObject = (eventData, transform) => {
    // console.log('transform', transform)
    // console.log('eventData', eventData);
    const target = transform.target;
    const canvas = target.canvas;
    setSelectedStickers((s) => (removeItemOnce(s, target.id)))
    // setSelectedStickers(newStickers);
    // let newStickers = removeItemOnce(selectedStickers, target.id)
    // console.log('new stickers', newStickers);
    // setSelectedStickers(newStickers);
    // console.log('target id', target.id);
    // console.log('target id 2 ', canvas.id);
    canvas.remove(target);
    canvas.requestRenderAll();
  };

  const renderIcon = (icon) => {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      const size = 40;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  }

  const initFabricCanvas = (imgURL, width, height, fg) => {
    const fabricElement = document.getElementById(`fabricCanvas-${props.index}`);
    const cssWidth = fabricElement.style.width;
    const cssHeight = fabricElement.style.height;

    fabricCanvas.current = new fabric.Canvas(`fabricCanvas-${props.index}`, {
      width: width,
      height: height,
      hoverCursor: 'pointer',
      selection: true,
      targetFindTolerance: 2,
      enableRetinaScaling: false,
      controlsAboveOverlay: true
    });
    fabricCanvas.current.setDimensions(
      { width: cssWidth, height: cssHeight },
      { cssOnly: true }
    );

    // Initialize sticker border controls
    const controlsUtils = fabric.controlsUtils;
    const scaleStyleHandler = controlsUtils.scaleCursorStyleHandler,
      scalingEqually = controlsUtils.scalingEqually,
      objectControls = fabric.Object.prototype.controls;

    objectControls.tl = new fabric.Control({
      x: -0.56,
      y: -0.56,
      actionHandler: deleteObject,
      cursorStyle: 'pointer',
      render: renderIcon(getImage(deleteIcon)),
      cornerSize: 30,
      sizeX: 40,
      sizeY: 40,
      mouseUpHandler: deleteObject,
    });

    objectControls.tr = new fabric.Control({
      x: 0.57,
      y: -0.57,
      withConnection: false,
      actionName: 'rotate',
      cursorStyle: 'pointer',
      render: renderIcon(getImage(rotate)),
      cornerSize: 24,
      actionHandler: controlsUtils.rotationWithSnapping,
    });

    objectControls.br = new fabric.Control({
      x: 0.57,
      y: 0.57,
      cursorStyleHandler: scaleStyleHandler,
      actionHandler: scalingEqually,
      render: renderIcon(getImage(scale)),
      cornerSize: 24,
    });

    objectControls.bl = new fabric.Control({
      x: -0.57,
      y: 0.57,
      cursorStyleHandler: scaleStyleHandler,
      actionHandler: scalingEqually,
      render: renderIcon(getImage(corner)),
      cornerSize: 24,
    });
    setBackgroundFromURL(imgURL);
  };

  const loadOnFabricCanvas = (image) => {
    image.addEventListener(
      'load',
      () => {
        photoCanvas.current = document.createElement('canvas');
        photoCanvas.current.width = videoConstraints.width || canvasConstraints.width;
        photoCanvas.current.height = videoConstraints.height || canvasConstraints.height;

        const ctx = photoCanvas.current.getContext('2d');
        ctx.drawImage(image, 0, 0, photoCanvas.current.width, photoCanvas.current.height) //, 0, 0, 320, 480);
        ctx.scale(2, 2);
        initFabricCanvas(
          photoCanvas.current.toDataURL(),
          photoCanvas.current.width,
          photoCanvas.current.height
        );
      },
      false
    );
  };

  const showPhoto = () => {
    const canvas = document.getElementById(`imageCanvas-${props.index}`);
    canvas.style.display = 'none';
    const image = loadUserImage(canvas);
    loadOnFabricCanvas(image);
  };

  const removeAllSticker = () => {
    setSelectedStickers([]);
    fabricCanvas.current.remove(...fabricCanvas.current.getObjects());
  };

  const addSticker = (selectedSticker, width, height, options = {}, name) => {
    fabric.Image.fromURL(selectedSticker, (sticker) => {
      sticker
        .set({
          left: Math.floor(Math.random() * 200),
          top: Math.floor(Math.random() * 100),
          angle: 0,
          cornerColor: '#E6F7E2',
          strokeWidth: 12,
          cornerSize: 23,
          cornerStrokeColor: '#E6F7E2',
          cornerStyle: 'rect',
          cornerWidth: 10,
          borderScaleFactor: 8,
          borderColor: '#E6F7E2',
          touchCornerSize: 40,
          borderDashArray: [10],
          id: name,
          ...options,
        })
        .setControlsVisibility({
          bl: true,
          br: true,
          tl: true,
          tr: true,
          mb: false,
          ml: false,
          mr: false,
          mt: false,
          mtr: false,
        })
        .scale(1)
        .setCoords();

      sticker.hasControls = true;
      sticker.hasBorders = true;
      sticker.scaleToHeight(200);
      sticker.scaleToWidth(200);

      fabricCanvas.current.add(sticker);
      fabricCanvas.current.requestRenderAll();
    });
  };

  const handleStickerAdd = async (index) => {
    if (fabricCanvas.current) {
      let newStickers = selectedStickers.concat(resolvedStickers[index].name)
      setSelectedStickers(newStickers);
      addSticker(resolvedStickers[index].url, 200, 200, {}, resolvedStickers[index].name);
    }
  };

  const handleChangeBackground = async (index) => {
    if (fabricCanvas.current) {
      if (index === currentBackground) return;
      setLoading(true);
      setCurrentBackground(index);
      if (index < 0) {
        setBackgroundFromURL(photoCanvas.current.toDataURL());
        setLoading(false);
        return;
      }
      try {
        let clippedUrl = '';
        toDataURL(backgrounds[index].url, async (bgSrcUrl) => {
          const fg = loadUserImage(fabricCanvas.current);
          let error;
          if (!clippedImage) {
            const { url, error: bgError } = await getBackgroundThirdParty(fg);
            setClippedImage(url);
            clippedUrl = url;
            error = bgError;
          }
          if (!error) {
            setBackgroundFromURL(bgSrcUrl);
            setSelectedBg([backgrounds[index].name]);
            setOverLay(clippedImage || clippedUrl);
          }
          setLoading(false);
        });
      } catch (e) {
        console.error('fk error changing background: ', e);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    Promise.allSettled(stickers.map(e => toDataURLPromise(e.url)))
      .then(allResolvedPromises => {
        const newStickers = allResolvedPromises.map((promiseStatus, index) => ({ name: stickers[index].name, url: promiseStatus.value }))
        setResolvedStickers(newStickers);
      })
      .catch(err => console.error('error setting stickers', err));
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (fabricCanvas.current) {
      return;
    }
    showPhoto();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Used for removing sticker controls before performing a canvasToDataUrl
    if (props.hideControls && fabricCanvas.current) {
      fabricCanvas.current.discardActiveObject();
      fabricCanvas.current.renderAll();
      props.getMediaAttrs({ stickers: selectedStickers, bg: selectedBg })
    }
    // eslint-disable-next-line
  }, [props.hideControls])

  return (
    <div className={`sticker-container ${props.display ? 'show-stickers' : 'hide-stickers'}`}>
      <div className={`spinner ${loading ? 'show' : 'hide'}`}></div>
      <div className='canvas-container'>
        {props.uploadUrls?.length > 1 && <div className="slider-icon">
          <div className="prev" onClick={props.prev}>
            <img className='prev-icon' src={prevIcon} alt='prev' />
          </div>
          <div className='next' onClick={props.next}>
            <img className='next-icon' src={nextIcon} alt='next' />
          </div>
        </div>}
        <canvas width={videoConstraints.width || canvasConstraints.width} height={videoConstraints.height || canvasConstraints.height} className="fabric" id={`fabricCanvas-${props.index}`} />
        <canvas width={videoConstraints.width || canvasConstraints.width} height={videoConstraints.height || canvasConstraints.height} className="canvas" id={`imageCanvas-${props.index}`} />
      </div>
      <div className='select-sticker-container'>
        <div className='heading'>
          <div className="heading-title">
            Add Stickers
          </div>
          <div className="clear-btn" onClick={() => removeAllSticker()}>
            <span>Clear</span>
          </div>
        </div>
        <div className='sub-heading'>You can select multiple stickers</div>
        <div className='stickers-preview'>
          {resolvedStickers.map((sticker, index) => (
            <div className='sticker' key={sticker.url}
              onClick={() => handleStickerAdd(index)}
              style={{ 'backgroundImage': `url(${sticker.url})` }}>
            </div>
          ))}
        </div>
      </div>
      <div className='select-bg-container'>
        <div className='heading'>
          <div className="heading-title">
            Add Background
          </div>
          <div className="clear-btn" onClick={() => handleChangeBackground(-1)}>
            <span>Clear</span>
          </div>
        </div>
        <div className='sub-heading'>Select 1 per image</div>
        <div className='bgs-preview'>
          {backgrounds.map((bg, index) => (
            <div className='bg' key={bg.url}
              onClick={() => handleChangeBackground(index)}
              style={{ 'backgroundImage': `url(${bg.url})` }}>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
