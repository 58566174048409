import qs from "qs";
import lodash from "lodash";

import Config from "./Config.js";
import Post from "./model/Post.js";
import PostFlat from "./model/PostFlat.js";
import { checkIfAeApps, getEmbedThemeInfo } from "./theme/Loader.js";
import { UnauthorizedError } from "./errors";
import { getQueryParamsValues, getUrlParams } from "./utils.js";
import { transform } from "./model/v2tov2.js";

const REACT_APP_ENV = process.env.REACT_APP_ENV || "local";
const appConfig = Config.api[REACT_APP_ENV];

function get(url, success, failure, options = {}) {
  return fetch(url, options)
    .then((response) => {
      if (response.status === 401) {
        throw new UnauthorizedError("User in not Authorized");
      }
      if (response.status === 204) {
        throw new Error("No more content");
      }
      return response;
    })
    .then((response) =>
      response.text().then((text) => (text ? JSON.parse(text) : []))
    )
    .then(
      (payload) => success(payload),
      (error) => {
        if (failure) {
          failure(error);
        }
      }
    );
}

function callApi(url, success, failure, options = {}) {
  return fetch(url, options)
    .then((response) => {
      if (response.status === 401) {
        throw new UnauthorizedError("User in not Authorized");
      }
      if (response.status === 204) {
        throw new Error("No more content");
      }
      return response;
    })
    .then(
      (payload) => success(payload),
      (error) => {
        if (failure) {
          failure(error);
        }
      }
    );
}

export const getFeatured = (success, failure, filter, limit) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    contentType: themeConfig.contentType,
    ctag: themeConfig.ctag,
    filter: filter || themeConfig.filter,
    limit,
  });
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  get(
    url,
    (result) => {
      let res = lodash.filter(result, (e) =>
        lodash.get(e, "content.sections[0].embed.media[0].url", null)
      );
      removeBrokenMedia(res, (values) => {
        if (themeConfig.videoOnly) {
          let posts = values
            .map((r) => new Post(r))
            .filter((post) => post.isVideoMedia());
          success(posts);
        } else {
          let posts = values.map((r) => new Post(r)); //.filter(post => post.isVideoMedia());
          success(posts);
        }
      });
    },
    failure
  );
};

export const getPhotos = (success, failure) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    contentType: "photo",
    ctag: themeConfig.ctag,
    filter: "approved",
  });
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  get(
    url,
    (result) => {
      let res = lodash.filter(result, (e) =>
        lodash.get(e, "content.sections[0].embed.media[0].url", null)
      );
      removeBrokenMedia(res, (values) => {
        let posts = values.map((r) => new Post(r));
        success(posts);
      });
    },
    failure
  );
};

export const getAll = (success, failure) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    contentType: themeConfig.contentType,
    ctag: themeConfig.ctag,
  });
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  get(
    url,
    (result) => {
      let res = lodash.filter(result, (e) =>
        lodash.get(e, "content.sections[0].embed.media[0].url", null)
      );
      removeBrokenMedia(res, (values) => {
        let posts = values.map((r) => new Post(r));
        success(posts);
      });
    },
    failure
  );
};

export const getPost = (postId, success, failure) => {
  let url = `${appConfig.CONTENT_URL}social/${postId}`;
  get(
    url,
    (result) => {
      if (result.length === 1) {
        success(new Post(result[0]));
      }
    },
    failure
  );
};

export const getContent = async (contentType = null, filter = "", from) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify(
    {
      contentType: contentType,
      topic: themeConfig.ctag,
      limit: themeConfig.initialCardCount,
      filter: filter,
      from,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  try {
    let result = await fetch(url);
    let jsonResult = await result.json();
    let posts = jsonResult.map((post) => new PostFlat(post));
    return posts;
  } catch (err) {
    console.error("Error fetching result", err);
    return [];
  }
};

export const getContentFlat = async (contentType = null, filter = "", from) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify(
    {
      contentType: contentType,
      topic: themeConfig.ctag,
      limit: themeConfig.initialCardCount,
      filter: filter,
      format: "flat",
      from,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social?${params}`;
  try {
    let result = await fetch(url);
    let jsonResult = await result.json();
    let posts = jsonResult.map((post) => new PostFlat(post));
    return posts;
  } catch (err) {
    console.error("Error fetching result", err);
    return [];
  }
};

export const getLeaderboard = async (category = null) => {
  let themeConfig = getEmbedThemeInfo();
  let params = qs.stringify({
    ctag: themeConfig.ctag,
  });
  let url = category
    ? `${appConfig.CMS_X}leaderboard/social?${params}&limit=10&invert=true`
    : `${appConfig.CMS_X}leaderboard/social?${params}&limit=10`;
  try {
    let result = await fetch(url).then((res) => res.json());
    return result;
  } catch (err) {
    console.error("Error fetching result", err);
    return [];
  }
};

function isNonMediaData(datum) {
  return datum.type === "text";
}

export const removeBrokenMedia = (data, done) => {
  // console.log(data);
  let groupedData = lodash.groupBy(data, (d) => isNonMediaData(d));
  let valid = groupedData.true || [];
  let mediaData = groupedData.false || [];

  let urlPromises = mediaData.map((d) => {
    // console.log(d.content.sections[0].embed.media[0].url)
    let mediaUrl = lodash.get(d, "content.sections[0].embed.media[0].url", "");
    return fetch(mediaUrl, { method: "GET" })
      .then((response) => response)
      .catch((err) => err);
  });

  Promise.all(urlPromises).then((responses) => {
    responses.forEach((response, i) => {
      if (response.status === 200) {
        valid.push(mediaData[i]);
      }
    });
    valid = lodash.orderBy(valid, "createdAt", "desc");
    done(valid);
  });
};

export const getHighlights = (success, failure, params) => {
  let themeConfig = getEmbedThemeInfo();
  // console.log('themeConfig', themeConfig.ctag, themeConfig.version)
  let param = qs.stringify(
    { ...params, ctag: themeConfig.ctag },
    { skipNulls: true }
  );
  let url;
  let options;
  if(themeConfig.version && themeConfig.version.toLowerCase() === 'v2') {
    // console.log('params', params)
    url = params.anchor ? `${appConfig.V2URL}?topic=${themeConfig.ctag}&limit=${params.limit}&moderation=${params.filter}&anchor=${params.anchor}&temporality=${params.temporality}`
    : `${appConfig.V2URL}?topic=${themeConfig.ctag}&limit=${params.limit}&moderation=${params.filter}`;
    // console.log('url', url)
    const token = themeConfig.token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    get(
      url,
      (result) => {
        // const formattedData = result.posts.filter(post => post.contentType !== 'video').map(post => transform(post))
        const formattedData = result.posts.map(post => transform(post))
        // let nonVideo = lodash.filter(
        //   formattedData,
        //   (e) => lodash.get(e, "type", null) !== "video"
        // );
        let posts = formattedData.map((r) => new PostFlat(r))
        success(posts)
        // removeBrokenMedia(formattedData, (values) => {
        //   // console.log('jlskjlskjsl', JSON.stringify(values, null, 1))
        //   let posts = values.map((r) => new PostFlat(r));
        //   success(posts);
        // });
      },
      failure,
      options
    ); 

  } else {
    url = `${appConfig.CONTENT_URL}social?${param}`;
    options = {
      method: 'GET',
      redirect: 'follow'
    };
    get(
      url,
      (result) => {
        let nonVideo = lodash.filter(
          result,
          (e) => lodash.get(e, "type", null) !== "video"
        );
        removeBrokenMedia(nonVideo, (values) => {
          let posts = values.map((r) => new PostFlat(r));
          success(posts);
        });
      },
      failure,
      options
    );
  }
};

export const getGallery = (success, failure, params) => {
  let themeConfig = getEmbedThemeInfo();
  let param = qs.stringify(
    {
      ...params,
      ctag: themeConfig.galleryctag
        ? themeConfig.galleryctag
        : themeConfig.ctag,
    },
    { skipNulls: true }
  );
  let url;
  if(themeConfig.version && themeConfig.version === 'v2') {
    const ctag = themeConfig.galleryctag
    ? themeConfig.galleryctag
    : themeConfig.ctag;
    url = params.anchor ? `${appConfig.V2URL}?topic=${ctag}&limit=${params.limit}&moderation=${params.filter}&responseType=image&anchor=${params.anchor}&temporality=${params.temporality}`
    : `${appConfig.V2URL}?topic=${ctag}&limit=${params.limit}&moderation=${params.filter}&responseType=image`
    const token = themeConfig.token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    let options = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    get(
      url,
      (result) => {
        const formattedData = result.posts.map(post => transform(post))
        let posts = formattedData
          .filter((e) => e.type === "image")
          .map((json) => new PostFlat(json));
        success(posts);
      },
      failure,
      options
    );
  } else {
    url = `${appConfig.CONTENT_URL}social?${param}`;
    get(
      url,
      (result) => {
        let posts = result
          .filter((e) => e.type === "image")
          .map((json) => new PostFlat(json));
        success(posts);
      },
      failure
    );
  }
};

export const getImpactGallery = (success, failure, params) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || null;
  const referrer = getUrlParams("referrer") || null;
  const env = window.localStorage.getItem("fk-env") || null;
  const themeConfig = getEmbedThemeInfo();

  const aeApps = checkIfAeApps();
  const aeAppsToken = window.localStorage.getItem("playerToken") || null;
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;

  const param = qs.stringify(
    {
      "x-access-token": oktaToken || aeAppsToken,
      topic: themeConfig.ctag,
      referrer,
      limit: themeConfig.gallery.apiLimit,
      category: "approved",
      aeApps,
      groupId,
      eventId,
      env,
      ...params,
    },
    { skipNulls: true }
  );
  const headers = new Headers();
  headers.append("BypassCache", true);
  const url = `${appConfig.AUTH_CMS}content/social?${param}`;
  get(
    url,
    ({ data = {} }) => {
      try {
        const posts = (data || []).map((json) => new PostFlat(json));
        success(posts);
      } catch (err) {
        success([]);
      }
    },
    failure,
    { headers }
  );
};

export const getImpactGalleryNoAuth = (success, failure, params) => {
  const referrer = getUrlParams("referrer") || null;
  const env = window.localStorage.getItem("fk-env") || null;
  const themeConfig = getEmbedThemeInfo();

  const aeApps = checkIfAeApps();
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;

  const param = qs.stringify(
    {
      topic: themeConfig.ctag,
      referrer,
      // backup for failing location api search
      limit: params.location ? 100 : themeConfig.gallery.apiLimit,
      category: "approved",
      aeApps,
      groupId,
      eventId,
      env,
      ...params,
    },
    { skipNulls: true }
  );
  const headers = new Headers();
  headers.append("BypassCache", true);
  const url = `${appConfig.CONTENT_URL}social?${param}`;
  get(
    url,
    (data) => {
      try {
        const posts = (data || []).map((json) => new PostFlat(json));
        // backup for failing api search location
        if (params.location) {
          const correct = posts.filter(e => params.location.split(',').includes(e.author?.location))
          // console.log('correct', correct)
          success(correct)
          return;
        }
        success(posts);
      } catch (err) {
        success([]);
      }
    },
    failure,
    { headers }
  );
};

export const getImpactSearch = (success, failure) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || null;
  const referrer = getUrlParams("referrer") || null;
  const env = window.localStorage.getItem("fk-env") || null;
  const themeConfig = getEmbedThemeInfo();

  const aeApps = checkIfAeApps();
  const aeAppsToken = window.localStorage.getItem("playerToken") || null;
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;

  const param = qs.stringify(
    {
      "x-access-token": oktaToken || aeAppsToken,
      aeApps,
      groupId,
      eventId,
      env,
      topic: themeConfig.ctag,
      referrer,
    },
    { skipNulls: true }
  );
  const url = `${appConfig.AUTH_CMS}stories/${themeConfig.ctag}/suggestions?${param}`;
  get(
    url,
    ({ data: result = {} }) => {
      const { data = {} } = result;
      success(data);
    },
    failure
  );
};

export const getImpactSearchNoAuth = (success, failure) => {
  const referrer = getUrlParams("referrer") || null;
  const env = window.localStorage.getItem("fk-env") || null;
  const themeConfig = getEmbedThemeInfo();

  const aeApps = checkIfAeApps();
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;

  const param = qs.stringify(
    {
      // "x-access-token": oktaToken || aeAppsToken,
      aeApps,
      groupId,
      eventId,
      env,
      topic: themeConfig.ctag,
      referrer,
    },
    { skipNulls: true }
  );
  const url = `${appConfig.CMS_X}stories/${themeConfig.ctag}/suggestions?${param}`;
  get(
    url,
    ({ data: result = {} }) => {
      const { data = {} } = result;
      success(data);
    },
    failure
  );
};

export const getImpactUserMetrics = (success, failure, params) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || null;
  const oktaEmail = sessionStorage.getItem("fk-impact-okta-email") || "";
  const referrer = getUrlParams("referrer") || null;
  const themeConfig = getEmbedThemeInfo();

  const aeApps = checkIfAeApps();
  const aeAppsToken = window.localStorage.getItem("playerToken") || null;
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;
  const env = window.localStorage.getItem("fk-env") || null;

  let aeAppsUser = null;
  if (aeApps) {
    const aeAppsUserData = localStorage.getItem("body") || "{}";
    const localStorageData = JSON.parse(aeAppsUserData);
    aeAppsUser = lodash.get(localStorageData, "Email", "");
  }
  const uid =
    aeAppsUser ||
    oktaEmail ||
    getQueryParamsValues(window?.location?.search || "", "user") ||
    "testuser@cisco.com";
  const headers = new Headers();
  headers.append("BypassCache", true);
  const param = qs.stringify(
    {
      "x-access-token": oktaToken || aeAppsToken,
      aeApps,
      groupId,
      eventId,
      referrer,
      topic: themeConfig.ctag,
      env,
      uid,
      ...params,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.AUTH_CMS}content/social/likes?${param}`;
  get(
    url,
    (data = {}) => {
      success(data);
    },
    failure,
    { headers }
  );
};

export const getImpactUserMetricsNoAuth = (success, failure, params) => {
  const oktaEmail = sessionStorage.getItem("fk-impact-okta-email") || "";
  const referrer = getUrlParams("referrer") || null;
  const themeConfig = getEmbedThemeInfo();

  const aeApps = checkIfAeApps();
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;
  const env = window.localStorage.getItem("fk-env") || null;

  let aeAppsUser = null;
  if (aeApps) {
    const aeAppsUserData = localStorage.getItem("body") || "{}";
    const localStorageData = JSON.parse(aeAppsUserData);
    aeAppsUser = lodash.get(localStorageData, "Email", "");
  }
  const uid =
    aeAppsUser ||
    oktaEmail ||
    getQueryParamsValues(window?.location?.search || "", "user") ||
    "testuser@cisco.com";
  const headers = new Headers();
  headers.append("BypassCache", true);
  const param = qs.stringify(
    {
      aeApps,
      groupId,
      eventId,
      referrer,
      topic: themeConfig.ctag,
      env,
      uid,
      ...params,
    },
    { skipNulls: true }
  );
  let url = `${appConfig.CONTENT_URL}social/likes?${param}`;
  get(
    url,
    (data = {}) => {
      success(data);
    },
    failure,
    { headers }
  );
};

export const updateUserMetrics = (success, failure, params) => {
  const oktaToken = sessionStorage.getItem("fk-impact-okta-auth") || null;
  const oktaEmail = sessionStorage.getItem("fk-impact-okta-email") || "";
  const themeConfig = getEmbedThemeInfo();
  const referrer = getUrlParams("referrer") || null;

  const aeApps = checkIfAeApps();
  const aeAppsToken = window.localStorage.getItem("playerToken") || null;
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;
  const env = window.localStorage.getItem("fk-env") || null;
  let aeAppsUser = null;
  if (aeApps) {
    const aeAppsUserData = localStorage.getItem("body") || "{}";
    const localStorageData = JSON.parse(aeAppsUserData);
    aeAppsUser = lodash.get(localStorageData, "Email", "");
  }

  const uid =
    aeAppsUser ||
    oktaEmail ||
    getQueryParamsValues(window?.location?.search || "", "user") ||
    "testuser@cisco.com";
  const param = qs.stringify(
    {
      "x-access-token": oktaToken || aeAppsToken,
      aeApps,
      groupId,
      eventId,
      env,
      topic: themeConfig.ctag,
      referrer,
    },
    { skipNulls: true }
  );
  const { pid, type } = params;
  const url = `${appConfig.AUTH_CMS}content/social/${pid}/likes/${uid}?${param}`;
  callApi(
    url,
    () => {
      success();
    },
    failure,
    {
      method: type === "like" ? "PUT" : "DELETE",
    }
  );
};



export const updateUserMetricsNoAuth = (success, failure, params) => {
  const oktaEmail = sessionStorage.getItem("fk-impact-okta-email") || "";
  const themeConfig = getEmbedThemeInfo();
  const referrer = getUrlParams("referrer") || null;

  const aeApps = checkIfAeApps();
  const groupId = window?.userConfig?.groupId || null;
  const eventId = window?.userConfig?.eventId || null;
  const env = window.localStorage.getItem("fk-env") || null;
  let aeAppsUser = null;
  if (aeApps) {
    const aeAppsUserData = localStorage.getItem("body") || "{}";
    const localStorageData = JSON.parse(aeAppsUserData);
    aeAppsUser = lodash.get(localStorageData, "Email", "");
  }

  const uid =
    aeAppsUser ||
    oktaEmail ||
    getQueryParamsValues(window?.location?.search || "", "user") ||
    "testuser@cisco.com";
  const param = qs.stringify(
    {
      aeApps,
      groupId,
      eventId,
      env,
      topic: themeConfig.ctag,
      referrer,
    },
    { skipNulls: true }
  );
  const { pid, type } = params;
  const url = `${appConfig.CONTENT_URL}social/${pid}/likes/${uid}?${param}`;
  callApi(
    url,
    () => {
      success();
    },
    failure,
    {
      method: type === "like" ? "PUT" : "DELETE",
    }
  );
};
